import { Button } from "@mui/material";
import { Container } from "@mui/system";
import { Auth } from "aws-amplify";
import facctviewLogo from "../../assets/images/logos/FacctView.svg";
import "./home.scss";

const Home = () => {
  return (
    <>
      <div
        id="home"
        className="page d-flex flex-column  justify-content-start container mb-5 pt-5"
        color=""
      >
        <Container>
          <div className="d-flex flex-column justify-content-center mt-5 pt-5">
            <div>
              <img src={facctviewLogo} alt="FacctumLogo" />
            </div>
            <div className="title mt-3">
              New screening technology <br />
              for financial crime risk management
            </div>
          </div>
        </Container>
        <div id="note" className="d-flex justify-content-center">
          <div className="note-box p-3">
            <Button
              data-testid="submitButton"
              color="info"
              variant="contained"
              onClick={() => {
                Auth.federatedSignIn({
                  customProvider: process.env.REACT_APP_SAML_IDP_NAME,
                });
              }}
              className="mt-4"
            >
              SSO
            </Button>
            <p className="body-small-normal">
              Access to FacctView is provided for purposes of product
              evaluation. OFAC SDN data is sourced directly from &nbsp;
              <a
                className="ofac-link"
                target="_blank"
                href="https://www.treasury.gov/ofac/downloads"
                rel="noreferrer"
              >
                OFAC Specially Designated Nationals Data (treasury.gov).
              </a>
              &nbsp; Facctum does not enrich this data. Information will be
              updated on an occasional basis. The criteria and results of
              searches are recorded and stored by Facctum. Use of this service
              does not constitute or infer Facctum providing any form of legal
              or compliance guidance. Please contact us if have any questions.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
