/* eslint-disable react-hooks/exhaustive-deps */
import Box from "@mui/material/Box";

import { Modal } from "@mui/material";
import { useEffect, useState } from "react";
import animationData from "../../../assets/images/animations/svgAnimation.json"
import facctview from "../../../assets/images/logos/facctumLogo.svg";
import JsonToSVG from "../jsonToSVG/jsonToSVG";

const SpinnerBlocker = () => {
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [timoutContainer, setTimeoutContainer] = useState(null);

  useEffect(() => {
    setTimeoutContainer(
      setTimeout(() => {
        setMessage("Its taking longer than expected, Please wait.");
        setShowMessage(true);
      }, 15000)
    );
    return () => {
      if (timoutContainer) clearTimeout(timoutContainer);
    };
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    borderRadius: "5px",
    p: 4,
  };

  return (
    <Modal
      open={true}
      aria-labelledby="Loading"
      aria-describedby="Loading"
      data-testid="loading"
    >
      <Box
        sx={{
          ...style,
          width: "100vw",
          height: "100vw",
          borderRadius: "0px",
          bgcolor: "#fcfcfcaa",
        }}
      >
        <div className="w-100 h-100 d-flex justify-content-center align-items-center flex-column">
          <div className="pb-4">
            <img
              style={{ width: "200px" }}
              src={facctview}
              alt="facctViewLogo"
            />
          </div>
          {/* <SpinnerLoader /> */}
          <div style={{ width: "400px" }}>
            <JsonToSVG animationData={animationData} />
          </div>
          {showMessage && message}
        </div>
      </Box>
    </Modal>
  );
};

export default SpinnerBlocker;
