import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "./loginRedux";
import metadataRedux from "./metadataRedux";
import searchCodesRedux from "./searchCodesRedux";
import entityDetailsRedux from "./enityDetailsRedux";
import aditionalInformationRedux from "./aditionalInformationRedux";
import enumDetailsRedux from "./enumDetailsRedux";
import entityDataRedux from "./entityDataRedux";
import attachmentsredux from "./attachmentsredux";

export const store = configureStore({
  reducer: {
    login: loginReducer,
    metadata: metadataRedux,
    searchcodesdata: searchCodesRedux,
    entityDetails: entityDetailsRedux,
    aditionalInformationDetails: aditionalInformationRedux,
    enumDetails : enumDetailsRedux,
    entityData : entityDataRedux,
    attachmentData: attachmentsredux
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV !== "production",
});
