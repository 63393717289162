import { useState, useEffect } from "react";
import { Divider, TextField } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useSelector, useDispatch } from "react-redux";

//components
import DetailsSection from "../../detialsSection";
import CommonSelect from "../../../../components/dropDown/dropDown";
import {
  setEntityDetails,
  addEntityField,
} from "../../../../redux/enityDetailsRedux";
//style
import "./addressField.scss";
import NoDataFound from "../../../../components/noDataFound/noDataFound";

const AddressField = (props) => {
  const { loading = true, isDisabled } = props;
  const dispatch = useDispatch();
  const address = useSelector((state) => state.entityDetails?.standard_data?.addresses);
  const addressDetailsList = useSelector((state) => state?.entityDetails?.addressDetailsList);
  const countries = useSelector((state) => state.entityDetails.countries);
  const selectedRole = sessionStorage.getItem("selectedRole");


  const [addressTypeOptions, setaddressTypeOptions] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(true);


  useEffect(() => {
    const addressList = addressDetailsList?.enum_values;
    const updatedAddressList = addressList
      ? addressList.map((name) => {
          return { id: name.enum_value_id, value: name.enum_value_name };
        })
      : [];
    setaddressTypeOptions(updatedAddressList);
    setIsDataLoading(false);
  }, [addressDetailsList]);

  const onChangeHandler = (event, index, key) => {
    const updatedAddress = [...address];
    if (key === "enum_value_id") {
      const enum_value_name = addressTypeOptions.filter(
        (addressType) => addressType.id === event.target.value
      )[0].value;
      updatedAddress[index] = {
        ...updatedAddress[index],
        enum_value_name,
        enum_type_id: addressDetailsList.enum_type_id,
      };
    }
    updatedAddress[index] = {
      ...updatedAddress[index],
      [key]: event.target.value,
    };
    dispatch(setEntityDetails({ key: "addresses", data: [...updatedAddress] }));
  };
  const deleteHandler = (id) => {
    const updatedAddress = [...address];
    updatedAddress.splice(id, 1);
    dispatch(setEntityDetails({ key: "addresses", data: [...updatedAddress] }));
  };

  return (
    <div>
      <DetailsSection
        title="Address"
        addFieldHandler={() => dispatch(addEntityField({ key: "addresses" }))}
        showAddIcon={selectedRole === "maker"}
        isDisabled={selectedRole === "checker"}
      >
        {address.length > 0 ?
          address.map((ele, index) => {
            return (
              <>
                <div
                  className={`address-container m-t-1`}
                  key={index}
                >
                  <CommonSelect
                    customStyle={{
                      width: "15%",
                      height: "25%",
                      marginRight: "1%",
                    }}
                    menuWidth="8%"
                    showLabel={false}
                    value={ele.enum_value_id}
                    title="ID Type"
                    inputId={"inputId"}
                    labelId={"Id Type-label-" + index}
                    selectId={"select-id-" + index}
                    label="Name"
                    options={addressTypeOptions?.length > 0 ? addressTypeOptions : []}
                    onChangeHandler={(event) =>
                      onChangeHandler(event, index, "enum_value_id")
                    }
                    loading={isDataLoading}
                    readOnly={selectedRole === "checker"}
                  />
                  <CommonSelect
                    customStyle={{
                      width: "15%",
                      height: "25%",
                      marginRight: "1%",
                    }}
                    showLabel={false}
                    value={ele.address_country}
                    title="Country"
                    inputId={"inputId"}
                    labelId={"Country-label-" + index}
                    selectId={"select-id-" + index}
                    label="Country"
                    options={countries?.length > 0 ? countries : []}
                    loading={loading}
                    onChangeHandler={(event) =>
                      onChangeHandler(event, index, "address_country")
                    }
                    readOnly={selectedRole === "checker"}
                  />
                  <TextField
                    style={{
                      width: "15%",
                      borderRadius: 8,
                      // height: 40,
                      marginRight: "1%",
                    }}
                    value={ele.address_city}
                    variant={"outlined"}
                    id="input-with-icon-textfield"
                    label="City"
                    onChange={(event) =>
                      onChangeHandler(event, index, "address_city")
                    }
                    inputProps={{ readOnly: selectedRole === "checker" }}
                  />
                  <TextField
                    style={{
                      width: "45%",
                      borderRadius: 8,
                      // height: 40,
                      marginRight: "1%",
                    }}
                    value={ele.address_line}
                    variant={"outlined"}
                    id="input-with-icon-textfield"
                    label="Address Line"
                    onChange={(event) =>
                      onChangeHandler(event, index, "address_line")
                    }
                    inputProps={{ readOnly: selectedRole === "checker" }}
                  />

                  <TextField
                    style={{
                      width: "15%",
                      borderRadius: 8,
                      // height: 40,
                      marginRight: "1%",
                    }}
                    value={ele.address_zipcode}
                    variant={"outlined"}
                    id="input-with-icon-textfield"
                    label="Zip Code"
                    onChange={(event) =>
                      onChangeHandler(event, index, "address_zipcode")
                    }
                    inputProps={{ readOnly: selectedRole === "checker" }}
                  />

                  {address.length > 1 && selectedRole === "maker" && (
                    <ClearIcon
                      sx={{
                        color: "#000",
                        opacity: "58%",
                        cursor: "pointer",
                        marginTop: "1%",
                      }}
                      onClick={() => deleteHandler(index)}
                    />
                  )}
                </div>
                {index !== address.length - 1 && (
                  <Divider className="w-100 divider-style" />
                )}
              </>
            );
          })
        :
        <NoDataFound />
        }
      </DetailsSection>
    </div>
  );
};

export default AddressField;
