import { useState, useEffect, useRef } from "react";
import _ from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

//components, constants, API handlers
import AdditionalInformation from "../detailsSection/basicDetails/additionalInformation/additionalInformation";
import AddressField from "../detailsSection/basicDetails/addressField/addressField";
import DobField from "../detailsSection/basicDetails/dobField/dobField";
import NameField from "../detailsSection/basicDetails/nameField/nameField";
import NationalIdField from "../detailsSection/basicDetails/nationalIdField/nationalIdField";

import SpinnerBlocker from "../../components/loader/spinnerBlocker/spinnerBlocker";

import "./addEntity.scss";
import { setLoading } from "../../redux/enityDetailsRedux";

const BasicDetails = ({ blocklistId, ...props }) => {
  const loading = useSelector((state) => state.entityDetails.standard_data.loading);

  return (
    <div className="d-flex flex-column">
      {props.loadingOnSubmit || loading ? (
        <SpinnerBlocker />
      ) : (
        <>
          <NameField />
          <DobField activeRadioButton={props.activeRadioButton} />
          <NationalIdField />
          <AddressField />
          <AdditionalInformation action={props?.state?.action} />
        </>
      )}
    </div>
  );
};

export default BasicDetails;
