import { React, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, TextField } from "@mui/material";
import { toast } from "react-toastify";
import { listManagementAPI } from "../../api";
import { useTranslation } from "react-i18next";

// styles
import "./stopApproveRejectRule.scss";
import SpinnerBlocker from "../../components/loader/spinnerBlocker/spinnerBlocker";
import _ from "lodash";
import { setUpdateList } from "../../redux/metadataRedux";
import EventBus from "../../components/eventBus/eventBus";

const StopApproveRejectRule = (props) => {
  const { handleClose, activeTab } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const userId = useSelector((state) => state.login?.user?.attributes?.email);
  const [comments, setComments] = useState("");
  const [isLoading, setLoading] = useState(false);
  const rule_category_id = sessionStorage.getItem("category") || 1;
  let title = "";
  let buttonLabel = "";
  let handleClick;

  if (props?.action?.toUpperCase() === "APPROVE") {
    title = "Approve Rule";
    buttonLabel = "APPROVE";
    handleClick = handleApproveRule;
  } else if (props?.action?.toUpperCase() === "REJECT") {
    title = "Reject Rule";
    buttonLabel = "REJECT";
    handleClick = handleRejectRule;
  } else {
    title = "Stop Rule";
    buttonLabel = "STOP";
    handleClick = handleStopRule;
  }

  async function handleStopRule() {
    setLoading(true);
    let body = {
      rule_id: props.selectedRow.ruleId,
      action: 3,
      user_id: userId,
      comment: comments,
      rule_category_id 
    };
    try {
      const ruleSetData = [];
      ruleSetData.push(body);
      const response = await listManagementAPI.addDeleteRules(ruleSetData);
      if (response.status === 200 && response.data.data.length > 0) {
        setLoading(false);
        toast.success("Rules Deletion in process");
        dispatch(setUpdateList(true));
      } else {
        setLoading(false);
        toast.error(t(response.response.data.code));
      }
    } catch (e) {
      setLoading(false);
      toast.error(t(e?.response?.data?.code));
    } finally {
      setLoading(false);
    }
    handleClose();
  }

  async function handleApproveRule() {
    setLoading(true);
    try {
      const req = {
        rule_id: props.selectedRow.ruleId,
        rule_version_number: props.selectedRow.ruleVersionNumber,
        status: 1,
        updated_user_id: userId,
        comment: comments,
        rule_category_id
      };
      const response = await listManagementAPI.actionOnRule(req);
      if (response.status === 200 && response.data.data.length) {
        setLoading(false);
        dispatch(setUpdateList(true));
        EventBus.publish('reload_rule_page', activeTab);
        toast.success("Rule APPROVED !");
      } else {
        setLoading(false);
        toast.error(t(response?.response?.data?.code));
      }
    } catch (e) {
      setLoading(false);
      toast.error(t(e?.response?.data?.code));
    } finally {
      setLoading(false);
    }
    handleClose();
  }

  async function handleRejectRule() {
    setLoading(true);
    try {
      const req = {
        rule_version_number: props.selectedRow.ruleVersionNumber,
        rule_id: props.selectedRow.ruleId,
        status: 2,
        updated_user_id: userId,
        comment: comments,
        rule_category_id
      };
      const response = await listManagementAPI.actionOnRule(req);
      if (response.status === 200 && response.data.data.length) {
        setLoading(false);
        dispatch(setUpdateList(true));
        EventBus.publish('reload_rule_page', activeTab);
        toast.success("Rule REJECTED !");
      } else {
        setLoading(false);
        toast.error(t(response?.response?.data?.code));
      }
    } catch (e) {
      console.log(e)
      setLoading(false);
      toast.error(t(e?.response?.data?.code));
    } finally {
      setLoading(false);
    }
    handleClose();
  }

  return (
    <>
      <div className="d-flex justify-content-center update-blocklist-container w-100 flex-column ">
        <p className="update-blocklist-title">{title}</p>
        <div className="content">
          <p className="label">
            Add Comments<span className="important-style">*</span>
          </p>
          <TextField
            fullWidth
            id="outlined-multiline-static"
            multiline
            rows={4}
            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 },}} 
            placeholder="Enter Description"
            onChange={(event) => {
              setComments(event.target.value);
            }}
          />
          <div className="action-buttons-container">
            <div className="d-flex justify-content-end">
              <Button
                sx={{ marginRight: "1.6%" }}
                size="large"
                color="error"
                onClick={handleClose}
              >
                CANCEL
              </Button>
              <Button
                variant="contained"
                size="large"
                color="error"
                onClick={handleClick}
                disabled={_.isEmpty(comments)}
              >
                {buttonLabel}
              </Button>
            </div>
          </div>
        </div>
      </div>
      {isLoading && <SpinnerBlocker></SpinnerBlocker>}
    </>
  );
};

export default StopApproveRejectRule;
