import {
  Paper,
  Button,
  TextField,
} from "@mui/material";
import { React, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { blockListManagementAPI } from "../../api";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { useDispatch } from "react-redux";
import _ from "lodash";
import AddEntity from "../addEntity/addEntity";
import { setLoading } from "../../redux/enityDetailsRedux";

//style
import "./viewApproval.scss";
import { setEntityData } from "../../redux/entityDataRedux";


const ViewApproval = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();

  const [disableButton, setDisableButton] = useState(true);
  const [comments, setComments] = useState("");

  const userId = useSelector((state) => state.login?.user?.attributes?.email);
  const entityData = useSelector((state) => state.entityData)
  const blocklistDetails = JSON.parse(
    sessionStorage.getItem("selectedBlocklistDetails")
  );

  const { t } = useTranslation();
  const selectedRole = sessionStorage.getItem("selectedRole");

  useEffect(() => {
    dispatch(setLoading(true));
    if (state) {
      const asyncResponse = async () => {
        const responseFromEntriesAPI =
          await blockListManagementAPI.fetchIBListEntriesMods({
            blocklistId: state.data.blocklist_id,
            blocklistEntryId: state.data.blocklist_entry_id,
            version_no: state.data.blocklist_entry_version_no || null
          });
        // await resetFieldHandler();
        // dispatch(setEntityData({ data: responseFromEntriesAPI?.data[0], action: "edit", role : selectedRole}));
        dispatch(setLoading(false));
        // setActiveRadioButton(
        //   IBL_RECORD_TYPE[responseFromEntriesAPI?.data[0].record_type]
        // );
        // await dispatch(
        //   updateEntityDetails({
        //     names: responseFromEntriesAPI?.data[0]?.standard_data?.names,
        //     dobs: _.isEmpty(
        //       responseFromEntriesAPI?.data[0]?.standard_data?.dobs
        //     )
        //       ? null
        //       : responseFromEntriesAPI?.data[0]?.standard_data?.dobs
        //         .map((dob) => ({
        //           enum_type_id: 3,
        //           enum_value_id: enumDetails.enum_value_id,
        //           enum_value_name: enumDetails.enum_value_name,
        //           month: dob?.month? { id: dob.month, value: monthsMapperObj[dob.month] }: null,
        //           day: dob?.day ? { id: dob.day, value: dob.day } : null,
        //           year: dob?.year ? {id: dob.year, value: dob.year} : null,
        //         })),
        //     addresses: _.isEmpty(
        //       responseFromEntriesAPI.data[0]?.standard_data?.addresses
        //     )
        //       ? null
        //       : responseFromEntriesAPI?.data[0]?.standard_data?.addresses,
        //     national_ids: _.isEmpty(
        //       responseFromEntriesAPI?.data[0]?.standard_data?.national_ids
        //     )
        //       ? null
        //       : responseFromEntriesAPI?.data[0]?.standard_data?.national_ids,
        //     additionalInformation: {
        //       proposed_start_dtm:
        //         responseFromEntriesAPI?.data[0]?.proposed_start_dtm,
        //       proposed_end_dtm:
        //         responseFromEntriesAPI?.data[0]?.proposed_end_dtm,
        //       comment: responseFromEntriesAPI?.data[0]?.comment,
        //     },
        //     proposed_start_dtm:
        //       responseFromEntriesAPI?.data[0]?.proposed_start_dtm,
        //     proposed_end_dtm: responseFromEntriesAPI?.data[0]?.proposed_end_dtm,
        //     comment: responseFromEntriesAPI?.data[0]?.comment,
        //   })
        // );
        // setEntryData(responseFromEntriesAPI?.data[0]);
        // setStandardData(responseFromEntriesAPI?.data[0]?.standard_data);
      };
      asyncResponse();
    }
  }, []);

  const onChangeHandler = (event) => {
    setComments(event?.target?.value);
    if (event?.target?.value) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  };

  const action = async (value) => {
    dispatch(setLoading(true));
    const body = {
      client_id: 1,
      blocklist_id: state.data.blocklist_id,
      blocklist_entry_id: state.data.blocklist_entry_id,
      version_no: state.data.blocklist_entry_version_no,
      api_action: value,
      comment: comments,
      user_id: userId,
    };


    const response = await blockListManagementAPI.entriesModHandler(body);
    if (response.status === 200 && response.data.data.length) {
      toast.success("Entry Approved!");
      dispatch(setLoading(false));
      navigateToIBLEntries();
    } else {
      if (response?.response?.data) {
        toast.error(t(response?.response?.data?.code));
        dispatch(setLoading(false));
      } else {
        toast.error(t(response.code));
        dispatch(setLoading(false));
      }
    }
  };

  const navigateToIBLEntries = () => {
    dispatch(setEntityData({ query: blocklistDetails.blocklist_id, action: "edit" }))
    navigate("/iblDetails", {
      state: { query: blocklistDetails.blocklist_id, action: "edit" },
    });
  };

  return (
    <div>
      <div className="d-flex flex-column">
        <div className="d-flex flex-column">
        <AddEntity />
        </div>
        <Paper
          className="mt-1 p-4 mb-5"
          elevation={5}
          sx={{
            width: "100%",
            minHeight: "15rem",
            color: "#1C2472",
          }}
        >
          <div className="section-title w-100 mb-2">
            Approver Action<span className="important-style">*</span>
          </div>
          <div className="d-flex w-100 justify-content-between">
            <TextField
              className="w-50"
              id="outlined-multiline-static"
              label="Comments"
              multiline
              rows={6}
              value={comments}
              placeholder="Comments"
              onChange={(event) => onChangeHandler(event)}
            />
            <div className="align-self-end">
              <Button
                size="large"
                variant="outlined"
                color="error"
                disabled={disableButton}
                onClick={() => {
                  return action(2);
                }}
              >
                Reject
              </Button>
              <Button
                className="ms-2"
                variant="contained"
                size="large"
                color="error"
                disabled={disableButton}
                onClick={() => {
                  return action(1);
                }}
              >
                Approve
              </Button>
            </div>
          </div>
        </Paper>
        <div style={{height: "2ch"}}></div>
      </div>
    </div>
  );
};

export default ViewApproval;
