import * as React from "react";
import Paper from "@mui/material/Paper";
import { iblConstants } from "../../constants";
import { toast } from "react-toastify";
import format from "date-fns/format";
import { useTranslation } from "react-i18next";
import { Avatar, Button, Checkbox, InputLabel } from "@mui/material";
import BusinessIcon from "@mui/icons-material/Business";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "./iblTable.scss";
import RightSidePopup from "../modal/modal";
import DeleteIBLEntry from "../../features/IBLDetails/deleteIBLEntry/deleteIBLEntry";
import { addUpdateEntity } from "../../api/blockListManagementAPI";
import { resetEntityDetails } from "../../redux/enityDetailsRedux";
import SpinnerBlocker from "../loader/spinnerBlocker/spinnerBlocker";
import { setEntityData } from "../../redux/entityDataRedux";
import { resetAddtionalInformation } from "../../redux/aditionalInformationRedux";
import { resetAttachmentsData } from "../../redux/attachmentsredux";

export default function IBLTable({
  listFor,
  caller,
  data,
  id,
  index,
  role,
  getDeleteEntryList,
  ...props
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = React.useState(false);
  const [deleteEntry, setDeleteEntry] = React.useState(null);
  const [isLoading, setLoading] = React.useState(false);

  const userId = useSelector((state) => state.login?.user?.attributes?.email);

  const handleOnClick = (value) => {
    sessionStorage.setItem("selectedBlockList", value);
    dispatch(setEntityData({ query: value }))
    navigate("/iblDetails", { state: { query: value } });

  };

  const handleReviewClick = () => {
    dispatch(setEntityData({ data , action: "edit"} ))
    navigate("/viewApproval", { state: { data , action: "edit"} });
  };

  const handleClose = () => setOpenModal(false);

  const redirectToEditEntity = async (listFor) => {
    setLoading(true);
    if (listFor === "approved") {
      const body = {
        blocklist_entry_id: data.blocklist_entry_id,
        blocklist_entry_version_no: data.blocklist_entry_version_no,
        blocklist_id: data.blocklist_id,
        client_id: data.client_id,
        api_action: 4,
        user_id: userId,
      };
      const response = await addUpdateEntity(body);
      if (
        response.status === 200 &&
        Object.keys(response.data.data).length > 0
      ) {
        dispatch(setEntityData({ data: response.data.data, action: "edit" }));
        setLoading(false);
        navigate("/addentity", {
          state: { data: response.data.data, action: "edit" },
        });
      } else {
        if (response?.response?.data) {
          toast.error(t(response?.response?.data?.code));
        } else {
          toast.error(t(response.code));
        }
        setLoading(false);
        return;
      }
    } else {
      dispatch(resetEntityDetails());
      dispatch(resetAddtionalInformation());
      dispatch(resetAttachmentsData());
      dispatch(setEntityData({ data, action: "edit" }));
      setLoading(false);
      navigate("/addentity", { state: { data, action: "edit" } });
    }
  };

  const handleOnCheckBox = (event, value) => {
    getDeleteEntryList({
      action: event.target.checked ? "add" : "delete",
      value: value,
    });
  };

  const getDateFormat = (dobs) => {
    if (dobs.year && dobs.month && dobs.day)
      return format(new Date(dobs.year, dobs.month - 1, dobs.day), "dd LLL yyyy");
    if (dobs.year && dobs.month)
      return format(new Date(dobs.year, dobs.month - 1, 1), "LLL yyyy");
    if (dobs.year)
    return format(new Date(dobs.year, 1, 1), "yyyy");
  };

  return (
    <>
      <Paper
        sx={{ width: "100%", minHeight: "2.8rem" }}
        elevation={1}
        className={`row d-flex mb-1 align-items-center hover-list ibl-list-font ${
          index % 2 ? `alt-row-color` : ""
        }`}
        key={id}
        id={"paper-" + id}
      >
        {caller === "IBLList" && (
          <>
            <div
              className="ps-3 status col-1"
              style={{
                color: iblConstants.IBL_STATUS_COLOR[data.state_id],
              }}
              id="state-id"
            >
              {iblConstants.IBL_STATUS[data.state_id]}
            </div>
            {role === "maker" && (
              <div className="fcc-dark-text iblTable-text-font col-4" id="name">
                {data?.blocklist_name}
              </div>
            )}
            {role === "checker" && (
              <div className="fcc-dark-text iblTable-text-font col-3" id="name">
                {data?.blocklist_name}
              </div>
            )}
            <div
              className="fcc-light-text d-flex justify-content-center iblTable-text-font col-1"
              id="blocklist-id"
            >
              {data?.blocklist_id}
            </div>
            <div
              className="fcc-dark-text d-flex justify-content-center iblTable-text-font col-2 last-updated-date"
              id="update-date"
            >
              {data?.updated_on &&
                format(
                  new Date(data?.updated_on?.slice(0, 10)),
                  "dd LLL yyyy"
                )}
              {data?.updated_on && (
                <Tooltip title="Count updated date" placement="top">
                  <InfoIcon fontSize="small" className="fcc-info-icon" />
                </Tooltip>
              )}
            </div>
            <div
              className="fcc-light-text d-flex justify-content-center iblTable-text-font col-1"
              id="records-approved"
            >
              {data?.records_approved}
            </div>
            <div
              className="fcc-light-text d-flex justify-content-center iblTable-text-font col-1"
              id="records-review"
            >
              {data?.records_review}
            </div>
            <div
              className="fcc-light-text d-flex justify-content-center iblTable-text-font col-1"
              id="records-draft"
            >
              {data?.records_draft}
            </div>
            {role === "maker" && (
              <div className="d-flex justify-content-end col-1">
                <Button
                  variant="text"
                  className="view-btn"
                  onClick={() => handleOnClick(data?.blocklist_id)}
                  disabled={data?.state_id === 1}
                >
                  VIEW{" "}
                  <ArrowForwardIcon fontSize="small" className="fcc-arrow" />
                </Button>
              </div>
            )}
            {role === "checker" && (
              <div className="d-flex justify-content-end col-2">
                <Button
                  variant="text"
                  className="view-btn"
                  onClick={() => handleOnClick(data?.blocklist_id)}
                >
                  APPROVAL QUEUE
                  <ArrowForwardIcon fontSize="small" className="fcc-arrow" />
                </Button>
              </div>
            )}
          </>
        )}
        {caller === "entrylistapproveddraft" && (
          <>
            <div className="col-1 d-flex justify-content-center">
              <Checkbox
                color="secondary"
                onChange={(event) => {
                  handleOnCheckBox(event, {
                    blocklist_entry_id: data?.blocklist_entry_id,
                    blocklist_entry_version_no:
                      data?.blocklist_entry_version_no,
                    api_action: listFor === "approved" ? 5 : 3,
                  });
                }}
                value={{
                  blocklist_entry_id: data?.blocklist_entry_id,
                  blocklist_entry_version_no: data?.blocklist_entry_version_no,
                  api_action: listFor === "approved" ? 5 : 3,
                }}
              />
            </div>
            <div className="d-flex align-items-center col-5 iblTable-text-font">
              {data?.record_type === 1 ? (
                <Avatar
                  sx={{ width: 24, height: 24 }}
                  src="/broken-image.jpg"
                  className="me-3"
                />
              ) : (
                <Avatar sx={{ width: 24, height: 24 }} className="me-3">
                  <BusinessIcon className="business-icon"></BusinessIcon>
                </Avatar>
              )}
              {data?.standard_data.names[0]?.full_name
                ? data?.standard_data.names[0]?.full_name
                : data?.standard_data.names[0]?.first_name}{" "}
            </div>
            <div className="col-2 last-updated-date iblTable-text-font d-flex justify-content-center">
              {data?.standard_data?.dobs[0] && getDateFormat(data?.standard_data?.dobs[0])}
            </div>
            <div className="col-2 d-flex justify-content-center">
              {data?.standard_data?.national_ids[0]?.id_value}
            </div>
            <div className="col-1 d-flex justify-content-end">
              <Button
                size="small"
                variant="outlined"
                onClick={() => {
                  setOpenModal((prevState) => !prevState);
                  setDeleteEntry([
                    {
                      blocklist_entry_id: data?.blocklist_entry_id,
                      blocklist_entry_version_no:
                        data?.blocklist_entry_version_no,
                      api_action: listFor === "approved" ? 5 : 3,
                    },
                  ]);
                }}
                id="btn-delete"
              >
                DELETE
              </Button>
            </div>
            <div className="col-1 d-flex justify-content-start">
              <Button
                size="small"
                variant="contained"
                onClick={() => redirectToEditEntity(listFor)}
                id="btn-edit"
              >
                EDIT
              </Button>
            </div>
          </>
        )}
        {caller === "entrylistpending" && role === "maker" && (
          <>
            <div className="d-flex align-items-center iblTable-text-font col-6">
              {data?.record_type === 1 ? (
                <Avatar
                  sx={{ width: 24, height: 24 }}
                  src="/broken-image.jpg"
                  className="me-3"
                />
              ) : (
                <Avatar sx={{ width: 24, height: 24 }} className="me-3">
                  <BusinessIcon className="business-icon"></BusinessIcon>
                </Avatar>
              )}
              {data?.standard_data.names[0]?.full_name
                ? data?.standard_data.names[0]?.full_name
                : data?.standard_data.names[0]?.first_name}{" "}
            </div>
            <div className="col-2 d-flex justify-content-center iblTable-text-font last-updated-date">
              {data?.standard_data?.dobs[0] && getDateFormat(data?.standard_data?.dobs[0])}
            </div>
            <div className="col-2 d-flex justify-content-center iblTable-text-font">
              {data?.standard_data.national_ids[0]?.id_value}
            </div>
            <div className="d-flex justify-content-center col-2">
              <InputLabel
                sx={{ color: iblConstants.IBL_ACTION_COLOR[data?.action] }}
              >
                {iblConstants.IBL_ACTION_TEXT[data?.action]}
              </InputLabel>
            </div>
          </>
        )}
        {caller === "entrylistpending" && role === "checker" && (
          <>
            <div className="col-1 d-flex justify-content-center">
              <InputLabel
                sx={{
                  color: iblConstants.IBL_ACTION_COLOR[data.action],
                  fontSize: "0.7rem",
                  padding: "0.74rem",
                }}
              >
                {iblConstants.IBL_ACTION_TEXT[data.action]}
              </InputLabel>
            </div>
            <div className="d-flex align-items-center col-3 iblTable-text-font">
              {data?.record_type === 1 ? (
                <Avatar
                  sx={{ width: 24, height: 24 }}
                  src="/broken-image.jpg"
                  className="me-3"
                />
              ) : (
                <Avatar sx={{ width: 24, height: 24 }} className="me-3">
                  <BusinessIcon className="business-icon"></BusinessIcon>
                </Avatar>
              )}
              {data?.standard_data.names[0]?.full_name}
            </div>
            <div className="col-3 d-flex justify-content-center iblTable-text-font">
              {data?.created_by}
            </div>
            <div className="col-2 d-flex justify-content-center iblTable-text-font last-updated-date">
              {data?.proposed_start_dtm &&
                format(
                  new Date(data?.proposed_start_dtm?.slice(0, 10)),
                  "dd LLL yyyy"
                )}
            </div>
            <div className="col-2 d-flex justify-content-center iblTable-text-font last-updated-date">
              {data?.proposed_end_dtm &&
                format(
                  new Date(data?.proposed_end_dtm?.slice(0, 10)),
                  "dd LLL yyyy"
                )}
            </div>
            <div className="d-flex justify-content-end col-1">
              <Button
                variant="text"
                className="view-btn"
                onClick={() =>
                  handleReviewClick()
                }
              >
                REVIEW{" "}
                <ArrowForwardIcon fontSize="small" className="fcc-arrow" />
              </Button>
            </div>
          </>
        )}
      </Paper>
      {role === "maker" && (
        <RightSidePopup open={openModal} width="26%">
          <DeleteIBLEntry handleClose={handleClose} deleteEntry={deleteEntry} activeTab={listFor}/>
        </RightSidePopup>
      )}
      {isLoading && <SpinnerBlocker></SpinnerBlocker>}
    </>
  );
}
