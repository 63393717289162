import { isEmptyCheck } from "./utility";
export const validateComplexRadioDate = (complexRadioDateObj) => {
  if (complexRadioDateObj["is_true"]) {
    if (
      isEmptyCheck(complexRadioDateObj.day) &&
      isEmptyCheck(complexRadioDateObj.year) &&
      complexRadioDateObj.month == null
    )
      return { error: true, error_message: "Please enter the valid date" };
    if (complexRadioDateObj.day) {
      if (
        complexRadioDateObj.year === null ||
        complexRadioDateObj.month == null
      )
        return { error: true, error_message: "Please enter the valid date" };
    }
    if (complexRadioDateObj.month) {
      if (complexRadioDateObj.year === null)
        return { error: true, error_message: "Please enter the valid date" };
    }
  }
  return { error: false, error_message: "" };
};

export const camelToUnderscore = (key) => {
  var result = key.replace(/([A-Z])/g, " $1");
  return result.split(" ").join("_").toLowerCase();
};

export const camelize = (str) => {
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
    return index === 0 ? word.toLowerCase() : word.toUpperCase();
  }).replace(/\s+/g, '');
}

export const validateComplexDropDown = (ComplexDropDownObj) => {
  let errorObj = { error: false, error_message: "" };
  ComplexDropDownObj.length > 0 && ComplexDropDownObj.map((ele) => {
    if (!isEmptyCheck(ele.code) && (isEmptyCheck(ele.enum_value_id) || isEmptyCheck(ele.enum_value_id.id)))
      errorObj = {
        error: true,
        error_message: "Please select the country type",
      };
    if (ele?.enum_value_id?.id) {
      if (isEmptyCheck(ele.code) || isEmptyCheck(ele.code.id))
        errorObj = {
          error: true,
          error_message: "Please select the country code",
        };
    }
  });
  return errorObj;
};


export const validateComplexDropDownDate = (complexDropDownObj) => {
  let errorObj = { error: false, error_message: "" };
  if (complexDropDownObj.length > 0) {
    for (let i = 0; i < complexDropDownObj.length; i++) {
      if (
        !isEmptyCheck(complexDropDownObj[i].enum_value_id) &&
        !isEmptyCheck(complexDropDownObj[i].enum_value_id.id)
      ) {
        if (
          isEmptyCheck(complexDropDownObj[i]?.sinceDay?.id) &&
          isEmptyCheck(complexDropDownObj[i]?.sinceYear?.id) &&
          isEmptyCheck(complexDropDownObj[i].sinceMonth?.id)
        )
          return { error: true, error_message: "Please enter the valid date" };
        if (
          !isEmptyCheck(complexDropDownObj[i]?.sinceYear?.id) &&
          isEmptyCheck(complexDropDownObj[i]?.toYear?.id)
        )
          return { error: true, error_message: "Please enter the valid date" };

        if (complexDropDownObj[i].sinceDay?.id) {
          if (
            isEmptyCheck(complexDropDownObj[i]?.sinceYear?.id) ||
            isEmptyCheck(complexDropDownObj[i]?.sinceMonth?.id)
          )
            return {
              error: true,
              error_message: "Please enter the valid date",
            };
        }
        if (isEmptyCheck(complexDropDownObj[i]?.sinceMonth?.id)) {
          if (isEmptyCheck(complexDropDownObj[i]?.sinceYear.id))
            return {
              error: true,
              error_message: "Please enter the valid date",
            };
        }

        if (
          isEmptyCheck(complexDropDownObj[i]?.toDay?.id) &&
          isEmptyCheck(complexDropDownObj[i]?.toYear?.id) &&
          isEmptyCheck(complexDropDownObj[i]?.toMonth?.id)
        )
          return { error: true, error_message: "Please enter the valid date" };

        if (complexDropDownObj[i]?.toDay?.id) {
          if (
            isEmptyCheck(complexDropDownObj[i]?.toYear?.id) ||
            isEmptyCheck(complexDropDownObj[i]?.toMonth?.id)
          )
            return {
              error: true,
              error_message: "Please enter the valid date",
            };
        }
        if (isEmptyCheck(complexDropDownObj[i]?.toMonth?.id)) {
          if (isEmptyCheck(complexDropDownObj[i]?.toYear?.id))
            return {
              error: true,
              error_message: "Please enter the valid date",
            };
        }

        if (
          isEmptyCheck(complexDropDownObj[i]?.sinceDay?.id) &&
          isEmptyCheck(complexDropDownObj[i]?.sinceMonth?.id) &&
          isEmptyCheck(complexDropDownObj[i]?.sinceYear.id)
        ) {
          return {
            error: true,
            error_message: "Please enter from date",
          };
        }

        if (
          isEmptyCheck(complexDropDownObj[i]?.toDay?.id) &&
          isEmptyCheck(complexDropDownObj[i]?.toMonth?.id) &&
          isEmptyCheck(complexDropDownObj[i]?.toYear?.id)
        ) {
          return {
            error: true,
            error_message: "Please enter to date",
          };
        }

        if (
          parseInt(complexDropDownObj[i]?.sinceYear?.id) >
          parseInt(complexDropDownObj[i]?.toYear?.id)
        ) {
          return {
            error: true,
            error_message: "From date cannot be before to date",
          };
        }

        if (
          parseInt(complexDropDownObj[i]?.sinceYear?.id) ===
            parseInt(complexDropDownObj[i]?.toYear?.id) &&
          parseInt(complexDropDownObj[i]?.sinceMonth?.id) >
            parseInt(complexDropDownObj[i]?.toMonth?.id)
        ) {
          return {
            error: true,
            error_message: "From date cannot be before to date",
          };
        }

        if (
          parseInt(complexDropDownObj[i]?.sinceYear?.id) ===
            parseInt(complexDropDownObj[i]?.toYear?.id) &&
          parseInt(complexDropDownObj[i]?.sinceMonth?.id) ===
            parseInt(complexDropDownObj[i]?.toMonth?.id) &&
          parseInt(complexDropDownObj[i]?.sinceDay?.id) >
            parseInt(complexDropDownObj[i]?.toDay?.id)
        ) {
          return {
            error: true,
            error_message: "From date cannot be before to date",
          };
        }
      }
    }
  }
  return errorObj;
};
