import { React, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, Checkbox, FormControlLabel, TextField } from "@mui/material";
import DatePicker from "../../components/datePicker/datepicker";
import { listManagementAPI } from "../../api";
import SpinnerBlocker from "../../components/loader/spinnerBlocker/spinnerBlocker";
import { toast } from "react-toastify";

const ComparisonReport = () => {
  const [dateDJ, setDateDJ] = useState(new Date());
  const [dateOFAC, setDateOFAC] = useState(new Date());
  const [data, setDataForTable] = useState(null);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [iblId, setIblId] = useState(null);
  const [downloadOfac, setDownloadOfac] = useState(null);
  const [downloadDj, setDownloadDj] = useState(null);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#1C2472",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const runComparison = () => {
    setIsDataLoading((prevState) => !prevState);
    const asyncResponse = async () => {
      try {
        await listManagementAPI.runComparison({
          client_id: 1,
          ofac_date: dateOFAC,
          dj_date: dateDJ,
          ibl_id: iblId,
          download_ofac: +downloadOfac,
          download_dj: +downloadDj,
        });
        toast.success("Generating comparison report.");
      } catch (err) {
      } finally {
        setIsDataLoading(false);
      }
    };
    asyncResponse();
  };

  const viewComparisonReport = () => {
    setIsDataLoading((prevState) => !prevState);
    const asyncResponse = async () => {
      try {
        const responseFromAPI = await listManagementAPI.viewComparisonReport();
        const formattedResponseForTable =
          responseFromAPI?.data?.data?.map((ele) => {
            return {
            blocklistIblId: ele.blocklistIblId,
            blocklistName: ele.blocklistName,
            recordsMatchedwithDj: ele.recordsMatchedwithDj,
            recordsReadFromOfac: ele.recordsReadFromOfac,
            unmatchedRecordsfromOfac: ele.unmatchedRecordsfromOfac
            };
          });
        setDataForTable(
          formattedResponseForTable
        );
      } catch (err) {
      } finally {
        setIsDataLoading(false);
      }
    };
    asyncResponse();
  };

  const handleChangeOfac = (event) => {
    setDownloadOfac(event.target.checked);
  };

  const handleChangeDj = (event) => {
    setDownloadDj(event.target.checked);
  };

  return (
    <div className="d-flex flex-column">
      <Paper className="d-flex mt-5 p-4 flex-column" elevation={3}>
        <h5>Generate and View Comparison Report</h5>
        <div className="d-flex justify-content-between mt-2">
          <TextField
            value={iblId}
            id="ibl-entry-id"
            label="IBL ID"
            placeholder="Enter IBL ID"
            onChange={(event) => setIblId(event.target.value)}
          />
          <div className="d-flex flex-column">
            <DatePicker
              disableFuture={true}
              label="OFAC Date"
              inputFormat="dd MMM yyyy"
              value={dateOFAC}
              onChange={(newValue) => {
                setDateOFAC(newValue.toISOString());
              }}
              renderInput={(params) => <TextField {...params} />}
            />
            <FormControlLabel
              className="mt-2"
              control={<Checkbox name="ofac" />}
              label="Download OFAC"
              checked={downloadOfac}
              onChange={handleChangeOfac}
            />
          </div>
          <div className="d-flex flex-column">
            <DatePicker
              disableFuture={true}
              label="Dow Jones Date"
              inputFormat="dd MMM yyyy"
              value={dateDJ}
              onChange={(newValue) => {
                setDateDJ(newValue.toISOString());
              }}
              renderInput={(params) => <TextField {...params} />}
            />
            <FormControlLabel
              className="mt-2"
              control={<Checkbox name="dj" />}
              label="Download Dow Jones"
              checked={downloadDj}
              onChange={handleChangeDj}
            />
          </div>
          <div className="d-flex align-self-center justify-content-end">
            <Button
              className="add-rule-btn"
              variant="outlined"
              size="medium"
              onClick={runComparison}
            >
              Generate Report
            </Button>
            <Button
              className="add-rule-btn ms-2"
              variant="outlined"
              size="medium"
              onClick={viewComparisonReport}
            >
              View Report
            </Button>
          </div>
        </div>
      </Paper>
      {isDataLoading && <SpinnerBlocker></SpinnerBlocker>}
      {data && data.length > 0 && (
        <div
          className="d-flex justify-content-around"
          style={{ minHeight: "20vh" }}
        >
          <TableContainer component={Paper} className="w-100 mt-5">
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Blocklist ID</StyledTableCell>
                  <StyledTableCell>BlockList Name</StyledTableCell>
                  <StyledTableCell align="right">Records Matched with Dow Jones</StyledTableCell>
                  <StyledTableCell align="right">Records Read from OFAC</StyledTableCell>
                  <StyledTableCell align="right">Unmatched Records from OFAC</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row) => (
                  <StyledTableRow key={row.name}>
                    <StyledTableCell>
                      {row.blocklistIblId}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {row.blocklistName}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.recordsMatchedwithDj}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.recordsReadFromOfac}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.unmatchedRecordsfromOfac}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
};

export default ComparisonReport;
