import { Paper } from "@mui/material";
import NoDataAvailable from '../../assets/images/icons/NoDataFolder.svg';
import './noDataPresent.scss';

const NoDataPresent = (props) => {
  return (
    <Paper
      elevate="2"
      sx={{ minHeight: "20rem", maxHeight: "20rem", width: "100%" }}
      className="d-flex flex-column justify-content-center align-items-center w-100 mt-3"
    >
      <img src={NoDataAvailable} alt="FacctumLogo" />
      <span className="no-data-available pt-1 mt-2">
        {props.message}
      </span>
    </Paper>
  );
};

export default NoDataPresent;