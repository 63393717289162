import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import SpinnerLoader from "../spinnerLoader/spinnerLoader";
import { selectedGridRowsCountSelector } from "@mui/x-data-grid";
import "./dropDown.scss";

const CommonSelect = (props) => {
  const {
    inputId,
    labelId,
    selectId,
    label,
    title,
    onChangeHandler,
    value,
    options,
    customStyle,
    loading,
    readOnly,
    selectHeigth,
    endAdornment,
    customSx,
    jurisdictionLabel,
  } = props;
  return (
    <>
      <FormControl id={jurisdictionLabel ? "selectAlign" : ""} sx={customStyle}>
        <InputLabel id={inputId}>{title}</InputLabel>
        <Select
          labelId={labelId}
          id={selectId}
          value={value}
          label={label}
          onChange={onChangeHandler}
          MenuProps={{
            PaperProps: {
              sx: {
                maxHeight: "30%",
                maxWidth: "8%",
              },
            },
          }}
          sx={{
            height: selectHeigth,
            "& .MuiSelect-iconOutlined": {
              display: customSx ? "none" : "",
            },
          }}
          inputProps={{ readOnly: readOnly }}
          endAdornment={endAdornment}
        >
          {options?.length > 0 ? (
            options.map((option, index) => (
              <MenuItem
                key={index}
                value={option.id}
                style={{whiteSpace: 'normal'}}
                sx={{
                  "&.MuiSelected": {
                    borderBottom: "none",
                  },
                }}
              >
                {option.value}
              </MenuItem>
            ))
          ) : (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ minHeight: "100px" }}
            >
              {!loading ? (
                <div className="no-option-text">No options found</div>
              ) : (
                <SpinnerLoader />
              )}
            </div>
          )}
        </Select>
      </FormControl>
    </>
  );
};

export default CommonSelect;
