/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Lottie from "lottie-react";

const JsonToSVG = ({ animationData }) => {
  return (
    <Lottie
      animationData={animationData}
      width={"100%"}
      loop={true}
      autoplay={true}
    />
  );
};

export default JsonToSVG;
