import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { listManagementAPI } from "../../api";
import SpinnerBlocker from "../../components/loader/spinnerBlocker/spinnerBlocker";
import "./appLanding.scss";
import SpinnerLoader from "../../components/spinnerLoader/spinnerLoader";

const AppLanding = () => {
  const navigate = useNavigate();
  const [isDataLoading, setIsDataLoading] = React.useState(false);
  const [watchlistOptions, setWatchListOptions] = React.useState(null);
  const [watchlist, setWatchList] = React.useState("");
  const [screenList, setScreenList] = React.useState("");
  const [open, setOpen] = React.useState({
    ibl: false,
    inclusion: false,
    exclusion: false,
  });
  const screen = useSelector((state) => state.metadata.screenList);
  const loading = useSelector((state) => state.metadata.loading)
  const handleChangeScreenList = (event) => {
    setIsDataLoading(true);
    setScreenList(event.target.value);
    setWatchList("");
    const asyncResponse = async () => {
      const responseFromAPI = await listManagementAPI.getWatchList({
        screenType: event.target.value,
      });
      setWatchListOptions(responseFromAPI.data);
      setIsDataLoading(false);
    };
    asyncResponse();
  };

  const handleChangeWatchList = (event) => {
    setWatchList(event.target.value);
  };

  const showRulesTable = () => {
    if (screenList === "" || watchlist === "") {
      toast.error("Please select Screen Type and Watchlist first.");
    } else {
      sessionStorage.setItem("screenList", screenList);
      sessionStorage.setItem("watchlist", watchlist);
      sessionStorage.setItem("category", 1);
      navigate("/dashboard");
    }
  };

  const showRulesTableForInclusion = () => {
    if (screenList === "" || watchlist === "") {
      toast.error("Please select Screen Type and Watchlist first.");
    } else {
      sessionStorage.setItem("screenList", screenList);
      sessionStorage.setItem("watchlist", watchlist);
      sessionStorage.setItem("category", 2);
      navigate("/dashboard");
    }
  };

  const goToIBL = (value) => {
    sessionStorage.setItem("selectedRole", value);
    navigate("/ibldashboard");
  };

  const handleClick = (id, value) => {
    if (id === "ibl") {
      setOpen((prevState) => ({
        ...prevState,
        ibl: !prevState.ibl,
        inclusion: false,
        exclusion: false,
      }));
    } else if (id === "inclusion") {
      setOpen((prevState) => ({
        ...prevState,
        ibl: false,
        inclusion: !prevState.inclusion,
        exclusion: false,
      }));
    } else if (id === "exclusion") {
      setOpen((prevState) => ({
        ...prevState,
        ibl: false,
        inclusion: false,
        exclusion: !prevState.exclusion,
      }));
    }
  };

  const goToReconciliation = () => {
    navigate("/showResult");
  }

  const goToComparison = () => {
    navigate("/comparison");
  }

  return (
    <>
      {isDataLoading && <SpinnerBlocker></SpinnerBlocker>}
      <div className="mb-2 mt-5 d-flex flex-column align-items-center">
        <Paper
          elevation={4}
          className="d-flex flex-column w-75 align-items-center justify-content-around mt-5"
        >
          <div className="d-flex w-75 align-items-center justify-content-end">
            <h2 className="w-50 h2-color">FACCTSET</h2>
            {!(watchlistOptions?.length > 0) && <div className="w-25"></div>}
            <FormControl
              className="w-40 d-flex align-self-end mb-5 mt-4 mx-0"
              variant="standard"
            >
              <InputLabel id="screen-list-input">SCREEN TYPE</InputLabel>
              <Select
                id="screen-list"
                value={screenList}
                label="SCREEN TYPE "
                onChange={handleChangeScreenList}
              >
                {screen?.length ?
                  screen.map((option, index) => (
                    <MenuItem key={index} value={option.screen_type_id}>
                      {option.name}
                    </MenuItem>
                  )) : (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ minHeight: "100px" }}
                    >
                      {!loading ? (
                        <div className="no-option-text">No options found</div>
                      ) : (
                        <SpinnerLoader />
                      )}
                    </div>
                  )}
              </Select>
            </FormControl>
            {screenList &&
              !isDataLoading &&
              (watchlistOptions?.length > 0 ? (
                <FormControl
                  className="w-40 d-flex align-self-end mb-5 mt-4 ms-4 me-0"
                  variant="standard"
                >
                  <InputLabel id="screen-list-input">WATCHLIST</InputLabel>
                  <Select
                    id="screen-list"
                    value={watchlist}
                    label="WATCHLIST"
                    onChange={handleChangeWatchList}
                  >
                    {watchlistOptions ?
                      watchlistOptions.map((option, index) => (
                        <MenuItem key={index} value={option.watchlist_id}>
                          {option.name}
                        </MenuItem>
                      )) : (
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ minHeight: "100px" }}
                        >
                          {!loading ? (
                           <div className="no-option-text">No options found</div>
                          ) : (
                            <SpinnerLoader />
                          )}
                        </div>
                      )}
                  </Select>
                </FormControl>
              ) : (
                null && toast.info("Not subscribed to any WATCHLIST !")
              ))}
          </div>
          <div className="d-flex flex-column w-75 align-items-end justify-content-around mb-5">
            <Paper
              style={{
                minHeight: "3rem",
                maxHeight: "3rem",
                backgroundColor: "#F3F8FB",
              }}
              elevation={4}
              className="w-100 d-flex justify-content-start align-items-center"
            >
              <span className="ms-3">1. List Management</span>
            </Paper>
            <Paper
              style={{
                minHeight: "3rem",
                maxHeight: "3rem",
                backgroundColor: "#F3F8FB",
              }}
              elevation={4}
              className="w-75 mt-2 d-flex justify-content-start align-items-center highlight"
            >
              <span className="mx-3 w-100 d-flex justify-content-between align-items-center" onClick={(prevState) => handleClick("ibl", !prevState)}>
                Internal Block List
                <IconButton
                  aria-label="expand row"
                  size="large"
                >
                  {open.ibl ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </IconButton>
              </span>
            </Paper>
            {open.ibl && (
              <>
                <Paper
                  style={{
                    minHeight: "3rem",
                    maxHeight: "3rem",
                    backgroundColor: "#F3F8FB",
                  }}
                  elevation={4}
                  className="w-50 mt-2 d-flex justify-content-start align-items-center highlight"
                >
                  <span
                    onClick={() => goToIBL("maker")}
                    className="mx-3 w-100 d-flex justify-content-between"
                  >
                    Maker
                    <ArrowForwardIcon
                      fontSize="small"
                      className="ms-1"
                    ></ArrowForwardIcon>
                  </span>
                </Paper>
                <Paper
                  style={{
                    minHeight: "3rem",
                    maxHeight: "3rem",
                    backgroundColor: "#F3F8FB",
                  }}
                  elevation={4}
                  className="w-50 mt-2 d-flex justify-content-start align-items-center highlight"
                >
                  <span
                    onClick={() => goToIBL("checker")}
                    className="mx-3 w-100 d-flex justify-content-between"
                  >
                    Checker
                    <ArrowForwardIcon
                      fontSize="small"
                      className="ms-1"
                    ></ArrowForwardIcon>
                  </span>
                </Paper>
              </>
            )}
            <Paper
              style={{
                minHeight: "3rem",
                maxHeight: "3rem",
                backgroundColor: "#F3F8FB",
              }}
              elevation={4}
              className="w-75 mt-2 d-flex justify-content-start align-items-center highlight"
            >
              <span className="mx-3 w-100 d-flex justify-content-between align-items-center" onClick={(prevState) => handleClick("inclusion", !prevState)}>
                Rules - Inclusions
                <IconButton
                  aria-label="expand row"
                  size="large"
                >
                  {open.inclusion ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </IconButton>
              </span>
            </Paper>
            {open.inclusion && (
              <>
                <Paper
                  style={{
                    minHeight: "3rem",
                    maxHeight: "3rem",
                    backgroundColor: "#F3F8FB",
                  }}
                  elevation={4}
                  className="w-50 mt-2 d-flex justify-content-start align-items-center highlight"
                >
                  <span
                    onClick={() => showRulesTableForInclusion()}
                    className="mx-3 w-100 d-flex justify-content-between"
                  >
                    Maker
                    <ArrowForwardIcon fontSize="small"></ArrowForwardIcon>
                  </span>
                </Paper>
                <Paper
                  style={{
                    minHeight: "3rem",
                    maxHeight: "3rem",
                    backgroundColor: "#F3F8FB",
                  }}
                  elevation={4}
                  className="w-50 mt-2 d-flex justify-content-start align-items-center highlight"
                >
                  <span
                    onClick={() => showRulesTableForInclusion()}
                    className="mx-3 w-100 d-flex justify-content-between"
                  >
                    Checker
                    <ArrowForwardIcon fontSize="small"></ArrowForwardIcon>
                  </span>
                </Paper>
              </>
            )}
            <Paper
              style={{
                minHeight: "3rem",
                maxHeight: "3rem",
                backgroundColor: "#F3F8FB",
              }}
              elevation={4}
              className="w-75 mt-2 d-flex justify-content-start align-items-center highlight"
            >
              <span className="mx-3 w-100 d-flex justify-content-between align-items-center" onClick={(prevState) => handleClick("exclusion", !prevState)}>
                Rules - Exclusions
                <IconButton
                  aria-label="expand row"
                  size="large"
                >
                  {open.exclusion ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </IconButton>
              </span>
            </Paper>
            {open.exclusion && (
              <>
                <Paper
                  style={{
                    minHeight: "3rem",
                    maxHeight: "3rem",
                    backgroundColor: "#F3F8FB",
                  }}
                  elevation={4}
                  className="w-50 mt-2 d-flex justify-content-start align-items-center highlight"
                >
                  <span
                    onClick={showRulesTable}
                    className="mx-3 w-100 d-flex justify-content-between"
                  >
                    Maker
                    <ArrowForwardIcon
                      fontSize="small"
                      className="ms-1"
                    ></ArrowForwardIcon>
                  </span>
                </Paper>
                <Paper
                  style={{
                    minHeight: "3rem",
                    maxHeight: "3rem",
                    backgroundColor: "#F3F8FB",
                  }}
                  elevation={4}
                  className="w-50 mt-2 d-flex justify-content-start align-items-center highlight"
                >
                  <span
                    onClick={showRulesTable}
                    className="mx-3 w-100 d-flex justify-content-between"
                  >
                    Checker
                    <ArrowForwardIcon
                      fontSize="small"
                      className="ms-1"
                    ></ArrowForwardIcon>
                  </span>
                </Paper>
              </>
            )}
            <Paper
              style={{
                minHeight: "3rem",
                maxHeight: "3rem",
                backgroundColor: "#F3F8FB",
              }}
              elevation={4}
              className="w-100 d-flex justify-content-start align-items-center mt-5"
            >
              <span className="ms-3">2. Comparison and Summary</span>
            </Paper>
            <Paper
              style={{
                minHeight: "3rem",
                maxHeight: "3rem",
                backgroundColor: "#F3F8FB",
              }}
              elevation={4}
              className="w-75 mt-2 d-flex justify-content-start align-items-center highlight"
              onClick={goToComparison}
            >
              <span className="mx-3 w-100 d-flex justify-content-between align-items-center" >
                Comparison Report
              </span>
            </Paper>
            <Paper
              style={{
                minHeight: "3rem",
                maxHeight: "3rem",
                backgroundColor: "#F3F8FB",
              }}
              elevation={4}
              className="w-75 mt-2 d-flex justify-content-start align-items-center highlight"
              onClick={goToReconciliation}
            >
              <span className="mx-3 w-100 d-flex justify-content-between align-items-center">
                Summary Report
              </span>
            </Paper>
          </div>
        </Paper>
        {/* <Button
        variant="text"
        className="color-black"
        onClick={goToReconciliation}
        >
        Go to Reconciliation
        </Button> */}
      </div>
    </>
  );
};

export default AppLanding;
