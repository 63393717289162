import { useEffect, useState } from "react";
import { Divider, TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import Stack from "@mui/material/Stack";

//style
// import "./dob.scss";

const DatePicker = (props) => {
  const {
    label,
    value,
    styles,
    disablePast,
    onChange,
    isDisabled,
    readOnly,
    minDate,
    disableFuture,
    inputFormat
  } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack spacing={1} className={`datepicker-contaier ${styles && styles} `}>
        <DesktopDatePicker
          label={label}
          inputFormat={inputFormat}
          value={value}
          onChange={onChange}
          renderInput={(params) => <TextField {...params} />}
          disablePast={disablePast}
          disabled={isDisabled}
          readOnly={readOnly}
          minDate={minDate}
          disableFuture={disableFuture}
        />
      </Stack>
    </LocalizationProvider>
  );
};

export default DatePicker;
