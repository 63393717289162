import { useEffect } from "react";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import "./detailsSection.scss";

const DetailsSection = (props) => {
  const { title, addFieldHandler, showAddIcon, helperText, isDisabled } = props;
  return (
    <div className="mt-2 details-container">
      <div className="details-header">
        <p className="details-title">{title}</p>
        <div>
          {showAddIcon && !isDisabled && (
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              className="color-black"
              onClick={addFieldHandler}
            >
              Add
            </Button>
          )}
        </div>
      </div>
      {helperText && !isDisabled && <p className="helper-text">{helperText}<span className="mandatory-text">*</span></p>}
      <div className="mb-3">{props.children}</div>
    </div>
  );
};

export default DetailsSection;
