import { Button, InputLabel, Pagination } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import DeleteIBLEntry from "../../features/IBLDetails/deleteIBLEntry/deleteIBLEntry";
import IBLTable from "../iblTable/iblTable";
import RightSidePopup from "../modal/modal";

export default function ListOfEntries({ listFor, rows, handler, pageCount }) {
  const [openModal, setOpenModal] = React.useState(false);
  const [deleteEntryList, setDeleteEntryList] = React.useState([]);
  const selectedRole = sessionStorage.getItem("selectedRole");
  const handleClose = () => setOpenModal(false);

  const getDeleteEntryList = (data) => {
    if (data.action === "add")
      setDeleteEntryList((prevArray) => [...prevArray, data.value]);
    if (data.action === "delete")
      setDeleteEntryList(
        deleteEntryList.filter((ele) => ele !== data.value.blocklist_entry_id)
      );
  };

  return (
    <>
      {listFor !== "pending" && (
        <div className="d-flex flex-column flex-grow-1">
          <div className="d-flex flex-column fcc-font-style ">
            <div
              style={{ width: "100%", minHeight: "2rem", maxHeight: "2rem" }}
              className={`row d-flex align-items-center`}
            >
              <div className="col-1"></div>
              <div className="col-5">
                <InputLabel className="fcc-name">NAME</InputLabel>
              </div>
              <div className="col-2 d-flex justify-content-center">
                <InputLabel className="fcc-name">DOB/DOR</InputLabel>
              </div>
              <div className="col-2 d-flex justify-content-center">
                <InputLabel className="fcc-name">NATIONAL ID</InputLabel>
              </div>
              <div className="col-1"></div>
              <div className="col-1"></div>
            </div>
            {rows &&
              rows.map((data, index) => {
                return (
                  <IBLTable
                    key={index}
                    listFor={listFor}
                    caller="entrylistapproveddraft"
                    data={data}
                    id={data.blocklist_id}
                    index={index}
                    role={selectedRole}
                    getDeleteEntryList={getDeleteEntryList}
                  ></IBLTable>
                );
              })}
          </div>
          <div className="d-flex align-items-start my-1">
            <Button
              variant="outlined"
              id="btn-select-delete"
              onClick={() => setOpenModal((prevState) => !prevState)}
            >
              DELETE SELECTED
            </Button>
          </div>
        </div>
      )}
      {listFor === "pending" && selectedRole === "maker" && (
        <div className="d-flex flex-column flex-grow-1">
          <div className="d-flex flex-column fcc-font-style ">
            <div
              style={{ width: "100%", minHeight: "2rem", maxHeight: "2rem" }}
              className={`row d-flex align-items-center`}
            >
              <div className="col-6 d-flex justify-content-start">
                <InputLabel className="fcc-name">NAME</InputLabel>
              </div>
              <div className="col-2 d-flex justify-content-center">
                <InputLabel className="fcc-name">DOB/DOR</InputLabel>
              </div>
              <div className="col-2 d-flex justify-content-center">
                <InputLabel className="fcc-name">NATIONAL ID</InputLabel>
              </div>
              <div className="col-2 d-flex justify-content-center">
                <InputLabel className="fcc-name">STATUS</InputLabel>
              </div>
            </div>
            {rows &&
              rows.map((data, index) => {
                return (
                  <IBLTable
                    key={index}
                    caller="entrylistpending"
                    data={data}
                    id={data.blocklist_id}
                    index={index}
                    role={selectedRole}
                  ></IBLTable>
                );
              })}
          </div>
        </div>
      )}
      {listFor === "pending" && selectedRole === "checker" && (
        <div className="d-flex flex-column flex-grow-1">
          <div className="d-flex flex-column fcc-font-style ">
            <div
              style={{ width: "100%", minHeight: "2rem", maxHeight: "2rem" }}
              className={`row d-flex align-items-center`}
            >
              <div className="col-1 d-flex justify-content-center">
                <InputLabel className="fcc-name">STATUS</InputLabel>
              </div>
              <div className="col-3 d-flex justify-content-start">
                <InputLabel className="fcc-name">NAME</InputLabel>
              </div>
              <div className="col-3 d-flex justify-content-center">
                <InputLabel className="fcc-name">MAKER ID</InputLabel>
              </div>
              <div className="col-2 d-flex justify-content-center">
                <InputLabel className="fcc-name">
                  PROPOSED START DATE
                </InputLabel>
              </div>
              <div className="col-2 d-flex justify-content-center">
                <InputLabel className="fcc-name">PROPOSED END DATE</InputLabel>
              </div>
              <div className="col-1"></div>
            </div>
            {rows &&
              rows.map((data, index) => {
                return (
                  <IBLTable
                    key={index}
                    caller="entrylistpending"
                    data={data}
                    id={data.blocklist_id}
                    index={index}
                    role={selectedRole}
                  ></IBLTable>
                );
              })}
          </div>
        </div>
      )}
      {selectedRole === "maker" && (
        <RightSidePopup open={openModal} width="26%">
          <DeleteIBLEntry
            handleClose={handleClose}
            deleteEntry={deleteEntryList}
            activeTab={listFor}
          />
        </RightSidePopup>
      )}
    </>
  );
}
