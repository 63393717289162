export const sortArrayofObj = (obj, key) => {
  obj.sort((a, b) => {
    let fa = a[key].toLowerCase();
    let fb = b[key].toLowerCase();

    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  });
  return obj;
};

export const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
          resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
          reject(error);
      };
  });
};

export const isValueEmpty = (data) => {
  let empty = false;
  data.map((obj) => {
    Object.values(obj).every((value) => {
      if (
        typeof value === "undefined" ||
        value === null ||
        value === "null" ||
        value === ""
      ) {
        empty = true;
      }
    });
  });
  return empty;
};
export const isEmptyCheck = (value) => {
  if (
    typeof value === "undefined" ||
    value === null ||
    value === "null" ||
    value === ""
  ) {
    return true;
  } else {
    return false;
  }
};

export const isCheckEmptyObject= (value) => {
  if (
    typeof value === "undefined" ||
    value === null ||
    value === "null" ||
    value === "" || (typeof value === "object" && Object.keys(value).length === 0) || (Array.isArray(value) && value.length === 0)
  ) {
    return true;
  } else {
    return false;
  }
};

export const isLeapYear = (year) => {
  if ((!(year % 4) && year % 100) || !(year % 400)) {
    return true;
  }

  return false;
};

