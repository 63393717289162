import React, { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./header.scss";
import facctview from "../../assets/images/logos/headerLogo.svg";
import { useTranslation } from "react-i18next";
import UserMenu from "../userMenu/userMenu";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import FacctSetLogo from "../../assets/images/logos/facctSetLogo";

const Header = () => {
  const isAuthenticated = useSelector((state) => state.login.isAuthenticated);
  const user = useSelector((state) => state.login.user);
  const navBarContainerRef = useRef(null);
  const scrollEventListener = (e) => {
    if (navBarContainerRef && window.scrollY > 64) {
      navBarContainerRef.current.style.height = "45px";
    } else if (navBarContainerRef && window.scrollY < 50) {
      navBarContainerRef.current.style.height = "54px";
    }
  };
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollEventListener);
    return () => {
      window.removeEventListener("scroll", scrollEventListener);
    };
  }, []);

  return (
    <div
      className="w-100 d-flex flex-row align-items-center justify-content-between px-5"
      id="applicationHeader"
      ref={navBarContainerRef}
    >
      <div className="d-flex align-items-center col-4">
        <div
          data-testid="company-name"
          id="headerApplicationLogoContainer"
          className="facctview-logo"
          onClick={() => {
            window.location = window.location.origin;
          }}
          role="button"
        >
          <FacctSetLogo />
        </div>
      </div>
      <h5 className="col-4 text-center" style={{ color: "white" }}>
        List Management
      </h5>

      <div
        id="headerUserContainer"
        className="flex-grow-1 d-flex justify-content-end col-4 "
      >
        <div className="me-2 d-flex justify-content-center align-items-center">
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={i18n.language.includes("en-") ? "en" : i18n.language}
              onChange={(e) => {
                changeLanguage(e.target.value);
              }}
              labelstyle={{ color: '#ff0000' }}
              sx={{
                color: "white",
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: 'white !important',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'white !important',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'white !important',
                },
                '.MuiSvgIcon-root ': {
                  fill: "white !important",
                },
                '&:before': {
                  borderColor: 'white !important',
                },
                '&:after': {
                    borderColor: 'white',
                }
              }}
            >
              <MenuItem value={"en"}>English</MenuItem>
              <MenuItem value={"es"}>Española</MenuItem>
            </Select>
          </FormControl>
        </div>
        {isAuthenticated && user && <UserMenu user={user} />}
      </div>
    </div>
  );
};

export default Header;
