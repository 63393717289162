import { useEffect, useState } from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import './tabs.scss';

const Tabs = (props) => {
  const { onChangeHandler, tabs, value } = props;
  return (
      <ToggleButtonGroup
        variant="text"
        color="primary"
        value={value}
        exclusive
        onChange={onChangeHandler}
        aria-label="Platform"
        id="btn-group"
      >
        {tabs.map((tab) => (
          <ToggleButton value={tab.value} key={tab.title}>
            {tab.title}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
  );
};

export default Tabs;
