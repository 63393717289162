import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as ListManagementApi  from "../api/listManagementAPI";


export const getDescriptions = createAsyncThunk(
  "meta/getDescriptions",
  async (body,thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    const response = await ListManagementApi.fetchDescriptions();
    thunkAPI.dispatch(setLoading(false));
    return response;
  }
);

export const getCountries = createAsyncThunk(
  "meta/getCountries",
  async (body,thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    const response = await ListManagementApi.fetchCountries();
    thunkAPI.dispatch(setLoading(false));
    return response;
  }
);

export const getRoles = createAsyncThunk(
  "meta/getRoles",
  async (body,thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    const response = await ListManagementApi.fetchRoles();
    thunkAPI.dispatch(setLoading(false));
    return response;
  }
);

export const getScreenList = createAsyncThunk(
  "meta/getScreenList",
  async (body,thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    const response = await ListManagementApi.getScreenList();
    thunkAPI.dispatch(setLoading(false));
    return response;
  }
);

const metadataInitialState = {
  loading: false,
  descriptions: [],
  countries:[],
  roles:[],
  screenList:[],
  error: false,
  selectedScreenList: null,
  selectedWatchList: null,
  selectedRole: null,
  selectedBlocklist: null,
  updateList: false
};

export const metadataSlice = createSlice({
  name: "metadata",
  initialState: { ...metadataInitialState },
  reducers: {
    resetMetadata: () => metadataInitialState,
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setSelectedScreenList: (state, action) => {
      state.selectedScreenList = action.payload;
    },
    setSelectedWatchList: (state, action) => {
      state.selectedWatchList = action.payload;
    },
    setSelectedRole: (state, action) => {
      state.selectedRole = action.payload;
    },
    setSelectedBlocklist: (state, action) => {
      state.selectedBlocklist = action.payload;
    },
    setUpdateList: (state, action) => {
      state.updateList = action.payload;
    },
  },
  extraReducers: {
    [getDescriptions.fulfilled]: (state, action) => {
      state.descriptions = action.payload;
    },
    [getDescriptions.rejected]: (state, action) => {
      state.loading = false;
      state.error = true;
    },
    [getCountries.fulfilled]: (state, action) => {
      state.countries = action.payload.data;
    },
    [getCountries.rejected]: (state, action) => {
      state.loading = false;
      state.error = true;
    },
    [getRoles.fulfilled]: (state, action) => {
      state.roles = action.payload.data;
    },
    [getRoles.rejected]: (state, action) => {
      state.loading = false;
      state.error = true;
    },
    [getScreenList.fulfilled]: (state, action) => {
      state.screenList = action.payload.data;
    },
    [getScreenList.rejected]: (state, action) => {
      state.loading = false;
      state.error = true;
    }
  },
});

export const { resetMetadata, setLoading, setSelectedScreenList, setSelectedWatchList, setSelectedRole, setSelectedBlocklist, setUpdateList} =
metadataSlice.actions;

export default metadataSlice.reducer;
