import { useEffect, useState } from "react";
import { TextField } from "@mui/material";

//components, constants
import CommonSelect from "../dropDown/dropDown";
import Autocomplete from "@mui/material/Autocomplete";

import { isLeapYear } from "../../constants/utility";
import { months } from "./DateConstants";
import { camelToUnderscore, camelize } from "../../constants/additionalInformationTabUtility";

const DateCompoment = (props) => {
  const { dob, onChangeHandler, labelId, loading = true, isDisabled, customLabel } = props;

  const [daysList, setDaysList] = useState(null);
  const [yearList, setYearList] = useState(null);
  let monthNumsWith31 = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  useEffect(() => {
    getYearList();
  }, []);

  useEffect(() => {
    if (dob?.month?.id !== null) getDaysInMonth();
  }, [dob?.month?.id]);

  useEffect(() => {
    if (dob?.year?.id !== null) getDaysInMonth();
  }, [dob?.year?.id]);

  const getDays = (totalDays) => {
    const days = [];
    for (let day = 1; day <= totalDays; day++) {
      days.push({ id: day, value: day });
    }
    setDaysList(days);
  };

  const getYearList = () => {
    const years = [];
    for (let year = 1900; year <= new Date().getFullYear(); year++) {
      years.push({ id: year, value: year });
    }
    setYearList(years);
  };

  const getDaysInMonth = () => {
    let totalNumDays = monthNumsWith31[parseInt(dob?.month?.id) - 1];
    if (
      parseInt(dob?.month?.id) === 2 &&
      dob?.year?.id &&
      isLeapYear(parseInt(dob?.year?.id))
    ) {
      totalNumDays = 29;
    }
    getDays(totalNumDays);
    if (totalNumDays < parseInt(dob?.day?.id))
      onChangeHandler(null, "day", props?.index);
  };

  return (
    <div className="d-flex mt-3 w-100">
      <Autocomplete
        disablePortal
        value={dob.year}
        id={labelId + "-year"}
        getOptionLabel={(option) => option?.value ? option.value.toString() : {}}
        options={yearList ? yearList : []}
        sx={{ width: "38%", marginRight: "1%" }}
        renderInput={(params) => <TextField {...params} label={customLabel?.yearLabel ? customLabel.yearLabel : "Year" } />}
        onChange={(event, newValue) => {
          onChangeHandler(newValue, customLabel?.yearLabel ? camelize(customLabel.yearLabel) : "year", props?.index);
        }}
        readOnly={isDisabled}
      />
      <Autocomplete
        disablePortal
        value={dob.month}
        id={labelId + "-year"}
        getOptionLabel={(option) => option?.value ? option.value.toString(): {}}
        options={months ? months : []}
        sx={{ width: "38%", marginRight: "1%" }}
        renderInput={(params) => <TextField {...params} label={customLabel?.monthLabel ? customLabel.monthLabel : "Month" } />}
        onChange={(event, newValue) => {
          onChangeHandler(newValue, customLabel?.monthLabel ? camelize(customLabel.monthLabel) : "month", props?.index)
        }}
        readOnly={isDisabled}
      />
      <Autocomplete
        disablePortal
        id={labelId + "-day"}
        value={dob.day}
        getOptionLabel={(option) => option?.value ? option.value.toString(): {}}
        options={daysList ? daysList : []}
        sx={{ width: "38%", marginRight: "1%" }}
        renderInput={(params) => <TextField {...params} label={customLabel?.dayLabel ? customLabel.dayLabel : "Day"} />}
        onChange={(event, newValue) => {
          onChangeHandler(newValue, customLabel?.dayLabel ? camelize(customLabel.dayLabel) : "day", props?.index);
        }}
        readOnly={isDisabled}
      />
    </div>
  );
};

export default DateCompoment;
