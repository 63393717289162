import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    enumData: {}
};

export const enumDetailsSlice = createSlice({
  name: "enumDetails",
  initialState: { ...initialState },
  reducers: {
    setEnumDetails: (state, action) => {
      state.enumData = action.payload ;
    },
  },
});

export const { setEnumDetails } = enumDetailsSlice.actions;

export default enumDetailsSlice.reducer;
