const NoDataFound = (props) => {
  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center w-100"
      style={{
        color: "#1C2472",
      }}
    >
      No data found
    </div>
  );
};

export default NoDataFound;
