import { TextField, Button, IconButton } from "@mui/material";
import { useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import "./searchBox.scss";

const SearchBox = (props) => {
  const [name, setName] = useState("");
  const [nationalId, setNationalId] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    props.onSearch(name, nationalId);
  };
  return (
    <form onSubmit={handleSubmit} autocomplete="off">
      <div id="ibl-search" className="d-flex flex-row w-100">
        <TextField
          value={name}
          size="small"
          className="my-3"
          id="name"
          label="Name"
          onChange={(e) => setName(e.target.value)}
          InputProps={{
            endAdornment: name && (
              <IconButton
                onClick={() => {
                  setName("");
                  props.onSearch("", nationalId);
                }}
              >
                <ClearIcon />
              </IconButton>
            ),
          }}
        />
        <TextField
          value={nationalId}
          size="small"
          className="m-3"
          id="nationalId"
          label="National ID"
          onChange={(e) => setNationalId(e.target.value)}
          InputProps={{
            endAdornment: nationalId && (
              <IconButton
                onClick={() => {
                  setNationalId("");
                  props.onSearch(name, "");
                }}
              >
                <ClearIcon />
              </IconButton>
            ),
          }}
        />
        <div className="my-auto">
          <Button type="submit" className="submit-button p-2" size="small">
            Search
          </Button>
        </div>
      </div>
    </form>
  );
};

export default SearchBox;
