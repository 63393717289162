import React, { useRef, useEffect } from "react";
import "./footer.scss";
import facctview from "../../assets/images/logos/headerLogo.svg";
import FacctSetLogo from "../../assets/images/logos/facctSetLogo";

const Footer = () => {
  const navBarContainerRef = useRef(null);
  const scrollEventListener = (e) => {
    if (navBarContainerRef && window.scrollY > 64) {
      navBarContainerRef.current.style.height = "45px";
    } else if (navBarContainerRef && window.scrollY < 50) {
      navBarContainerRef.current.style.height = "54px";
    }
  };


  useEffect(() => {
    window.addEventListener("scroll", scrollEventListener);
    return () => {
      window.removeEventListener("scroll", scrollEventListener);
    };
  }, []);

  return (
    <div
      className="w-100 d-flex flex-row align-items-center justify-content-between px-5"
      id="applicationFooter"
      ref={navBarContainerRef}
    >
      <div className="d-flex align-items-center col-4">
        <div
          data-testid="company-name"
          id="headerApplicationLogoContainer"
          className="facctview-logo"
          onClick={() => {
            window.location = window.location.origin;
          }}
          role="button"
        >
          <FacctSetLogo />
        </div>
      </div>
    </div>
  );
};

export default Footer;
