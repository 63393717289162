import {
  Button,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Paper,
  IconButton,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import { useState, useEffect } from "react";

//components, constant
import SpinnerLoader from "../spinnerLoader/spinnerLoader";
import { ruleConstants } from "../../constants";
import * as ListManagementApi from "../../api/listManagementAPI";
import { useTranslation } from "react-i18next";
import "./filterRules.scss";

const FilterRules = (props) => {
  const { t } = useTranslation();
  const [ruleRefList, setRuleRefList] = useState();
  const [ruleGroup, setRuleGroup] = useState("");
  const [ruleType, setRuleType] = useState("");
  const [selectedRuleGroup, setSelectedRuleGroup] = useState("");
  const [selectedRuleType, setSelectedRuleType] = useState("");
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [selectedTypeId, setSelectedTypeId] = useState(null);
  const [loadingRuleGroup, setLoadingRuleGroup] = useState(false)
  const [loadingRuleType, setLoadingRuleType] = useState(false)

  const selectRuleGroup = (event) => {
    setLoadingRuleType(true)
    const selected = ruleGroup.filter(
      (group) => group.ruleGroupName === event.target.value
    )[0];
    const uniqueRuleTypes = ruleRefList.filter(
      (i) => parseInt(i.rule_group_id) === parseInt(selected.ruleGroupId)
    );
    const typeArray = uniqueRuleTypes.map((ele) => {
      return {
        ruleRefId: ele.rule_ref_id,
        ruleGroupId: ele.rule_group_id,
        ruleTypeId: ele.rule_type_id,
        ruleTypeName: ruleConstants.RULE_TYPE_SHORT_TEXT[ele.rule_type_id],
      };
    });
    setRuleType(typeArray);
    setSelectedRuleGroup(event.target.value);
    setSelectedRuleType();
    setSelectedGroupId(selected.ruleGroupId);
    setLoadingRuleType(false)
  };

  const selectRuleType = (event) => {
    const selected = ruleType.filter(
      (type) => type.ruleTypeName === event.target.value
    )[0];
    setSelectedRuleType(event.target.value);
    setSelectedTypeId(selected.ruleTypeId);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.onSearch(selectedGroupId, selectedTypeId);
  };

  useEffect(() => {
    const asyncResponse = async () => {
      setLoadingRuleGroup(true)
      const responseFromAPI = await ListManagementApi.fetchRuleGroupType(
        sessionStorage.getItem("category")
      );
      setRuleRefList(responseFromAPI.data);
      let uniGroups = new Map();
      responseFromAPI.data.forEach((p) => uniGroups.set(p.rule_group_id, p));
      let uniqueGroups = [...uniGroups.values()];
      const groupArray = uniqueGroups.map((ele) => {
        return {
          ruleGroupId: ele.rule_group_id,
          ruleGroupName: ruleConstants.RULE_GROUP[ele.rule_group_id],
        };
      });
      setRuleGroup(groupArray);
      setLoadingRuleGroup(false)
    };
    asyncResponse();
  }, []);

  const handleRuleGroupClear = () => {
    setSelectedRuleGroup("");
    setSelectedGroupId(null);
    setSelectedRuleType("");
    setSelectedTypeId(null);
    props.onSearch(null, null);
  };

  const handleRuleTypeClear = () => {
    setSelectedRuleType("");
    setSelectedTypeId(null);
    props.onSearch(selectedGroupId, null);
  };

  return (
    <div className="w-50">
      <div className="d-flex flex-row w-100 mt-4 mb-2" id="rule-dropdown">
        <FormControl className="my-3 w-50">
          <InputLabel>{t("RuleGroupUppercase")}</InputLabel>
          <Select
            size="small"
            value={selectedRuleGroup}
            label="Rule Group"
            onChange={selectRuleGroup}
            sx={{
              "& .MuiSelect-iconOutlined": {
                display: selectedRuleGroup ? "none" : "",
              },
            }}
            endAdornment={
              selectedRuleGroup && (
                <IconButton onClick={handleRuleGroupClear}>
                  <ClearIcon />
                </IconButton>
              )
            }
          >
            {ruleGroup ?
              ruleGroup.map((rule) => (
                <MenuItem
                  key={rule.ruleGroupId}
                  data-id={rule.ruleGroupId}
                  value={rule.ruleGroupName}
                >
                  {t(rule.ruleGroupName)}
                </MenuItem>
              ))
              : (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ minHeight: "100px" }}
                >
                  {loadingRuleGroup ? (
                    <SpinnerLoader />
                  ) : (
                    <div className="no-option-text">No options found</div>
                  )}
                </div>
              )}
          </Select>
        </FormControl>
        <FormControl className="m-3 w-50">
          <InputLabel>{t("RuleTypeUppercase")}</InputLabel>
          <Select
            size="small"
            value={selectedRuleType}
            label="Rule Type"
            onChange={selectRuleType}
            sx={{
              "& .MuiSelect-iconOutlined": {
                display: selectedRuleGroup ? "none" : "",
              },
            }}
            endAdornment={
              selectedRuleType && (
                <IconButton onClick={handleRuleTypeClear}>
                  <ClearIcon />
                </IconButton>
              )
            }
          >
            {selectedRuleGroup &&
              ruleType ?
              ruleType.map((rule) => (
                <MenuItem
                  key={rule.ruleTypeId}
                  id={rule.ruleTypeId}
                  value={rule.ruleTypeName}
                >
                  {t(rule.ruleTypeName)}
                </MenuItem>
              )) : (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ minHeight: "100px" }}
                >
                  {loadingRuleType ? (
                    <SpinnerLoader />
                  ) : (
                    <div className="no-option-text">No options found</div>
                  )}
                </div>
              )
            }
          </Select>
        </FormControl>
        <div className="my-auto">
          <Button
            type="submit"
            className="p-2 submit-button"
            size="small"
            onClick={handleSubmit}
          >
            Search
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FilterRules;