import { createSlice } from "@reduxjs/toolkit";
import { setLoading } from "./enityDetailsRedux";

const initialState = {
    entityData: null,
};

export const enityDataSlice = createSlice({
  name: "enityData",
  initialState: { ...initialState },
  reducers: {
    resetEntityData: () => initialState,
    setEntityData: (state, action) => {
      state.entityData = action.payload;
    }
  }
});

export const {
    setEntityData,
    resetEntityData,
} = enityDataSlice.actions;

export default enityDataSlice.reducer;
