import { React, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Button, TextField } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { toast } from "react-toastify";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useTranslation } from "react-i18next";

//component, constants, Api handler
import { blockListManagementAPI } from "../../api";
import { convertBase64 } from "../../constants/utility";
import { IBL_STATUS_ID } from "../../constants/iblConstants";
import OverflowTip from "../../components/overflowTooltip/overflowTooltip"
// styles
import "./createBlockList.scss";
import SpinnerBlocker from "../../components/loader/spinnerBlocker/spinnerBlocker";

const CreateBlockList = (props) => {
  const { handleClose } = props;
  const { t } = useTranslation();
  const uploadFileRef = useRef(null);
  const userId = useSelector((state) => state.login?.user?.attributes?.email);
  const [blocklistData, setBlocklistData] = useState({
    blocklistName: "",
    description: "",
    fileName: null,
    file: null,
  });
  const [isloading, setLoading] = useState(false);

  const onChangeHandler = (event, key) => {
    setBlocklistData((prevState) => ({
      ...prevState,
      [key]: event.target.value,
    }));
  };

  const fileChangeHandler = async (event) => {
    if (event.target.files[0]) {
      const base64ConvertedFile = await convertBase64(event.target.files[0]);
      setBlocklistData((prevState) => ({
        ...prevState,
        file: base64ConvertedFile,
        fileName: event.target.files[0].name,
      }));
      toast.success(`File Selected: ${event.target.files[0].name}`, {
        autoClose: 2000,
      });
      if (uploadFileRef?.current?.value) {
        uploadFileRef.current.value = null;
      }
    } else {
      toast.error("Please Select a file", {
        autoClose: 2000,
      });
    }
  };

  const submitHandler = () => {
    if (blocklistData.fileName !== null) {
      createBlockListFile();
      return;
    }
    createBlockList();
  };

  const removeFileUpload = () => {
    setBlocklistData((prevState) => ({
      ...prevState,
      file: null,
      fileName: null,
    }));
  };

  const createBlockList = async () => {
    setLoading(true);
    const body = {
      api_action: 1,
      client_id: 1,
      blocklist_name: blocklistData.blocklistName,
      blocklist_desc: blocklistData.description,
      state_id: IBL_STATUS_ID["ACTIVE"],
      user_id: userId,
    };
    const response = await blockListManagementAPI.createInternalBlockList(body);

    if (response.status === 200 && response.data.data.length) {
      setLoading(false);
      toast.success("Blocklist Successfully created !");
    } else {
      setLoading(false);
      if (response?.response?.data) {
        toast.error(t(response?.response?.data?.code));
      } else {
        toast.error(t(response.code));
      }
    }
    handleClose();
  };

  const createBlockListFile = async () => {
    setLoading(true);
    const body = {
      api_action: 1,
      client_id: 1,
      blocklist_name: blocklistData.blocklistName,
      blocklist_desc: blocklistData.description,
      state_id: IBL_STATUS_ID["PENDING"],
      file: blocklistData.file,
      user_id: userId,
    };

    const response = await blockListManagementAPI.createBlocklistFile(body);
    if (response.status === 200 && Object.keys(response.data.data).length > 0) {
      setLoading(false);
      toast.success("Blocklist Successfully created !");
    } else {
      setLoading(false);
      if (response?.response?.data) {
        toast.error(t(response?.response?.data?.code));
      } else {
        toast.error(t(response.code));
      }
    }
    handleClose();
  };

  return (
    <>
      <div className="d-flex create-blocklist-container w-100 flex-column ">
        <div>
          <p className="create-blocklist-title">Creating a Blocklist</p>
          <div className="content">
            <p className="label">
              Enter Blocklist Name<span className="important-style">*</span>
            </p>
            <TextField
              fullWidth
              id="outlined-multiline-static"
              label="Enter Blocklist Name"
              placeholder="Blocklist Name"
              onChange={(event) => onChangeHandler(event, "blocklistName")}
            />
          </div>
          <div className="content mb-35">
            <p className="label">
              Description<span className="important-style">*</span>
            </p>

            <TextField
              fullWidth
              id="outlined-multiline-static"
              label="Enter Description"
              multiline
              rows={4}
              placeholder="Enter Description"
              onChange={(event) => onChangeHandler(event, "description")}
            />
          </div>
          <p className="optional-title">Optional</p>
          <div className="note">
            <p className="note-title">Import existing Blocklist</p>
            <p className="note-content">
              We have curated a document to help you create/alter your blocklist
              files into FacctView’s format for ease of integration. You can also
              download a sample file to further assist you with the same.
            </p>
            <div className="help-link">
              <span className="mr-8">
                {" "}
                <OpenInNewIcon
                  sx={{
                    color: "#000",
                    opacity: "58%",
                    cursor: "pointer",
                  }}
                />
              </span>
              <a
                href="#"
                // target="_blank"
                className="help-link-text"
              >
                How to Create and Upload
              </a>
            </div>
            <div className="help-link">
              <span className="mr-8">
                {" "}
                <DownloadForOfflineIcon
                  sx={{
                    color: "#000",
                    opacity: "58%",
                    cursor: "pointer",
                  }}
                />
              </span>

              <a
                href="https://lm-dev-public-bucket.s3.eu-west-2.amazonaws.com/ibl/ibl_bulk_upload.json"
                target="_blank"
                className="help-link-text"
              >
                Sample File (Format)
              </a>
            </div>
          </div>
          <div>
            <Button
              fullWidth
              className={`upload-file-btn  ${
                blocklistData?.fileName !== null && "button-disabled"
              }`}
              variant="outlined"
              size="large"
              color="error"
              onClick={() => {
                uploadFileRef.current.click();
              }}
              disabled={blocklistData?.fileName !== null}
            >
              <UploadFileIcon
                sx={{
                  color:
                    blocklistData?.fileName !== null
                      ? "rgba(0, 0, 0, 0.26)"
                      : "#FB665C",
                  cursor: "pointer",
                }}
                height={18}
                width={15}
              />
              <span
                className={`upload-file-btn-text ${
                  blocklistData?.fileName !== null && "disabled-style"
                }`}
              >
                UPLOAD FILE
              </span>
            </Button>
            <div className="d-flex mt-2 ms-4 justify-content-center align-items-center">
              <span className="file-name" style={{maxWidth: "100%"}}><OverflowTip value={blocklistData?.fileName} /></span>
              <span>
              {blocklistData?.fileName && (
                <CancelIcon
                  data-testid="cancel"
                  className="ms-2"
                  onClick={removeFileUpload}
                  sx={{
                    color: "#FB665C",
                    cursor: "pointer",
                  }}
                />
              )}
              </span>
            {" "}
              &nbsp;
            </div>

            <input
              data-testid="upload-file"
              onChange={fileChangeHandler}
              multiple={false}
              ref={uploadFileRef}
              type="file"
              accept=".csv,.json"
              hidden
            />
          </div>
        </div>
        <div>
          <div className="d-flex justify-content-end">
            <Button
              sx={{ marginRight: "1.6%" }}
              size="large"
              color="error"
              onClick={handleClose}
            >
              CANCEL{" "}
            </Button>
            <Button
              variant="contained"
              size="large"
              color="error"
              disabled={
                blocklistData.blocklistName === "" ||
                blocklistData.description === ""
              }
              onClick={submitHandler}
            >
              SUBMIT
            </Button>
          </div>
        </div>
      </div>
      {isloading && <SpinnerBlocker></SpinnerBlocker>}
    </>
  );
};

export default CreateBlockList;
