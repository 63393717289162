import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

//components, constant
import CustomRadioGroup from "../../components/customRadioGroup/customRadioGroup";
import CustomTextarea from "../../components/customTextarea/customTextarea";
import CustomTextbox from "../../components/customTextbox/customTextbox";
import ComplexRadioDateSection from "../../sections/complexRadioDateSection/complexRadioDateSection";
import CustomDropdowm from "../../components/customDropdown/customDropdown";
import CustomDropdownDate from "../../components/customDropdownDate/customDropdownDate";
import { monthsMapperObj } from "../../components/dateComponent/DateConstants";
import { COMPONENT_WITH_DATE } from "../../constants/iblConstants";
import SpinnerBlocker from "../../components/loader/spinnerBlocker/spinnerBlocker";
import CustomAttachment from "../../components/customAttachment/customAttachment";
import { setData } from "../../redux/aditionalInformationRedux";

const InformationDetails = (props) => {
  const { sortedAdditionalAttributes, loadingOnSubmit } = props;
  const loading = useSelector((state) => state.entityDetails.standard_data.loading);
  const dispatch = useDispatch();


  const getUIElements = (element) => {
    switch (element.ui_type) {
      case "complex_radio_date":
        return (
          <ComplexRadioDateSection
            attributeDetails={element}
          ></ComplexRadioDateSection>
        );
      case "textarea":
        return <CustomTextarea attributeDetails={element}></CustomTextarea>;
      case "textbox":
        return <CustomTextbox attributeDetails={element}></CustomTextbox>;
      case "radio_group":
        return <CustomRadioGroup attributeDetails={element}></CustomRadioGroup>;
      case "complex_dropdown":
        return <CustomDropdowm attributeDetails={element}/>;
      case "complex_dropdown_date":
        return <CustomDropdownDate attributeDetails={element}/>;
      case "complex_attachment":
        return <CustomAttachment attributeDetails={element}/>;
      default:
        return <></>;
    }
  };
  return <>
  { (loadingOnSubmit || loading) && <SpinnerBlocker /> }
  {sortedAdditionalAttributes && sortedAdditionalAttributes.map((ele) => getUIElements(ele))}
  </>
};

export default InformationDetails;
