import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    attachments: []
};

export const attachmentDataSlice = createSlice({
  name: "attachmentData",
  initialState: { ...initialState },
  reducers: {
    resetAttachmentsData: () => initialState,
    setAttachmentsData: (state, action) => {
      state.attachments = action.payload;
    }
  }
});

export const {
    setAttachmentsData,
    resetAttachmentsData
} = attachmentDataSlice.actions;

export default attachmentDataSlice.reducer;
