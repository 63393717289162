import * as React from "react";
import Paper from "@mui/material/Paper";
import { Button, TextareaAutosize, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ruleConstants } from "../../constants";
import { useSelector } from "react-redux";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Stack } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import { listManagementAPI } from "../../api";
import { useNavigate } from "react-router-dom";
import SpinnerBlocker from "../../components/loader/spinnerBlocker/spinnerBlocker";

export default function DraftsTable(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userName = useSelector((state) => state.login?.user?.attributes?.email);
  const occupation = useSelector((state) => state.metadata.roles);
  const region = useSelector((state) => state.metadata.countries);
  const descriptions = useSelector((state) => state.metadata.descriptions);
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());
  const [comments, setComments] = React.useState("");
  const [submitDisabled, setSubmitDisabled] = React.useState(true);
  const [isLoading, setLoading] = React.useState(false);
  const rule_category_id = sessionStorage.getItem("category") || 1;

  const handleStartDateChange = (newValue) => {
    if (newValue instanceof Date && !isNaN(newValue)) {
      setStartDate(newValue);
    }
  };

  const handleEndDateChange = (newValue) => {
    if (newValue instanceof Date && !isNaN(newValue)) {
      setEndDate(newValue);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const ruleSetData = [];
    props.rows.forEach((element) => {
      let proposed_start_dtm = element.proposed_start_dtm.toISOString();
      proposed_start_dtm = proposed_start_dtm.slice(0, -13)+"00:00:00Z";
      let proposed_end_dtm = element.proposed_end_dtm.toISOString();
      proposed_end_dtm = proposed_end_dtm.slice(0, -13)+"23:59:59Z";

      let body = {
        action: 1,
        client_id: 1,
        watchlist_id: parseInt(sessionStorage.getItem("watchlist")),
        screenType_Id: parseInt(sessionStorage.getItem("screenList")),
        rule_id: null,
        ruleref_id: element.ruleRefId.toString(),
        rule_value_1: element.value1,
        rule_value_2: element.value2,
        rule_value_3: element.value3,
        rule_value_4: element.value4,
        rule_value_5: element.value5,
        user_id: userName,
        proposed_start_dtm: proposed_start_dtm,
        proposed_end_dtm: proposed_end_dtm,
        comment: element.comment || "",
        rule_category_id
      };
      ruleSetData.push(body);
    });

    const response = await listManagementAPI.addDeleteRules(ruleSetData);
    if (response.status === 200 && response.data.data.length) {
      toast.success("Rule(s) ADDED for APPROVAL !");
      setLoading(false);
      navigate("/dashboard");
    } else {
      if (response?.response?.data) {
        toast.error(t(response?.response?.data?.code));
      } else {
        toast.error(t(response.code));
      }
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (props.rows) {
      props.rows.forEach((ele) => {
        ele.proposed_start_dtm = ele.proposed_start_dtm || new Date();
        ele.proposed_end_dtm = ele.proposed_end_dtm || new Date();
      });
    }
  }, [props.rows]);

  React.useEffect(() => {
    const allCommentsPresent = [];
    setSubmitDisabled(true);
    if (props.rows) {
      props.rows.forEach((ele) => {
        (ele.comment && ele.comment !== "" && ele.comment !== undefined)? allCommentsPresent.push("true"): allCommentsPresent.push("false");
      });
      setSubmitDisabled(allCommentsPresent.includes("false"));
    }
  }, [comments, props.rows]);

  return (
    <>
      {isLoading && <SpinnerBlocker></SpinnerBlocker>}
      {props.rows.map((row, index) => (
        <Paper
          sx={{ width: "100%", minHeight: "11rem", maxHeight: "11rem" }}
          elevation={3}
          className={`d-flex flex-row mb-1 hover-list ${
            index % 2 ? `alt-row-color` : ""
          }`}
          key={row.ruleId}
          id={"paper-" + row.ruleId}
        >
          <div
            className="ps-3 draft-rules-text mt-2"
            style={{ minWidth: "5%", maxWidth: "5%" }}
          >
            {++index}
          </div>
          <div
            className="ps-3 draft-rules-text mt-2"
            style={{ minWidth: "10%", maxWidth: "10%" }}
          >
            {t(ruleConstants.RULE_GROUP[row.ruleGroup])}
          </div>
          <div
            className="ps-3 draft-rules-text mt-2"
            style={{ minWidth: "20%", maxWidth: "20%" }}
          >
            {row.ruleRefId === 8 || row.ruleType === 7 ? (
              <>
                {rule_category_id === "2" ? t(ruleConstants.RULE_TYPE_INCLUSION[row.ruleType]) : t(ruleConstants.RULE_TYPE_EXCLUSION[row.ruleType])} <br /> Description 1:{" "}
                <strong>
                  {
                    descriptions.descr1.find(
                      (ele) =>
                        parseInt(ele.description1_id) === parseInt(row.value1)
                    )["description1_name"]
                  }
                </strong>
                {row.value2 && (
                  <>
                    <br /> Description 2:{" "}
                    <strong>
                      {
                        descriptions.descr2.find(
                          (ele) =>
                            parseInt(ele.description2_id) ===
                            parseInt(row.value2)
                        )["description2_name"]
                      }
                    </strong>
                  </>
                )}
                {row.value3 && (
                  <>
                    <br /> Description 3:{" "}
                    <strong>
                      {
                        descriptions.descr3.find(
                          (ele) =>
                            parseInt(ele.description3_id) ===
                            parseInt(row.value3)
                        )["description3_name"]
                      }
                    </strong>
                  </>
                )}
              </>
            ) : row.ruleRefId === 3 ||
              row.ruleType === 11 ||
              row.ruleRefId === 16 || 
              row.ruleRefId === 19 || 
              row.ruleRefId === 23 ||
              row.ruleType === 2 ? (
              <>
                {rule_category_id === "2" ? t(ruleConstants.RULE_TYPE_INCLUSION[row.ruleType]) : t(ruleConstants.RULE_TYPE_EXCLUSION[row.ruleType])} <br />
                <strong>
                  {
                    region.find((ele) => ele.country_code === row.value1)[
                      "country_name"
                    ]
                  }
                </strong>
              </>
            ) : row.ruleRefId === 9 || row.ruleType === 8 ? (
              <>
                {rule_category_id === "2" ? t(ruleConstants.RULE_TYPE_INCLUSION[row.ruleType]) : t(ruleConstants.RULE_TYPE_EXCLUSION[row.ruleType])} <br />
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`https://dev-facctview.facctum.ai/profile?entityId=${row.value1}`}
                >
                  {row.value1}
                </a>
              </>
            ) : row.ruleRefId === 17 || row.ruleRefId === 24 || row.ruleType === 12 ? (
              <>
                {rule_category_id === "2" ? t(ruleConstants.RULE_TYPE_INCLUSION[row.ruleType]) : t(ruleConstants.RULE_TYPE_EXCLUSION[row.ruleType])} <br />
                <strong>
                  {
                    occupation.find(
                      (ele) =>
                        parseInt(ele.occupation_code) === parseInt(row.value1)
                    )["occupation_name"]
                  }
                </strong>
              </>
            ) : (
              <>
                {rule_category_id === "2" ? t(ruleConstants.RULE_TYPE_INCLUSION[row.ruleType]) : t(ruleConstants.RULE_TYPE_EXCLUSION[row.ruleType])} <br />{" "}
                <strong>{row.value1}</strong>
              </>
            )}
          </div>
          <div
            className="draft-rules-text mt-2"
            style={{ minWidth: "35%", maxWidth: "35%" }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns} className="mt-2">
              <Stack
                className="d-flex flex-row justify-content-around w-100"
                id="date-container"
              >
                <DesktopDatePicker
                  label="Proposed Start Date"
                  inputFormat="MM/dd/yyyy"
                  value={row?.proposed_start_dtm || new Date()}
                  onChange={(e) => {
                    row.proposed_start_dtm = e;
                    handleStartDateChange(e);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  disablePast={true}
                />
                <DesktopDatePicker
                  label="Proposed End Date"
                  inputFormat="MM/dd/yyyy"
                  value={row?.proposed_end_dtm || new Date()}
                  onChange={(e) => {
                    row.proposed_end_dtm = e;
                    handleEndDateChange(e);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  minDate={row?.proposed_start_dtm || new Date()}
                />
              </Stack>
            </LocalizationProvider>
          </div>
          <div
            className="ps-3 draft-rules-text mt-2"
            style={{ minWidth: "25%", maxWidth: "25%" }}
          >
            <TextareaAutosize
              label="Comments"
              aria-label="comments"
              minRows={7}
              placeholder="Comments"
              className="draft-rules-text w-100"
              onChange={(e) => {
                row.comment = e.target.value;
                setComments(e.target.value);
              }}
            />
          </div>
          <div
            className="d-flex align-items-center justify-content-around"
            style={{ minWidth: "5%", maxWidth: "5%" }}
          >
            <span>
              <CloseIcon
                onClick={() => props.deleterow(--index)}
                className="close-btn-hover"
              />
            </span>
          </div>
        </Paper>
      ))}
      <Button
        className={`exclude-btn fcc-submit-btn d-flex align-self-end mb-5 mt-3`}
        variant={"contained"}
        onClick={handleSubmit}
        disabled={submitDisabled}
      >
        {t("SubmitApproval")}
      </Button>
    </>
  );
}