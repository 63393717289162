import axios from "axios";
import { apiConstant } from "../constants";

export const createInternalBlockList = (data) => {
  return axios
    .post(apiConstant.apiURL.createBlocklist, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const createBlocklistFile = (data) => {
  return axios
    .post(apiConstant.apiURL.createBlocklistFile, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const entriesModHandler = (data) => {
  return axios
    .post(apiConstant.apiURL.entriesModHandler, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const fetchNameAndNationalType = (queryParam) => {
  let url = apiConstant.apiURL.getNameAndNationalTypeUrl;
  return axios
    .get(url)
    .then((response) => response.data)
    .catch((error) => {
      return error;
    });
};
export const fetchIBList = (queryParam) => {
  let url = apiConstant.apiURL.getBlockList + "?client_id=1";
  if (queryParam.offset) {
    url = url + "&offset=" + queryParam.offset;
  }

  if (queryParam.limit) {
    url = url + "&limit=" + queryParam.limit;
  }
  return axios.get(url).then((response) => {
    return response.data;
  });
};

export const fetchIBListDetails = (queryParam) => {
  let url = apiConstant.apiURL.getBlockList + "?client_id=1";
  if (queryParam.blocklistId) {
    url = url + "&blocklist_id=" + queryParam.blocklistId;
  }
  return axios.get(url).then((response) => {
    return response.data;
  });
};

export const fetchIBListEntries = (queryParam) => {
  let url = apiConstant.apiURL.getBlockListEntries + "?client_id=1";
  if (queryParam.blocklistId) {
    url = url + "&blocklist_id=" + queryParam.blocklistId;
  }
  if (queryParam.offset) {
    url = url + "&offset=" + queryParam.offset;
  }
  if (queryParam.limit) {
    url = url + "&limit=" + queryParam.limit;
  }
  if (queryParam.name) {
    url = url + "&name=" + queryParam.name;
  }
  if (queryParam.national_id) {
    url = url + "&national_id=" + queryParam.national_id;
  }
  url = url + "&valid_at_or_after=" + new Date().toISOString();
  return axios.get(url).then((response) => {
    return response.data;
  });
};

export const fetchIBListEntriesMods = (queryParam) => {
  let url = apiConstant.apiURL.getBlockListEntriesMod + "?client_id=1";
  if (queryParam.blocklistId) {
    url = url + "&blocklist_id=" + queryParam.blocklistId;
  }
  if (queryParam.offset) {
    url = url + "&offset=" + queryParam.offset;
  }
  if (queryParam.limit) {
    url = url + "&limit=" + queryParam.limit;
  }
  if (queryParam?.status >= 0) {
    url = url + "&status=" + queryParam.status;
  }
  if (queryParam.blocklistEntryId) {
    url = url + "&blocklist_entry_id=" + queryParam.blocklistEntryId;
  }
  if (queryParam.name) {
    url = url + "&name=" + queryParam.name;
  }
  if (queryParam.national_id) {
    url = url + "&national_id=" + queryParam.national_id;
  }
  if (queryParam.version_no) {
    url = url + "&version_no=" + queryParam.version_no;
  }

  return axios.get(url).then((response) => {
    return response.data;
  });
};

export const getMasterBlocklistEntries = (queryParam) => {
  let url = apiConstant.apiURL.getMasterBlocklistEntries + "?client_id=1";
  if (queryParam.blocklist_id) {
    url = url + "&blocklist_id=" + queryParam.blocklist_id;
  }
  if (queryParam.blocklist_entry_id) {
    url = url + "&blocklist_entry_id=" + queryParam.blocklist_entry_id;
  }
  if (queryParam.version_no) {
    url = url + "&version_no=" + queryParam.version_no;
  }

  url = url + "&valid_at_or_after=" + new Date().toISOString();

  return axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getStageBlocklistEntries = (queryParam) => {
  let url = apiConstant.apiURL.getStageBlocklistEntries + "?client_id=1";
  if (queryParam.blocklist_id) {
    url = url + "&blocklist_id=" + queryParam.blocklist_id;
  }
  if (queryParam.blocklist_entry_id) {
    url = url + "&blocklist_entry_id=" + queryParam.blocklist_entry_id;
  }
  if (queryParam.version_no) {
    url = url + "&version_no=" + queryParam.version_no;
  }
  return axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getEntriesComments = (queryParam) => {
  let url = apiConstant.apiURL.getEntriesComment + "?client_id=1";
  if (queryParam.blocklist_id) {
    url = url + "&blocklist_id=" + queryParam.blocklist_id;
  }
  if (queryParam.blocklist_entry_id) {
    url = url + "&blocklist_entry_id=" + queryParam.blocklist_entry_id;
  }
  if (queryParam.version_no) {
    url = url + "&version_no=" + queryParam.version_no;
  }
  return axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const addUpdateEntity = (data) => {
  return axios
    .post(apiConstant.apiURL.getBlockListEntriesMod, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getAdditionalAttributes = (queryParam) => {
  let url = apiConstant.apiURL.getAdditionalAttributes + "?client_id=1";
  return axios.get(url).then((response) => {
    return response.data;
  });
};


export const attachments = (data) => {
  return axios
    .post(apiConstant.apiURL.attachments, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
