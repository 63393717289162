import React from "react";
import { Button, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useTranslation } from "react-i18next";
import Add from "@mui/icons-material/Add";
import TableForActiveAndPendingRules from "../../components/table/tableForActiveAndPendingRules";
import TableForApproval from "../../components/table/tableForApproval";
import "./dashboard.scss";
import { useNavigate } from "react-router-dom";
import * as ListManagementApi from "../../api/listManagementAPI";
import TablePlaceholder from "../../components/table/tablePlaceholder";
import { toast } from "react-toastify";
import Pagination from "@mui/material/Pagination";
import NoDataPresent from "../../components/noDataPresent/noDataPresent";
import format from "date-fns/format";
import FilterRules from "../../components/filterRules/filterRules";
import { useSelector, useDispatch } from "react-redux";
import { setUpdateList } from "../../redux/metadataRedux";

const Dashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [tab, setTab] = React.useState("activeRules");
  const navigate = useNavigate();
  const [rows, setRows] = React.useState(null);
  const [pageCount, setPageCount] = React.useState(null);
  const [isDataLoading, setIsDataLoading] = React.useState(false);
  const [ruleList, setRuleList] = React.useState(null);
  const screenSelected = sessionStorage.getItem("screenList") || null;
  const watchlistSelected = sessionStorage.getItem("watchlist") || null;
  const rule_category_id = sessionStorage.getItem("category") || 1;
  const [searchAttributes, setSearchAttributes] = React.useState({
    ruleGroup: null,
    ruleType: null,
  });
  const isUpdateRequired = useSelector((state) => state.metadata.updateList);


  const handleChange = (event, newtab) => {
    if (null !== newtab) setTab(newtab);
  };

  const handlePageChange = (event, value) => {
    getListOfRules(value);
  };

  const navigateToAddRule = (event) => {
    navigate("/addrule");
  };

  const onSearch = (ruleGroupVal, ruleTypeVal) => {
    setSearchAttributes((prevState) => ({
      ...prevState,
      ruleGroup: ruleGroupVal,
      ruleType: ruleTypeVal,
    }));
  };

  const formatRows = (responseFromAPI) => {
    return responseFromAPI.data.map((element) => {
      return createData(
        element.rule_id,
        tab === "activeRules" ? element.rule_group_id : element.rule_group_id,
        tab === "activeRules" ? element.rule_type_id : element.rule_type_id,
        element.rule_value_1,
        element.rule_value_2,
        element.rule_value_3,
        element.rule_value_4,
        element.rule_value_5,
        tab === "activeRules"
          ? element.validity_from_dtm
          : element.proposed_start_dtm,
        tab === "activeRules"
          ? element.validity_to_dtm
          : element.proposed_end_dtm,
        element.watchlist_id,
        tab === "activeRules" ? element.added_user_id : element.initiated_by,
        tab === "activeRules" ? element.added_dtm : element.initiated_dtm,
        tab === "activeRules" ? element.updated_user_id : null,
        tab === "activeRules" ? element.updated_dtm : null,
        element.rule_ref_id,
        tab === "activeRules" ? element.in_use_from_dtm : null,
        tab === "activeRules" ? element.in_use_to_dtm : null,
        tab !== "activeRules" ? element.action : null,
        tab !== "activeRules" ? element.comment : null,
        element.rule_version_number
      );
    });
  };

  function getListOfRules(value) {
    setRuleList(null);
    setRows(null);
    const asyncResponse = async () => {
      setIsDataLoading(true);
      try {
        let responseFromAPI;
        let formatedRows;
        if (tab === "pendingQueue" || tab === "approvalQueue") {
          responseFromAPI = await ListManagementApi.fetchPendingRules({
            rule_group_id: searchAttributes.ruleGroup,
            rule_type_id: searchAttributes.ruleType,
            offset: value ? (value - 1) * 10 : 0 ,
            limit: 10,
            rule_category_id,
            watchListId: watchlistSelected
          });
        } else {
          const currentDateTime = new Date().toISOString().slice(0, -5);
          responseFromAPI = await ListManagementApi.fetchActiveRules({
            rule_group_id: searchAttributes.ruleGroup,
            rule_type_id: searchAttributes.ruleType,
            screenTypeId: screenSelected,
            watchListId: watchlistSelected,
            currentDateTime: currentDateTime + "Z",
            offset: value ? (value - 1) * 10 : 0 ,
            limit: 10,
            rule_category_id
          });
        }

        formatedRows = await formatRows(responseFromAPI);
        setPageCount(Math.ceil(responseFromAPI.count / 10));
        setRuleList(responseFromAPI.data);
        setRows(formatedRows);
        dispatch(setUpdateList(false));
      } catch (e) {
        toast.error("Error fetching data");
        setRows(null);
      } finally {
        setIsDataLoading(false);
      }
    };
    asyncResponse();
  }

  function createData(
    ruleId,
    ruleGroup,
    ruleType,
    ruleValue1,
    ruleValue2,
    ruleValue3,
    ruleValue4,
    ruleValue5,
    from,
    to,
    listType,
    addedUserId,
    addedDate,
    updatedUserId,
    updatedDate,
    ruleRefId,
    inUseStartDate,
    inUseEndDate,
    action,
    comment,
    ruleVersionNumber
  ) {
    return {
      ruleId,
      ruleGroup,
      ruleType,
      ruleValue1,
      ruleValue2,
      ruleValue3,
      ruleValue4,
      ruleValue5,
      from,
      to,
      listType,
      addedUserId,
      addedDate,
      updatedUserId,
      updatedDate,
      ruleRefId,
      inUseStartDate,
      inUseEndDate,
      action,
      comment,
      ruleVersionNumber
    };
  }

  React.useEffect(() => {
    getListOfRules();
  }, [tab, watchlistSelected, screenSelected, searchAttributes]);

  React.useEffect(() => {
    if(isUpdateRequired) getListOfRules();
  }, [isUpdateRequired]);

  return (
    <>
      <div className="d-flex align-items-end h-custom">
        {rule_category_id === "2" ? <span className="rule-header">{t('RuleInclusion')}</span> : <span className="rule-header">{t('RuleExclusion')}</span>}
      </div>
      <div className="mb-2 d-flex justify-content-between mt-4 align-items-center">
        <ToggleButtonGroup
          variant="text"
          color="primary"
          value={tab}
          exclusive
          onChange={handleChange}
          aria-label="Platform"
          id="rule-btn-group"
        >
          <ToggleButton value="activeRules">{t("ActiveRule")}</ToggleButton>
          <ToggleButton value="pendingQueue">{t('PendingQueue')}</ToggleButton>
          <ToggleButton value="approvalQueue">{t('ApprovalQueue')}</ToggleButton>
        </ToggleButtonGroup>
        <div className="d-flex flex-column justify-content-end">
          <Button
            className="add-rule-btn"
            variant="outlined"
            size="medium"
            onClick={navigateToAddRule}
            startIcon={<Add />}
          >
            {t("addRule")}
          </Button>
        </div>
      </div>

      <FilterRules onSearch={onSearch} />

      {isDataLoading ? (
        <TablePlaceholder />
      ) : (
        <>
          {(tab === "activeRules" || tab === "pendingQueue") && rows ? (
            <TableForActiveAndPendingRules
              tableFor={tab}
              rows={rows}
            ></TableForActiveAndPendingRules>
          ) : tab === "approvalQueue" && rows ? (
            <TableForApproval rows={rows}></TableForApproval>
          ) : (
            <NoDataPresent message="No entry available in the list"></NoDataPresent>
          )}
        </>
      )}

      {pageCount > 1 && (
        <div
          className="d-flex justify-content-center align-items-center position-relative"
          id="pagination-div"
        >
          <Pagination
            count={pageCount}
            shape="rounded"
            onChange={handlePageChange}
          />
        </div>
      )}
    </>
  );
};

export default Dashboard;
