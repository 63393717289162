export const TRANSLATIONS_EN = {
  // ssoLogin: "SSO Login",
  // approvedRules: "APPROVED RULES",
  // addRules: "ADD RULES",
  addRule: "ADD RULE",
  approveRule: "Approve Rule",
  List: "LIST",
  Entity: "ENTITY",
  LowQualityAliasNames: "LQAKA",
  PEP: "PEP",
  ExcludebySuspended: "Exclude by suspended",
  ExcludebyCountryCode: "Exclude by jurisdiction",
  ExcludebyProviderCode: "Exclude by provider code",
  ExcludebyReferenceCode: "Exclude by reference code",
  ExcludebyInactive: "Exclude by inactive",
  ExcludebyDeceased: "Exclude by deceased",
  ExcludebyCategory: "Exclude by category or descriptions combinations",
  ExcludebyId: "Exclude by ID",
  ExcludebyName: "Exclude by LQ(Low Quality Names)",
  ExcludebyRADS: "Exclude Requires Additional Domestic Screening",
  ExcludebyCitizenship: "Exclude by citizenship",
  ExcludebyRoles: "Exclude by Roles",
  IncludebySuspended: "Include by suspended",
  IncludebyCountryCode: "Include by jurisdiction",
  IncludebyProviderCode: "Include by provider code",
  IncludebyReferenceCode: "Include by reference code",
  IncludebyInactive: "Include by inactive",
  IncludebyDeceased: "Include by deceased",
  IncludebyCategory: "Include by Category or descriptions combinations",
  IncludebyId: "Include by id",
  IncludebyRADS: "Include by Requires Additional Domestic Screening",
  IncludebyCitizenship: "Include by Citizenship",
  IncludebyRoles: "Include by Roles",
  AddExclusionRules: "Add Exclusion Rules",
  SelectRuleGroup: "Select Rule Group",
  RuleTypeSelection: "Rule Type Selection",
  BACK: "BACK",
  HowToCreateAndSubmitRule: "How to create and submit a rule:",
  RuleAll: "All",
  willBe: "will be",
  // Excluded: "Excluded",
  EXCLUDED: "EXCLUDED",
  FromYour: "from your",
  InYour: "in your",
  ScreeningProcess: "screening process",
  Exclude: "Exclude",
  ProvideCommentsAndProceedForApproval:
    "Proceed to provide comments, then proceed for approval",
  DraftRules: "Draft Rules",
  SuspendedList: "Suspended List",
  Countries: "Country(s)",
  Providers: "Provider(s)",
  ReferenceCodes: "Reference Code(s)",
  Inactives: "Inactives",
  Deceased: "Deceased",
  Categorys: "Category(s)",
  SelectedID: "Selected ID",
  LQNAMES: "LQ(Low Quality Names)",
  RADS: "Requires Additional Domestic screening",
  Citizenships: "Citizenship(s)",
  Roles: "Roles",
  Description: "Description",
  Reject: "REJECT",
  Approve: "APPROVE",
  // "Are you sure want to REJECT this rule?":
  //   "Are you sure want to reject this rule?",
  // "Are you sure want to APPROVE this rule?":
  //   "Are you sure want to approve this rule?",
  NO: "NO",
  YES: "YES",
  RuleID: "RULE ID",
  SerialNo: "Serial No.",
  RuleGroupUppercase: "RULE GROUP",
  ProposedStartDate: "PROPOSED START DATE",
  ProposedEndDate: "PROPOSED END DATE",
  RuleType: "RULE TYPE",
  Action: "ACTION",
  Dates: "DATES",
  // MakerComment: "MAKER'S COMMENTS",
  Comment: "COMMENTS",
  SubmitApproval: "SUBMIT FOR APPROVAL",
  // SelectScreenList: "SELECT SCREEN LIST",
  // SelectWatchList: "SELECT WATCH LIST",
  // Continue: "Continue",
  ActiveRule: "ACTIVE RULES",
  PendingQueue: "PENDING QUEUE",
  ApprovalQueue: "APPROVAL QUEUE",
  RuleTypeUppercase: "RULE VALUE",
  From: "FROM",
  To: "TO",
  Search: "SEARCH",
  // Selected: "Selected",
  All: "All",
  AddInclusionRules:"Add Inclusion Rules",
  ExcludeRuleType:"Exclude Rule Type",
  IncludeRuleType:"Include Rule Type",
  HowToCreateAndSubmitARule:"How to create and submit a rule",
  StartBySelectingRuleGroup:"Start by selecting rule group",
  Step1: "Step 1",
  Step2: "Step 2",
  Step3: "Step 3",
  Step4: "Step 4",
  Step5: "Step 5",
  SelectThe: "Select the",
  RuleGroup: "  rule group",
  YouWantToExclude: " you want to exclude.",
  YouWantToInclude: " you want to include.",
  SelectExclusionCriteriaUnder: "Select exclusion criteria under",
  SelectInclusionCriteriaUnder: "Select inclusion criteria under",
  RuleType: " rule type.",
  AddFurtherSpecificationsIfRequired: " Add further specifications if required.",
  Click: "Click",
  AndAddToTheQueue: " and add to the queue.",
  OnceInQueue:"Once in queue, add comments to all the rules",
  OnceInQueueInclusion: "Once in queue, add comments to all the rules",
  SubmitFor:"Submit for",
  Approval: " approval.",
  LowQualitysNames: "LQ(Low Quality Names)",
  ListContent: "All lists related to selected rule type/records will be excluded",
  PEPContent: "All politically exposed persons related to selected rule type will be excluded.",
  PEPContentInclusion: "All politically exposed persons related to selected rule type will be included.",
  EntityContent: "All entity related to selected rule type/records will be excluded.",
  LQNamesContent:"All LQ( Low Quality) AKA( Also Known As) related to selected rule type will be excluded.",
  Suspended: "Suspended",
  Suspendedcontent: "All suspended lists will be excluded.",
  Jurisdiction: "Jurisdiction",
  JurisdictionContent:"All lists from the selected country/jurisdiction will be excluded.",
  JurisdictionContentInclusion:"All lists from the selected country/jurisdiction will be included.",
  Provider: "Provider",
  ProviderContent: "All lists from the selected providers will be excluded.",
  ProviderContentInclusion: "All lists from the selected providers will be included.",
  Lists: "List ID",
  ListContent: "All lists will be excluded.",
  ListIDsContent: "All list IDs selected will be excluded.",
  ListContentInclusion: "All lists will be included.",
  ListIDsContentInclusion: "All list IDs selected will be included.",
  SpecifyTheRuleType : "Specify the rule type:",
  Inactive: "Inactive",
  EntityInactiveContent: "All entities/individuals inactive will be excluded.",
  PEPInactiveContent: "All politically exposed persons inactive will be excluded.",
  Category: "Category",
  CategoryContent: "All entities/individuals of selected categories will be excluded.",
  EntityID: "Entity ID",
  EntityRuleContent: "All entity IDs/individual IDs will be excluded.",
  DeceasedContent: "All entities/individuals deceased will be excluded.",
  PEPDeceasedContent: "All politically exposed persons will be excluded.",
  DeceasedContentInclusion: "All deceased lists will be included.",
  LQAKANAMES: "All LQAKA NAMES will be excluded from your screening process",
  RADS: "RADS",
  InactiveContent: "All inactive lists will be excluded.",
  PEPInactiveContentInclusion: "All inactive lists will be included.",
  RADSContent: "All politically exposed persons marked as requiring additional domestic screening will be excluded.",
  RADSContentInclusion: "All politically exposed persons marked as requiring additional domestic screening will be included.",
  Citizenship: "Citizenship",
  CitizenshipContent: "All politically exposed persons from the selected countries will be excluded.",
  CitizenshipContentInclusion: "All politically exposed persons from the selected countries will be included.",
  RolesContent: "All politically exposed persons with selected roles will be excluded.",
  RolesContentInclusion: "All politically exposed persons with selected roles will be included.",
  ListID : "LIST ID",
  AllPEPContentInclusion: "All politically exposed persons will be included",
  PoliticallyExposedPerson: "Politically exposed persons",
  LQAKA: "LQ( Low Quality) AKA( Also Known As)",
  RADSTooltipContent: "Requires additional domestic screening",
  AllContentInclusion: "All lists will be included",
  SELECT_COMPONENT_INCLUSION_TITLES_2: "List from selected country/jurisdiction ",
  SELECT_COMPONENT_INCLUSION_TITLES_3: "Lists from selected providers ",
  SELECT_COMPONENT_INCLUSION_TITLES_4: "Selected list IDs ",
  SELECT_COMPONENT_INCLUSION_TITLES_10: "All politically exposed persons marked as requiring additional domestic screening ",
  SELECT_COMPONENT_INCLUSION_TITLES_11: "All politically exposed persons from selected countries ",
  SELECT_COMPONENT_INCLUSION_TITLES_12: "Politically exposed persons with selected roles ",
  SELECT_COMPONENT_INCLUSION_TITLES_13: "All lists ",
  SELECT_COMPONENT_INCLUSION_TITLES_14: "All politically exposed persons ",
  SELECT_COMPONENT_EXCLUSION_TITLES_1: "All suspended list ",
  SELECT_COMPONENT_EXCLUSION_TITLES_2: "List from selected country/jurisdiction ",
  SELECT_COMPONENT_EXCLUSION_TITLES_3: "Lists from selected providers ",
  SELECT_COMPONENT_EXCLUSION_TITLES_4: "All list IDs selected ",
  SELECT_COMPONENT_EXCLUSION_TITLES_1001_5: "All entities/individuals inactive ",
  SELECT_COMPONENT_EXCLUSION_TITLES_1001_6: "All entities/individuals deceased ",
  SELECT_COMPONENT_EXCLUSION_TITLES_7: "All entities/individuals of selected categories ",
  SELECT_COMPONENT_EXCLUSION_TITLES_8: "All entity IDs/individual IDs ",
  SELECT_COMPONENT_EXCLUSION_TITLES_9: "All LQ( Low Quality) AKA( Also Known As) ",
  SELECT_COMPONENT_EXCLUSION_TITLES_10: "All politically exposed persons marked as Requiring Additional domestic screening ",
  SELECT_COMPONENT_EXCLUSION_TITLES_11: "All politically exposed persons from selected countries ",
  SELECT_COMPONENT_EXCLUSION_TITLES_12: "Politically exposed persons with selected roles ",
  SELECT_COMPONENT_EXCLUSION_TITLES_1003_5: "All politically exposed persons inactive ",
  SELECT_COMPONENT_EXCLUSION_TITLES_1003_6: "All politically exposed persons deceased ",
  RuleInclusion: "Rule Inclusion",
  RuleExclusion: "Rule Exclusion",
    //error codes

    E0001: "CLIENT ID is required",
    E0002: "CLIENT ID should be a number",
    E0003: "CLIENT ID is invalid",
    E0004: "CLIENT ID is inactive",
    E0011: "USER ID is required",
    E0012: "USER ID should be a string",
    E0013: "USER ID is invalid",
    E0014: "USER ID is inactive",
    E0021: "SCREEN TYPE ID is required",
    E0022: "SCREEN TYPE ID should be a number",
    E0023: "SCREEN TYPE ID is invalid",
    E0024: "SCREEN TYPE ID is inactive",
    E0031: "WATCHLIST ID is required",
    E0032: "WATCHLIST ID should be a number",
    E0033: "WATCHLIST ID is invalid",
    E0034: "WATCHLIST ID is inactive",
    E0041: "STATE ID is required",
    E0042: "STATE ID should be a number",
    E0043: "STATE ID is invalid",
    E0051: "STATUS is required",
    E0052: "STATUS should be a number",
    E0053: "STATUS is invalid",
    E0061: "ACTION ID is required",
    E0062: "ACTION ID should be a number",
    E0063: "ACTION ID is invalid",
    E0071: "API ACTION is required",
    E0072: "API ACTION should be a number",
    E0073: "API ACTION is invalid",
    E0081: "BLOCKLIST ID is required",
    E0082: "BLOCKLIST ID should be a number",
    E0083: "BLOCKLIST ID is invalid",
    E0084: "BLOCKLIST ID is in pending or inactive state",
    E0085: "Internal error, contact developers",
    E0091: "BLOCKLIST NAME is required",
    E0092: "BLOCKLIST NAME should be a string and not empty",
    E0101: "BLOCKLIST DESC is required",
    E0102: "BLOCKLIST DESC should be a string and not empty",
    E0111: "LIMIT should be a positive integer",
    E0112: "Limit should not be greater than 100",
    E0121: "Rule Ref ID is required",
    E0122: "Rule Ref ID should be a number",
    E0123: "Rule Ref ID is invalid",
    E0131: "ENUM TYPE ID is required",
    E0132: "ENUM TYPE ID  should be a number",
    E0133: "ENUM TYPE ID is invalid",
    E0141: "RECORD TYPE is required",
    E0142: "RECORD TYPE should be a number",
    E0143: "RECORD TYPE is invalid",
    E0151: "BLOCKLIST ENTRY ID is required",
    E0152: "BLOCKLIST ENTRY ID should be a string",
    E0153: "BLOCKLIST ENTRY ID is invalid",
    E0154: "BLOCKLIST ENTRY is already exist and the status is either in pending, approved, rejected, or preapproved",
    E0155: "BLOCKLIST ENTRY is already exist and the status is in draft",
    E0156: "BLOCKLIST ENTRY is already exist and the status is in pending",
    E0161: "BLOCKLIST ENTRY VERSION NO is required",
    E0162: "BLOCKLIST ENTRY VERSION NO should be a number",
    E0163: "BLOCKLIST ENTRY VERSION NO is invalid",
    E0171: "DELETE ENTRIES should is required",
    E0172: "Atleast one DELETE ENTRIES should be present",
    E0173:  "Given delete entries already is in pending state",
    E0181: "Rule Version Number is required",
    E0182: "Rule Version Number should be a number",
    E0183: "Rule Version Number is invalid",
    E0184: "Rule ID is required",
    E0191: "Incorrect Client ID, Watchlist ID Combination",
    E0192: "Incorrect Client ID, ScreenType ID Combination",
    E0193: "Incorrect Watchlist ID, ScreenType ID Combination",
    E0194: "Proposed Start Date Time should be in IS0-8601 format",
    E0201: "Proposed End Date Time is required",
    E0202: "Proposed End Date Time should be a string",
    E0203: "Proposed End Date Time should be a valid Date",
    E0204: "Proposed Start Date Time should be in IS0-8601 format",
    E0205: "Proposed End Date Time should not be in the past",
    E0206: "Proposed End Date Time should be grater than Proposed Start Date Time",
    E0211: "NAME is required",
    E0212: "NAME should be a string",
    E0221: "Internal error, contact developers",
    E0231: "Given national id is already exist and still in pending state",
    E0232: "Given national id is already exist and already in approved state",
    E0233: "NATIONAL ID is required",
    E0234: "NATIONAL ID should be a string",
    E0241: "Entity ID is invalid",
    E0242: "Entity ID should be a number",
    E0251: "SCREENTYPE ID is required",
    E0252: "SCREENTYPE ID should be a number",
    E0253: "SCREENTYPE ID is invalid",
    E0254: "SCREENTYPE ID is inactive",
    E0261: "Effective start date should be less than current date",
    E0271: "COMMENT is required",
    E0272: "COMMENT should be a string",
    E0281: "BLOCKLIST DATE is required",
    E0282: "BLOCKLIST DATE should be in IS0-8601 format",
    E0283: "BLOCKLIST DATE should be after start date",
    E0284: "BLOCKLIST DATE should be a string",
    E0291: "Status ID is required",
    E0292: "Status ID should be a number",
    E0293: "Status ID is invalid",
    E0301: "Rule is already expired",
    E0302: "Entry is already expired",
    E0311: "Trying to handle a rule that is created by you",
    E0312: "Trying to handle an entry that is created by you",
    E0321: "Rule Group ID is required",
    E0322: "Rule Group ID should be a number",
    E0323: "Rule Group ID is invalid",
    E0331: "Rule Type ID is required",
    E0332: "Rule Type ID should be a number",
    E0333: "Rule Type ID is invalid",
    E0341: "Valid at should be a valid Date",
    E0351: "Valid at or after should be a valid Date",
    E0361: "IN USE AT DATE should be a valid Date",
    E0362: "IN USE AT DATE should be a string",
    E0371: "List Reference Id should be a number",
    E0372: "List Reference Id is invalid",
    E0381: "Jurisdiction is invalid",
    E0382: "List provider code is invalid",
    E0391: "Rule ID should be a number",
    E0392: "Rule ID is required",
    E0393: "Rule ID is invalid",

    E0401: "Status should be a number",
    E0402: "Status should be 0(Suspended)  or 1(Active)",
    E0411: "CATEGORY ID is required",
    E0412: "CATEGORY ID should be a number",
    E0413: "CATEGORY ID is invalid",
    E0421: "All Lists are already Included",
    E0431: "All PEPs are already Included",
    E0441: "Effective end date should be greater than current date",
    E0451: "VALID AT OR AFTER is required",
    E0452: "VALID AT OR AFTER should be a string",
    E0453: "VALID AT OR AFTER should be in IS0-8601 format",
    E0461: "Proposed End Date Time is required",
    E0462: "Proposed End Date Time should be a string",
    E0463: "Proposed End Date Time should be a valid Date",
    E0464: "Proposed End Date Time should not be in the past",
    E0465: "Proposed End Date Time should be grater than Proposed Start Date Time",
    E0471: "Proposed Start Date Time is required",
    E0472: "Proposed Start Date Time should be a string",
    E0473: "Proposed Start Date Time should be a valid Date",
    E0474: "Proposed Start Date Time should be in IS0-8601 format",
    E0481: "Rule value 1 is required",
    E0482: "Rule Value 1 should be a string",
    E0483: "Rule value 1 should be a number",
    E0491: "Rule value 2 is required",
    E0492: "Rule value 2 should be a number",
    E0493: "Rule value 2 should not be null when Rule value 3 exists",
    E0501: "Rule value 3 is required",
    E0502: "Rule value 3 should be a number",
    E0511: "Rule value 4 is required",
    E0512: "Rule value 4 should be a number",
    E0521: "Rule value 5 is required",
    E0522: "Rule value 5 should be a number",
    E0531: "This is a duplicate rule. Rule is overlapping with an active rule.",
    E0541: "This is a duplicate rule. Rule is overlapping with a pending rule.",
    E0551: 'A delete request is already created for this rule.',
    E0561: "Trying to delete an inactive rule.",
    E0571: "Can not Approve or Reject entries that have not been submitted for Approval",
    E0581: "OFFSET should be a positive integer",
    E0591: "EMAIL ID's should be a valid",
    E0601: "Record Not Found",
    E0602: "This Entry is already in use",
    E0603: "This Entry is not in use",
    E0611: "ENUM VALUE ID is required",
    E0612: "ENUM VALUE ID should be a number",
    E0613: "ENUM TYPE ID or ENUM VALUE ID is invalid",
    E0621: "Error uploading data",
    E0622: "Error occured while uploading file",
    E0623: "Error occured while getting file",
    E0624: "Error occured while deleting file",
    E0625: "Error occured while copying file within bucket",
    E0631: "Error while creating queue",
    E0641: "VALID AT is required",
    E0642: "VALID AT should be a string",
    E0643: "VALID AT should be in IS0-8601 format",
    E0651: "Country Code is invalid",
    E0661: "Country Code is invalid",
    E0671: "Country Code is invalid",
    E0681: "Country Code is invalid",
    E0691: "Invalid File, File should be valid json format",
    E0692: "Error while fetching from provided URL",
    E0701: "SORT ORDER is required",
    E0702: "SORT ORDER should be a number",
    E0703: "SORT ORDER is invalid",
    E0711: "Proposed Start Date Time is required",
    E0712: "Proposed Start Date Time should be a string",
    E0713: "Proposed Start Date Time should be a valid Date",
    E0714: "Proposed Start Date Time should be in IS0-8601 format",
    E0715: "Proposed Start Date Time should not be in the past",
    E0716: "Proposed Start Date Time should be grater than Proposed Start Date Time",
    E0721: "Year is required",
    E0722: "Year should be a number",
    E0723: "Year is invalid",
    E0731: "Month is required",
    E0732: "Month should be a number",
    E0733: "Month is invalid",
    E0741: "Day is required",
    E0742: "Day should be a number",
    E0743: "Day is invalid",
    E0751: "Date is invalid",
    E0761: "GENERATED DATE is required",
    E0762: "GENERATED DATE should be in IS0-8601 format",
    E0771: "REPORT NOT FOUND",
    E0781: "OFAC DATE is required",
    E0782: "OFAC DATE should be in IS0-8601 format",
    E0791: "DJ DATE is required",
    E0792: "DJ DATE should be in IS0-8601 format",
    E0801: "STANDARD DATA is required",
    E0811: "ATTACHMENT is required",
    E0812 : "FILE NAME is required",
    E0813 : "FILE URL is required",
    E0814: "BASE64 Url is required",
    E0815: "DESCRIPTION String is required",


    F0001: "Internal Service Error Occured, contact developers",
    F0002: "Internal Service Error Occured, contact developers",
    F0003: "Internal Service Error Occured, contact developers",
    F0004: "Internal Service Error Occured, contact developers",
    F0005: "Internal Service Error Occured, contact developers",
    F0006: "Internal Service Error Occured, contact developers",
    F0007: "Secrets Manager",
    F0008: "EC2",
    F0009: "S3",
    F00010: "SNS",
    F00011: "AWS LAMBDA",    
};