import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Auth } from "aws-amplify";

export const checkSessionForUser = createAsyncThunk(
  "login/checkSessionForUser",
  async (body, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));

    // const currentSession = await Auth.currentSession();
    // cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
    //   console.log("session", err, session);
    //   const { idToken, refreshToken, accessToken } = session;
    //   // do whatever you want to do now :)
    // });

    const cognitoUser = await Auth.currentAuthenticatedUser();
    thunkAPI.dispatch(setAuthenticated(true));
    // console.log(JSON.stringify(cognitoUser));
    // For guest user
    try {
      // console.log(await Auth.currentCredentials());
      const currSession = await Auth.currentSession();
      thunkAPI.dispatch(setRole(currSession?.idToken?.payload?.profile));
    } catch (e) {
      console.log(e);
    }
    return cognitoUser;
  }
);

export const signOut = createAsyncThunk(
  "login/signOut",
  async (body, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    const response = await Auth.signOut();
    thunkAPI.dispatch(setLoading(false));
    return response;
  }
);

export const loginSlice = createSlice({
  name: "login",
  initialState: {
    sessionLoginAttempt: false,
    loading: false,
    role: null,
    error: null,
    isAuthenticated: true,
    user: null,
    showLoginModal: false,
    forceLogin: false,
  },
  reducers: {
    setAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    // These are just examples, will remove as developers are aware of how redux works
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setRole: (state, action) => {
      state.role = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setForceLogin: (state, action) => {
      state.forceLogin = action.payload;
    },
    setShowLoginModal: (state, action) => {
      state.showLoginModal = action.payload;
    },
  },
  extraReducers: {
    [checkSessionForUser.fulfilled]: (state, action) => {
      state.isAuthenticated = true;
      state.sessionLoginAttempt = true;
      state.user = action.payload;
      state.loading = false;
    },
    [checkSessionForUser.rejected]: (state, action) => {
      state.isAuthenticated = false;
      state.loading = false;
      state.sessionLoginAttempt = true;
      state.error = action.payload;
      state.role = null;
    },
    [signOut.fulfilled]: (state, action) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.user = null;
    },
    [signOut.rejected]: (state, action) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.user = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setAuthenticated,
  setRole,
  setUser,
  setForceLogin,
  setShowLoginModal,
} = loginSlice.actions;

export default loginSlice.reducer;
