import { React} from "react";
import { useSelector } from "react-redux";
import { Button, TextField } from "@mui/material";
import { toast } from "react-toastify";
import { IBL_STATUS_ID, IBL_ACTION_ID } from "../../constants/iblConstants";
import { useTranslation } from "react-i18next";

import {
  blockListManagementAPI
  } from "../../api";

// styles
import "./updateBlockList.scss";

const UpdateBlockList = (props) => {
  const { handleClose } = props;
  const userId = useSelector((state) => state.login?.user?.attributes?.email);
  const { t } = useTranslation();

  const submitHandler = async () => {
    const body = {
        api_action: IBL_ACTION_ID.UPDATE,
        client_id: 1,
        blocklist_name: props.blocklistName,
        blocklist_desc: null,
        blocklist_id: props.blocklistId,
        state_id: props.state === 2 ? IBL_STATUS_ID.INACTIVE : IBL_STATUS_ID.ACTIVE,
        user_id: userId,
      };
      const response = await blockListManagementAPI.createInternalBlockList(body);
  
      if (response.status === 200 && response.data.data.length) {
        toast.success("Blocklist Successfully Deactivated!");
      } else {
        if (response?.response?.data) {
          toast.error(t(response?.response?.data?.code));
        } else {
          toast.error(t(response.code));
        }
      }
      handleClose();
  };

  return (
    <div className="d-flex justify-content-center update-blocklist-container w-100 flex-column ">
          {props.state === 2 ? <p className="update-blocklist-title">Request for Deactivation of blocklist</p>:
          <p className="update-blocklist-title">Request for Activation of blocklist</p>}
          <div className="content">
            <p className="label">
              <span className="text">Blocklist Name <strong>{props.blocklistName}</strong></span>
            </p>
            <p className="label">
            <span className="text"> Blocklist ID <strong>{props.blocklistId}</strong></span>
            </p>
            <p className="label">
              Add Comments<span className="important-style">*</span>
            </p>
            <TextField
              fullWidth
              id="outlined-multiline-static"
              multiline
              rows={4}
              sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 },}} 
              placeholder="Enter Description"
            />
            <div className="action-buttons-container">
            <div className="d-flex justify-content-end">
                <Button
                sx={{ marginRight: "1.6%" }}
                size="large"
                color="error"
                onClick={handleClose}
                >
                CANCEL
                </Button>
                <Button
                variant="contained"
                size="large"
                color="error"

                onClick={submitHandler}
                >
                SUBMIT
                </Button>
            </div>
            </div>
      </div>
    </div>
  );
};

export default UpdateBlockList;
