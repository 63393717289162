import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const additionalInformationDetailsSlice = createSlice({
  name: "additionalInformation",
  initialState: { ...initialState },
  reducers: {
    setAdditionalInformation: (state, action) => {
      state[action.payload.key] = action.payload.data;
    },
    setData: (state, action) => {
      state = action.payload
    },
    resetAddtionalInformation: () => initialState
  },
});

export const { setAdditionalInformation, setData, resetAddtionalInformation } =
  additionalInformationDetailsSlice.actions;

export default additionalInformationDetailsSlice.reducer;
