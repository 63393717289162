export const RULE_GROUP = {
  1000: "List",
  1001: "Entity",
  1002: "LowQualityAliasNames",
  1003: "PEP",
};

export const RULE_GROUP_TYPE_FULL_FORM = {
  1002: "LQAKA" ,
  1003: "PoliticallyExposedPerson",
  10: "RADSTooltipContent"
} 

export const RULE_TYPE_EXCLUSION = {
  1: "ExcludebySuspended",
  2: "ExcludebyCountryCode",
  3: "ExcludebyProviderCode",
  4: "ExcludebyReferenceCode",
  5: "ExcludebyInactive",
  6: "ExcludebyDeceased",
  7: "ExcludebyCategory",
  8: "ExcludebyId",
  9: "ExcludebyName",
  10: "ExcludebyRADS",
  11: "ExcludebyCitizenship",
  12: "ExcludebyRoles"
};

export const RULE_TYPE_INCLUSION = {
  1: "IncludebySuspended",
  2: "IncludebyCountryCode",
  3: "IncludebyProviderCode",
  4: "IncludebyReferenceCode",
  5: "IncludebyInactive",
  6: "IncludebyDeceased",
  7: "IncludebyCategory",
  8: "IncludebyId",
  9: "IncludebyName",
  10: "IncludebyRADS",
  11: "IncludebyCitizenship",
  12: "IncludebyRoles",
  13: "All",
  14: "All"
};

export const RULE_TYPE_SHORT_TEXT = {
  1: "Suspended",
  2: "Jurisdiction",
  3: "Provider",
  4: "ListID",
  5: "Inactive",
  6: "Deceased",
  7: "Category",
  8: "EntityID",
  9: "Names",
  10: "RADS",
  11: "Citizenship",
  12: "Roles",
  13: "All",
  14: "All"
};

export const WATCHLIST = {
  1: "Dow Jones",
};

export const SELECTED_RULE_LIST = {
  1: "SuspendedList",
  2: "Countries",
  3: "Providers",
  4: "ReferenceCodes",
  5: "Inactives",
  6: "Deceased",
  7: "Categorys",
  8: "ID",
  9: "LQNAMES",
  10: "Requires Additional Domestic Screening",
  11: "Citizenships",
  12: "Roles",
};

export const ADD_RULE_TITLE = {
  // AddRuleTitle1: "AddExclusionRules",
  AddRuleTitle2: "SelectRuleGroup",
  AddRuleTitle3: "RuleTypeSelection",
  AddRuleTitle4: "RuleAll",
  AddRuleTitle5: "willBe",
  // AddRuleTitle6: "Excluded",
  // AddRuleTitle7: "FromYour",
  // AddRuleTitle8: "ScreeningProcess",
  AddRuleTitle10: "Description",
  AddRuleTitle11: "Exclude",
  AddRuleTitle12: "DraftRules",
  AddRuleTitle13: "ProvideCommentsAndProceedForApproval",
  AddRuleTitle14: "BACK",
  // AddRuleTitle15: "SubmitApproval",
  // AddRuleTitle16: "AddInclusionRules"
};

export const ACTION = {
  1: "ADD",
  3: "DELETE",
};

export const ID_NAME_MAPPER = {
  2: { id: "country_name" },
  3: { id: "list_provider_id", name: "list_provider_name" },
  4: {
    id: "list_ref_id",
    jurisdiction: "jurisdiction",
    name: "list_reference_name",
  },
  8: { id: "entity_id", name: "entity_name" },
  11: { id: "country_name" },
  12: { id: "occupation_name" },
};

export const TITLE_FOR_SEARCH_COMPONENT = {
  2: ["SELECT COUNTRY/JURISDICTION"],
  3: ["Provider ID", "Provider Name"],
  4: ["List ID", "List Name"],
  8: ["Entity/Individual ID", "Entity/Individual Name"],
  11: ["SELECT COUNTRIES"],
  12: ["SELECT ROLES"]
};

export const INPUTFIELD_FOR_SEARCH_COMPONENT = {
  2: ["SELECT COUNTRY/JURISDICTION"],
  3: ["Provider ID", "Provider Name"],
  4: ["List ID", "Jurisdiction", "List Name"],
  8: ["Entity/Individual ID", "Entity/Individual Name"],
  11: ["SELECT COUNTRIES"],
  12: ["SELECT ROLES"]
};

export const API_KEYS_FOR_SEARCH_COMPONENT = {
  3: ["id", "name"],
  4: ["id", "jurisdiction", "name"],
  8: ["id", "name"],
};

export const API_DATA = {
  3: { id: "", name: "" },
  4: { id: "", name: "", jurisdiction: "" },
  8: { id: "", name: "" },
}


export const ADD_RULE_STEPS_EXCLUSION = {
  1: {
    title: "HowToCreateAndSubmitARule",
    content: [
      {
        title: "Step1",
        content_section_1: "SelectThe",
        content: "RuleGroup",
        content_section_2: "YouWantToExclude",
      },
      {
        title: "Step2",
        content_section_1: "SelectExclusionCriteriaUnder",
        content: "RuleType",
        content_section_2: "AddFurtherSpecificationsIfRequired",
      },
      {
        title: "Step3",
        content_section_1: "Click",
        content: " exclude",
        content_section_2: "AndAddToTheQueue",
      },
      {
        title: "Step4",
        content:
          "OnceInQueue",
      },
      {
        title: "Step5",
        content_section_1: "SubmitFor",
        content: "Approval",
      },
    ],
  },
  2: {
    title: "StartBySelectingRuleGroup",
    content: [
      {
        title: "List",
        content:
          "ListContent",
      },
      {
        title: "PEP",
        content: "PEPContent",
      },
      {
        title: "Entity",
        content:
          "EntityContent",
      },
      {
        title: "LowQualitysNames",
        content: "LQNamesContent",
      },
    ],
  },
  1000: {
    title: "Specify the Rule type:",
    content: [
      {
        title: "Suspended",
        content: "Suspendedcontent",
      },
      {
        title: "Jurisdiction",
        content: "JurisdictionContent",
      },
      {
        title: "Provider",
        content: "ProviderContent",
      },
      {
        title: "Lists",
        content: "ListIDsContent",
      },
    ],
  },
  1001: {
    title: "Specify the Rule type:",
    content: [
      {
        title: "Inactive",
        content: "EntityInactiveContent",
      },
      {
        title: "Deceased",
        content: "DeceasedContent",
      },
      {
        title: "Category",
        content:
          "CategoryContent",
      },
      {
        title: "EntityID",
        content: "EntityRuleContent",
      },
    ],
  },
  1002: {
    title: "LQAKANAMES",
    content: [],
  },
  1003: {
    title: "Specify the Rule type:",
    content: [
      {
        title: "Inactive",
        content: "PEPInactiveContent",
      },
      {
        title: "Deceased",
        content: "PEPDeceasedContent",
      },
      {
        title: "RADS",
        content: "RADSContent",
      },
      {
        title: "Citizenship",
        content: "CitizenshipContent",
      },
      {
        title: "Roles",
        content: "RolesContent",
      },
    ],
  },
};

export const ADD_RULE_STEPS_INCLUSION = {
  1: {
    title: "HowToCreateAndSubmitARule",
    content: [
      {
        title: "Step1",
        content_section_1: "SelectThe",
        content: "RuleGroup",
        content_section_2: "YouWantToInclude",
      },
      {
        title: "Step2",
        content_section_1: "SelectInclusionCriteriaUnder",
        content: "RuleType",
        content_section_2: "AddFurtherSpecificationsIfRequired",
      },
      {
        title: "Step3",
        content_section_1: "Click",
        content: " include",
        content_section_2: "AndAddToTheQueue",
      },
      {
        title: "Step4",
        content:
          "OnceInQueueInclusion",
      },
      {
        title: "Step5",
        content_section_1: "SubmitFor",
        content: "Approval",
      },
    ],
  },
  2: {
    title: "StartBySelectingRuleGroup",
    content: [
      {
        title: "List",
        content:
          "ListContentInclusion",
      },
      {
        title: "PEP",
        content: "PEPContentInclusion",
      },
    ],
  },
  1000: {
    title: "Specify the Rule type:",
    content: [
      {
        title: "All",
        content: "AllContentInclusion",
      },
      {
        title: "Jurisdiction",
        content: "JurisdictionContentInclusion",
      },
      {
        title: "Provider",
        content: "ProviderContentInclusion",
      },
      {
        title: "Lists",
        content: "ListIDsContentInclusion",
      },
    ],
  },
  1003: {
    title: "Specify the rule type:",
    content: [
      {
        title: "All",
        content: "AllPEPContentInclusion",
      },
      {
        title: "Citizenship",
        content: "CitizenshipContentInclusion",
      },
      {
        title: "Roles",
        content: "RolesContentInclusion",
      },
      {
        title: "RADS",
        content: "RADSContentInclusion",
      },
    ],
  },
};


export const SELECT_COMPONENT_EXCLUSION_TITLES = {
  1: "SELECT_COMPONENT_EXCLUSION_TITLES_1",
  2: "SELECT_COMPONENT_EXCLUSION_TITLES_2",
  3: "SELECT_COMPONENT_EXCLUSION_TITLES_3",
  4: "SELECT_COMPONENT_EXCLUSION_TITLES_4",
  "1001_5": "SELECT_COMPONENT_EXCLUSION_TITLES_1001_5",
  "1001_6": "SELECT_COMPONENT_EXCLUSION_TITLES_1001_6",
  7: "SELECT_COMPONENT_EXCLUSION_TITLES_7",
  8: "SELECT_COMPONENT_EXCLUSION_TITLES_8",
  9: "SELECT_COMPONENT_EXCLUSION_TITLES_9",
  10: "SELECT_COMPONENT_EXCLUSION_TITLES_10",
  11: "SELECT_COMPONENT_EXCLUSION_TITLES_11",
  12: "SELECT_COMPONENT_EXCLUSION_TITLES_12",
  "1003_5": "SELECT_COMPONENT_EXCLUSION_TITLES_1003_5",
  "1003_6": "SELECT_COMPONENT_EXCLUSION_TITLES_1003_6",
};

export const SELECT_COMPONENT_INCLUSION_TITLES = {
  2: "SELECT_COMPONENT_INCLUSION_TITLES_2",
  3: "SELECT_COMPONENT_INCLUSION_TITLES_3",
  4: "SELECT_COMPONENT_INCLUSION_TITLES_4",
  10: "SELECT_COMPONENT_INCLUSION_TITLES_10",
  11: "SELECT_COMPONENT_INCLUSION_TITLES_11",
  12: "SELECT_COMPONENT_INCLUSION_TITLES_12",
  13: "SELECT_COMPONENT_INCLUSION_TITLES_13",
  14: "SELECT_COMPONENT_INCLUSION_TITLES_14"
};

export const MIN_WIDTH_FOR_DESCRIPTION_EXCLUSION = {
  1 : "10%",
  2: "20%",
  1000: "15%",
  1001:  "15%",
  1002: "15%",
  1003: "15%",
}

export const MIN_WIDTH_FOR_DESCRIPTION_INCLUSION = {
  1 : "10%",
  2: "10%",
  1000: "15%",
  1003: "15%",
}