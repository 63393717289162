import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchNameAndNationalType } from "../api/blockListManagementAPI";

import { fetchCountries } from "../api/listManagementAPI";


const initialState = {
  standard_data: {
    names: [
      {
        enum_type_id: "",
        enum_value_id: "",
        enum_value_name: "",
        title_honorific: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        maiden_name: "",
        suffix: "",
        original_script_name: "",
      },
    ],
    dobs: [{ year: null, month: null, day: null}],
    addresses: [
      {
        enum_type_id: "",
        enum_value_id: "",
        enum_value_name: "",
        address_city: "",
        address_country: "",
        address_line: "",
        address_zipcode: "",
      },
    ],
    national_ids: [{ enum_value_id: "", id_value: "" }],
    additionalInformation: {
      proposed_start_dtm: null,
      proposed_end_dtm: null,
      comment: "",
    },
    error: { status: false, message: "" },
    loading: false
  },
  nameDetailsList: [],
  addressDetailsList: [],
  nationalIDTypesList: [],
  countries: [],
  dateTypeList: [],

};


export const getNameAndNationalType = createAsyncThunk(
  "entityDetails/getNameAndNationalType",
  async (body,thunkAPI) => {
    const response = await fetchNameAndNationalType();
    return response;
  }
);

export const getCountries = createAsyncThunk(
  "entityDetails/getCountries",
  async (body,thunkAPI) => {
    const response = await fetchCountries({ isOnlyCountries: true });
    return response;
  }
);

export const enityDetailsSlice = createSlice({
  name: "entityDetails",
  initialState: { ...initialState },
  reducers: {
    resetEntityDetails: (state, action) => {
      state["standard_data"] = initialState["standard_data"];
    },
    setEntityDetails: (state, action) => {
      state["standard_data"][action.payload.key] = action.payload.data;
    },
    setLoading: (state, action) => {
      state.standard_data.loading = action.payload;
    },
    addEntityField: (state, action) => {
       state["standard_data"][action.payload.key] = [
        ... state["standard_data"][action.payload.key],
        ...initialState["standard_data"][action.payload.key],
      ];
    },
    resetEntityField: (state, action) => {
      if (action.payload.key === "additionalInformation") {
        state["standard_data"][action.payload.key] = { ...initialState["standard_data"][action.payload.key] };
      } else {
        state["standard_data"][action.payload.key] = [initialState["standard_data"][action.payload.key]];
      }
    },
    updateEntityDetails: (state, action) => {
      state["standard_data"] = action.payload
    },
    setUpdateList: (state, action) => {state.updateList = action.payload}
  },
  extraReducers: {
    [getNameAndNationalType.fulfilled]: (state, action) => {
      if (action.payload.data.length > 0) {
        state.nameDetailsList = action.payload.data.filter(function (obj) {
          return obj.enum_type_id === 1;
        })[0];
        state.nationalIDTypesList = action.payload.data.filter(function (obj) {
          return obj.enum_type_id === 2;
        })[0];

        state.dateTypeList = action.payload.data.filter(function (obj) {
          return obj.enum_type_id === 3;
        })[0].enum_values;

        state.addressDetailsList = action.payload.data.filter(function (obj) {
          return obj.enum_type_id === 4;
        })[0];
      }
    },
    [getNameAndNationalType.rejected]: (state, action) => {
      state.error.status = true;
      state.error.message = action.payload;
    },
    [getCountries.fulfilled]: (state, action) => {
      if (action.payload.data.length > 0) {
        state.countries = action.payload.data.map((country) => {
          return { id: country.country_code, value: country.country_name };
        });
      }
    },
    [getCountries.rejected]: (state, action) => {
      state.error.status = true;
      state.error.message = action.payload;
    },
  },
});

export const {
  setEntityDetails,
  resetEntityDetails,
  setLoading,
  addEntityField,
  updateEntityDetails,
  resetEntityField,
  setUpdateList
} = enityDetailsSlice.actions;

export default enityDetailsSlice.reducer;
