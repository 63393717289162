import { useEffect, useState, useRef } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import _ from "lodash";
//components, constants
import DetailsSection from "../../features/detailsSection/detialsSection";
import { setAdditionalInformation } from "../../redux/aditionalInformationRedux";
import { camelToUnderscore } from "../../constants/additionalInformationTabUtility";
import { isEmptyCheck } from "../../constants/utility";
import NoDataFound from "../noDataFound/noDataFound";

export default function CustomRadioGroup(props) {
  const { attributeDetails } = props;
  const selectedRole = sessionStorage.getItem("selectedRole");

  const [radioState, setRadioState] = useState(
    selectedRole === "maker"
      ? {
          enum_type_id: null,
          enum_value_id: null,
          enum_value_name: null,
        }
      : null
  );

  let timer = useRef(null);

  const dispatch = useDispatch();
  const entityData = useSelector((state) => state.entityData);

  const additionalInformationData = useSelector(
    (state) =>
      state.aditionalInformationDetails[
        camelToUnderscore(attributeDetails.enum_type_name)
      ]
  );
  useEffect(() => {
    if (
      entityData.entityData?.action === "edit" &&
      additionalInformationData &&
      Object.keys(additionalInformationData).length > 0
    ) {
      setRadioState({
        ...additionalInformationData,
      });
    }
  }, []);

  const onChangeHandler = (value) => {
    const updatedRadioGroupDetails = { ...radioState };
    updatedRadioGroupDetails["enum_type_id"] = attributeDetails?.enum_type_id;
    updatedRadioGroupDetails["enum_value_id"] = value;
    updatedRadioGroupDetails["enum_value_name"] =
      attributeDetails.enum_values.filter((ele) => {
        if (ele.enum_value_id.toString() === value) {
          return ele;
        }
      })[0].enum_value_name;
    setRadioState({ ...updatedRadioGroupDetails });
  };

  useEffect(() => {
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      dispatch(
        setAdditionalInformation({
          key: camelToUnderscore(attributeDetails.enum_type_name),
          data: !_.isEmpty(radioState.enum_value_id) ? { ...radioState } : null,
        })
      );
    }, [500]);
  }, [radioState]);

  return (
    <DetailsSection
      title={attributeDetails?.default_label_name}
      showAddIcon={
        attributeDetails.accept_multi_value && selectedRole === "maker"
      }
    >
      {" "}
      {radioState && (
        <FormControl>
          <RadioGroup
            row
            onChange={(event) => onChangeHandler(event.target.value)}
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={radioState?.enum_value_id}
          >
            {attributeDetails &&
              attributeDetails?.enum_values?.map((element) => {
                return (
                  <FormControlLabel
                    value={element.enum_value_id}
                    control={
                      <Radio
                        sx={{
                          "&.Mui-checked": {
                            color: "#1976d2",
                          },
                        }}
                      />
                    }
                    label={element.enum_value_name}
                    style={{ color: "black" }}
                    disabled={selectedRole === "checker"}
                  />
                );
              })}
          </RadioGroup>
        </FormControl>
      )}
      {isEmptyCheck(radioState) && (
        <NoDataFound/>
      )}
    </DetailsSection>
  );
}
