import { validateComplexRadioDate, validateComplexDropDown, validateComplexDropDownDate } from "./additionalInformationTabUtility";
export const IBL_STATUS = {
  1: "PENDING",
  2: "ACTIVE",
  3: "INACTIVE",
};

export const IBL_STATUS_COLOR = {
  1: "#F57C00", //Pending
  2: "#2E7D32", //active
  3: "#E53935", // inactive
};

export const IBL_STATUS_ID = {
    PENDING: 1,
    ACTIVE: 2,
    INACTIVE: 3,
};

export const IBL_ACTION_ID = {
    ADD: 1,
    UPDATE : 2,
    DELETE: 3
};

export const IBL_ACTION_TEXT = {
    1: "NEW",
    2: "MODIFIED",
    3: "DELETION"
};

export const IBL_ACTION_COLOR = {
    1: "#2E7D32",
    2: "#1976D2",
    3: "#D32F2F"
}

export const IBL_ENTRY_STATUS = {
    PENDING: 0,
    APPROVED: 1,
    REJECTED: 2,
    DRAFT:3,
    PRREAPPROVED: 4
};

export const IBL_NAME_TITLE = [
  { id: "Mr", value: "Mr" },
  { id: "Master", value: "Master" },
  { id: "Miss", value: "Miss" },
  { id: "Mrs", value: "Mrs" },
];

export const IBL_API_ACTION_ID = {
    "save-as-draft": 1,
    "submit": 2,
    "submit-and-add-another": 2,
  };

  export const IBL_RECORD_TYPE = {
    1: "individual",
    2: "organisation"
  }

  export const IBL_RECORD_TYPE_ID = {
    "individual": 1,
    "organisation": 2
  }


  export const ADDITIONAL_INFORMATION_VALIDATE_FUNCTION_MAPPER = {
    "complex_radio_date": validateComplexRadioDate,
    "radio_group" : () => { return { error: false, error_message: "" } },
    "textbox" : () => { return { error: false, error_message: "" } },
    "complex_dropdown" : validateComplexDropDown,
    "complex_dropdown_date": validateComplexDropDownDate,
    "textarea": () => { return { error: false, error_message: "" } },
    "attachments": () => { return { error: false, error_message: "" } }
  };
 
export const CUSTOM_DROPDOWN_DATE_KEYS = {
  "fromYear": "sinceYear",
  "fromMonth": "sinceMonth",
  "fromDay": "sinceDay",
  "toYear": "toYear",
  "toMonth": "toMonth",
  "toDay": "toDay",
  "enum_value_name" : "enum_value_name",
  "enum_value_id" : "enum_value_id",
  "enum_type_id" : "enum_type_id"
}

export const ACTIVE_TAB = {
  "save-as-draft": "draft",
  "submit": "pending",
};