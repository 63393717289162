/* eslint-disable react-hooks/exhaustive-deps */
import { Amplify } from "aws-amplify";
import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import Header from "./features/header/header";
import { checkSessionForUser } from "./redux/loginRedux";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./features/home/home";
import "./App.scss";
import Dashboard from "./features/dashboard/dashboard";
import AddRule from "./features/addRule/addNewRule";
import AddEntity from "./features/addEntity/addEntity";
import IBLDashboard from "./features/iblDashboard/iblDashboard";
import ViewApproval from "./features/IBLDetails/viewApproval";
import "placeholder-loading/dist/css/placeholder-loading.min.css";

import { ProtectedRoute } from "./components/protectedRoute/protectedRoute";
import SpinnerBlocker from "./components/loader/spinnerBlocker/spinnerBlocker";
import {
  getCountries,
  getDescriptions,
  getRoles,
  getScreenList,
} from "./redux/metadataRedux";
import Footer from "./features/footer/footer";
import IBLDetails from "./features/IBLDetails/IBLDetails";
import ViewModifiedApproval from "./features/IBLDetails/ViewModifiedApproval";
import AppLanding from "./features/appLanding/appLanding";
import ShowResult from "./features/showResult/showResult";
import ComparisonReport from "./features/comparisonReport/comparisonReport";
function App() {
  const dispatch = useDispatch();
  const sessionLoginAttempt = useSelector(
    (state) => state.login.sessionLoginAttempt
  );
  const isAuthenticated = useSelector((state) => state.login.isAuthenticated);

  React.useEffect(() => {
    Amplify.configure({
      Auth: {
        region: process.env.REACT_APP_REGION,
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
      },
      oauth: {
        domain: process.env.REACT_APP_DOMAIN_URL,
        scope: ["email", "openid", "aws.cognito.signin.user.admin", "profile"],
        responseType: "token",
        clientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
        redirectSignIn: process.env.REACT_APP_LOGIN_REDIRECT,
        redirectSignOut: process.env.REACT_APP_LOGIN_REDIRECT,
      },
    });
    dispatch(checkSessionForUser());
  }, []);

  React.useEffect(() => {
    if (isAuthenticated) {
      dispatch(getCountries());
      dispatch(getDescriptions());
      dispatch(getRoles());
      dispatch(getScreenList());
      document.body.classList.add("bg-new");
    }
    return () => {
      document.body.classList.remove("bg-new");
    };
  }, [isAuthenticated]);

  return (
    <>
      {!sessionLoginAttempt ? (
        <SpinnerBlocker />
      ) : (
        <>
          <Header />
          <div
            id="applicationContainer"
            className="w-100 h-100 container my-5"
          >
            <React.Suspense fallback={<SpinnerBlocker />}>
              <Routes>
                <Route
                  path="/viewApproval"
                  element={
                    <ProtectedRoute redirectTo="/">
                      <ViewApproval />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/ibldashboard"
                  element={
                    <ProtectedRoute redirectTo="/">
                      <IBLDashboard />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/iblDetails"
                  element={
                    <ProtectedRoute redirectTo="/">
                      <IBLDetails />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/addrule"
                  element={
                    <ProtectedRoute redirectTo="/">
                      <AddRule />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/dashboard"
                  element={
                    <ProtectedRoute redirectTo="/">
                      <Dashboard />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/"
                  element={
                    isAuthenticated ? (
                      <Navigate replace to={"/applanding"} />
                    ) : (
                      <Home />
                    )
                  }
                />
                <Route
                  path="/addentity"
                  element={
                    <ProtectedRoute redirectTo="/">
                      <AddEntity />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/viewModifiedApproval"
                  element={
                    <ProtectedRoute redirectTo="/">
                      <ViewModifiedApproval />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/applanding"
                  element={
                    <ProtectedRoute redirectTo="/">
                      <AppLanding />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/showResult"
                  element={
                    <ProtectedRoute redirectTo="/">
                      <ShowResult />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/comparison"
                  element={
                    <ProtectedRoute redirectTo="/">
                      <ComparisonReport />
                    </ProtectedRoute>
                  }
                />
              </Routes>
            </React.Suspense>
            <ToastContainer
              position="top-center"
              autoClose={4000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
          <Footer />
        </>
      )}
    </>
  );
}

export default App;
