import { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import format from "date-fns/format";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";

//components
import DetailsSection from "../../detialsSection";
import DatePicker from "../../../../components/datePicker/datepicker";
import { setEntityDetails } from "../../../../redux/enityDetailsRedux";
//style
import "./additionalInformation.scss";

const AdditionalInformation = (props) => {
  const { action, isDisabled } = props;
  const dispatch = useDispatch();

  const additionalInformation = useSelector(
    (state) => state.entityDetails?.standard_data?.additionalInformation
  );
  const additionalInformationOnEdit = useSelector(
    (state) => state.entityDetails
  );
  const selectedRole = sessionStorage.getItem("selectedRole");


  const onChangeHandler = (event, key) => {
    if (key !== "comment" && !(event instanceof Date) && isNaN(event)) {
      return;
    }

    let updatedData = { ...additionalInformation };
    updatedData[key] = key === "comment" ? event.target.value : event;
    dispatch(
      setEntityDetails({
        key: "additionalInformation",
        data: { ...updatedData },
      })
    );
  };

  return (
    <DetailsSection>
      <div className="additional-information-title">Additional Information</div>
      <div className="d-flex">

        <div
          className={`additional-information-container w-100`}
        >
          <div className="date-container">
            <div className="date-content">
              <DatePicker
                label="Active From"
                inputFormat="MM/dd/yyyy"
                value={additionalInformation?.proposed_start_dtm}
                onChange={(event) =>
                  onChangeHandler(event, "proposed_start_dtm")
                }
                renderInput={(params) => <TextField {...params} />}
                disablePast={selectedRole === "checker" ? false : true}
                readOnly={selectedRole === "checker"}
              />
            </div>
            <div className="date-content">
              <DatePicker
                label="Active To"
                inputFormat="MM/dd/yyyy"
                value={additionalInformation?.proposed_end_dtm}
                onChange={(event) => onChangeHandler(event, "proposed_end_dtm")}
                renderInput={(params) => <TextField {...params} />}
                disablePast={selectedRole === "checker" ? false : true}
                minDate={additionalInformation?.proposed_start_dtm || new Date()}
                readOnly={selectedRole === "checker"}
              />
            </div>
          </div>
          <div>
            <TextField
              sx={{ width: props?.action === "edit" ? "100%" : "49%" }}
              styles="content"
              fullWidth
              value={additionalInformation.comment}
              id="outlined-multiline-static"
              label="Comments"
              multiline
              rows={4}
              placeholder="Comments"
              onChange={(event) => onChangeHandler(event, "comment")}
              inputProps={{ readOnly: selectedRole === "checker" }}
            />
          </div>
        </div>
      </div>
    </DetailsSection>
  );
};

export default AdditionalInformation;
