import { Paper } from "@mui/material";
import { format } from "date-fns";

import OverflowTip from "../../../components/overflowTooltip/overflowTooltip";
import "./ibldetailsComponent.scss";

const IBLDetailsComponent = (props) => {
  const blocklistDetails = props.blocklistDetails;
  // const selectedRole = props.selectedRole;
  // const handleOpen = props.handleOpen;

  return (
    <Paper
      id="ibl-details-paper"
      className="row align-items-center w-90 ms-2 p-2"
      elevation={2}
    >
      <div className="col-6 col-md-6 col-sm-12 col-xs-12 d-flex flex-column ibl-name-id-main">
        <div className="row ibl-name-id-main mb-1">
          <span className="d-flex justify-content-end ibl-name-id col-3">
            Blocklist Name:
          </span>
          <span className="col-9">
            <OverflowTip value={blocklistDetails.blocklist_name} className="ibl-name-id-value" />
          </span>
        </div>
        <div className="row ibl-name-id-main">
          <span className="d-flex justify-content-end ibl-name-id col-3">
            Blocklist ID:
          </span>
          <span className="col-9">
            <OverflowTip value={blocklistDetails.blocklist_id} className="ibl-name-id-value"/>
          </span>
        </div>
      </div>
      <div className="col-6 col-md-6 col-sm-12 col-xs-12 d-flex flex-column">
        <div className="row ibl-creator-date-main mb-1">
          <span className="d-flex justify-content-end  col-3">Creator:</span>
          <span className="col-9">
            <OverflowTip value={blocklistDetails.created_by} className="ibl-creator-date-value" />
          </span>
        </div>
        <div className="row ibl-creator-date-main ">
          <span className="d-flex justify-content-end ibl-creator-date col-3">
            Creation Date:
          </span>
          <span className=" col-9">
            <OverflowTip
              value={format(
                new Date(blocklistDetails.creation_dtm),
                "dd LLL yyyy"
              )}
              className="ibl-creator-date-value" 
            />
          </span>
        </div>
      </div>

      {/* {(selectedRole === "maker" && blocklistDetails.state_id !== 1) &&(
              <div className="fcc-bl-btn align-items-end d-flex flex-column">
                {blocklistDetails.state_id === 2 && (
                  <Button
                    sx={{ textDecoration: "underline" }}
                    variant="text"
                    size="small"
                    id="btn-deactivation"
                    onClick={handleOpen}
                  >
                    Request Deactivation
                  </Button>
                )}
                {blocklistDetails.state_id === 3 && (
                  <Button
                    sx={{ textDecoration: "underline" }}
                    variant="text"
                    size="small"
                    id="btn-deactivation"
                    onClick={handleOpen}
                  >
                    Request Activation
                  </Button>
                )}
              </div>
            )} */}
    </Paper>
  );
};

export default IBLDetailsComponent;
