import { useMemo } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const RightSidePopup = (props) => {
  const style = useMemo(() => {
    return {
      position: "absolute",
      right: 0,
      top: 54,
      bottom: 54,
      outline: "none",
      height: `calc(100vh - 54px)`,
      width: props.width,
      bgcolor: "#FFF",
      border: 0,
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8
    };
  });

  const { open, handleClose } = props;
  return (
    <div className="d-flex">
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>{props.children}</Box>
      </Modal>
    </div>
  );
};
export default RightSidePopup;
