import * as React from "react";

import Pagination from "@mui/material/Pagination";
import { Button, InputLabel } from "@mui/material";
import Add from "@mui/icons-material/Add";

//components, constant, Api handler
import CreateBlockList from "../createBlockList/createBlockList";
import IBLTable from "../../components/iblTable/iblTable";
import { blockListManagementAPI } from "../../api";
import RightSidePopup from "../../components/modal/modal";
import BlocklistLoader from "../../components/loader/blocklistLoader/blocklistLoader";
import { toast } from "react-toastify";
import NoDataPresent from "../../components/noDataPresent/noDataPresent";

//styles
import "./iblDashboard.scss";

const IBLDashboard = () => {
  const [iblist, setIbl] = React.useState(null);
  const [pageCount, setPageCount] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [isDataLoading, setIsDataLoading] = React.useState(false);
  const selectedRole = sessionStorage.getItem("selectedRole");

  const handleClose = () => {
    getListOfBlockLists();
    setOpenModal(false);
  };

  const handleChange = (event, value) => {
    getListOfBlockLists(value);
  };

  const getListOfBlockLists = (value) => {
    setIsDataLoading(true);
    const asyncResponse = async () => {
      try {
        const responseFromAPI = await blockListManagementAPI.fetchIBList({
          offset: (value - 1) * 10,
          limit: 10,
        });
        setPageCount(Math.ceil(responseFromAPI.totalRecords / 10));
        setIbl(responseFromAPI.data);
      } catch (e) {
        toast.error("Error fetching data");
        setIbl(null);
        setPageCount(null);
      } finally {
        setIsDataLoading(false);
      }
    };
    asyncResponse();
  };

  React.useEffect(() => {
    getListOfBlockLists();
  }, []);

  return (
    <>
      <div className="mb-2 mt-5 d-flex justify-content-between">
        <span className="mt-5 ibl-heading">Internal Blocklist</span>
        {selectedRole === "maker" && (
          <div className="mt-5 d-flex flex-column justify-content-end">
            <Button
              className="add-rule-btn"
              variant="outlined"
              size="medium"
              onClick={() => setOpenModal((prevState) => !prevState)}
              startIcon={<Add />}
            >
              Create Blocklist
            </Button>
          </div>
        )}
      </div>
      <div className="d-flex flex-column flex-grow-1 mt-5">
        {isDataLoading ? (
          <BlocklistLoader></BlocklistLoader>
        ) : iblist?.length ? (
          <div className="d-flex flex-column fcc-font-style ">
            <div
              style={{ width: "100%", minHeight: "2rem", maxHeight: "2rem" }}
              className={`row d-flex align-items-center`}
            >
              <div className="p-2 col-1"></div>
              {selectedRole === "maker" && (
                <div className="col-4">
                  <InputLabel className="fcc-name">NAME</InputLabel>
                </div>
              )}
              {selectedRole === "checker" && (
                <div className="col-3">
                  <InputLabel className="fcc-name">NAME</InputLabel>
                </div>
              )}
              <div className="d-flex justify-content-center col-1">
                <InputLabel className="fcc-name header-min-width d-flex">
                  ID
                </InputLabel>
              </div>
              <div className="d-flex justify-content-center col-2">
                <InputLabel className="fcc-name">UPDATED ON</InputLabel>
              </div>
              <div className="d-flex justify-content-center col-1">
                <InputLabel className="fcc-name header-min-width">
                  APPROVED
                </InputLabel>
              </div>
              <div className="d-flex justify-content-center col-1">
                <InputLabel className="fcc-name header-min-width">
                  IN REVIEW
                </InputLabel>
              </div>
              <div className="d-flex justify-content-center col-1">
                <InputLabel className="fcc-name header-min-width">
                  DRAFTS
                </InputLabel>
              </div>
              {selectedRole === "maker" && <div className="col-1"></div>}
              {selectedRole === "checker" && <div className="col-1"></div>}
            </div>
            {iblist &&
              iblist.map((data, index) => {
                return (
                  <IBLTable
                    caller="IBLList"
                    data={data}
                    id={data.blocklist_id}
                    index={index}
                    role={selectedRole}
                  ></IBLTable>
                );
              })}
          </div>
        ) : (
          <NoDataPresent message="No blocklist available"></NoDataPresent>
        )}
        {pageCount > 1 && (
          <div
            className="d-flex justify-content-center align-items-center"
            id="pagination-div"
          >
            <Pagination
              count={pageCount}
              shape="rounded"
              onChange={handleChange}
            />
          </div>
        )}
        <RightSidePopup open={openModal} width="26%">
          <CreateBlockList handleClose={handleClose} />
        </RightSidePopup>
      </div>
    </>
  );
};

export default IBLDashboard;
