import React, { useRef, useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import "./overflowTooltip.scss";
const OverflowTip = (props) => {
  // Create Ref
  const textElementRef = useRef(null);

  const compareSize = () => {
    let compare = false;
    if (textElementRef?.current)
      compare =
        textElementRef?.current?.scrollWidth >
        textElementRef?.current?.clientWidth;
    setHoverStatus(compare);
  };

  useEffect(() => {
    compareSize();
    window.addEventListener("resize", compareSize);
    return () => {
      window.removeEventListener("resize", compareSize);
    };
  }, []);

  const [hoverStatus, setHoverStatus] = useState(false);

  return hoverStatus ? (
    <>
      <Tooltip
        title={props.value}
        disableInteractive
      >
        <div
          ref={textElementRef}
          onMouseEnter={() => compareSize()}
          className={`${props.className} elipsis`}
          data-testid="textElement"
        >
          {props.value}
        </div>
      </Tooltip>
    </>
  ) : (
    <div
      ref={textElementRef}
      onMouseEnter={() => compareSize()}
      className={`${props.className} elipsis`}
      data-testid="textElement"
    >
      {props.value}
    </div>
  );
};

export default OverflowTip;
