import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

export default function SpinnerLoader({ message }) {
  return (
    <Box className="w-100 d-flex flex-column justify-content-center align-items-center" data-testid='spinner-loader'>
      <CircularProgress />
      <span style={{ color: "#000" }}>{message}</span>
    </Box>
  );
}
