import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { toast } from "react-toastify";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";

//components, constants
import Tabs from "../../components/tabs/tabs";
import BasicDetails from "./basicDetails";
import InformationDetails from "./informationDetails";
import { monthsMapperObj } from "../../components/dateComponent/DateConstants";
import { setEnumDetails } from "../../redux/enumDetailsRedux";
import { fetchCountries } from "../../api/listManagementAPI";
import { camelToUnderscore } from "../../constants/additionalInformationTabUtility";
import IBLDetailsComponent from "../IBLDetails/iblDetailsComponent/ibldetailsComponent";
import { blockListManagementAPI } from "../../api";
import { resetAddtionalInformation, setAdditionalInformation } from "../../redux/aditionalInformationRedux";
import {
  IBL_API_ACTION_ID,
  IBL_RECORD_TYPE,
  IBL_RECORD_TYPE_ID,
} from "../../constants/iblConstants";
import {
  addUpdateEntity,
} from "../../api/blockListManagementAPI";
import { getCountries, getNameAndNationalType} from "../../redux/enityDetailsRedux";
import {
  updateEntityDetails,
  resetEntityDetails,
  resetEntityField,
  setLoading
} from "../../redux/enityDetailsRedux";
import { setEntityData } from "../../redux/entityDataRedux";
import { ADDITIONAL_INFORMATION_VALIDATE_FUNCTION_MAPPER } from "../../constants/iblConstants";
import { isValueEmpty, isEmptyCheck } from "../../constants/utility";
import { resetAttachmentsData, setAttachmentsData } from "../../redux/attachmentsredux";
//style
import "./addEntity.scss";


const AddEntity = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const userId = useSelector((state) => state.login?.user?.attributes?.email);
  const additionalInformationData = useSelector((state) => state.aditionalInformationDetails)
  const attachmentData = useSelector((state) => state.attachmentData.attachments);

  const enumDetails = useSelector((state) => state.enumDetails );
  const entityData = useSelector((state) => state.entityData );

  const entityDetails = useSelector((state) => state.entityDetails);
  const countries = useSelector((state) => state.entityDetails.countries);

  const [disableSaveAsdraftButton, setDisableSaveAsdraftButton] =
    useState(true);
  const [disableSubmitButton, setDisableSubmitButton] = useState(true);

  let activeTabComponent = null;
  const { state } = useLocation();
  let timer = useRef(null);

  const [activeTab, setActiveTab] = useState("basicDetails");
  const [tabs, setTabs] = useState([]);
  const [additionalAttributes, setAdditionalAttributes] = useState(null);
  const [validateFunctionDetails, setValidateFunctionDetails] = useState({});
  const [activeRadioButton, setActiveRadioButton] = useState("individual");
  const [loading, setLoading] = useState(true);
  const [sortedAdditionalAttributes, setSortedAdditionalAttributes]= useState(null);


  const selectedRole = sessionStorage.getItem("selectedRole");
  const blocklistDetails = JSON.parse(
    sessionStorage.getItem("selectedBlocklistDetails")
  );



  switch (activeTab) {
    case "informationFields":
      activeTabComponent = <InformationDetails loadingOnSubmit={loading} sortedAdditionalAttributes={sortedAdditionalAttributes}/>;
      break;
    default:
      activeTabComponent = (
        <BasicDetails activeRadioButton={activeRadioButton} loadingOnSubmit={loading} state={state}/>
      );
  }


  useEffect(() => {
    const asyncResponse = async () => {
      const responseFromAPI = await blockListManagementAPI.getAdditionalAttributes();
      setAdditionalAttributes(responseFromAPI?.data);
      const tabs = [
        { title: "BASIC DETAILS", value: "basicDetails" }
      ];
      if(responseFromAPI?.data?.length) {
        tabs.push({ title: "INFORMATION FIELDS", value: "informationFields" });
      }
      setTabs(tabs);
      if (state?.action === "add")  setLoading(false);

    };
    asyncResponse();
  }, []);

  useEffect(() => {
    if (state?.action === "edit") {
      fetchEntityBaiscDetailsObj();
    }
    dispatch(getCountries());
    dispatch(getNameAndNationalType());
  }, []);

  useEffect(() =>{
    if (state?.action === "edit" && !_.isEmpty(additionalAttributes)) {
      fetchAdditionalInformationDetails();
      setLoading(false);

    }

  },[additionalAttributes, countries]) 



  useEffect(() => {
    const activeRadioButtonId = activeRadioButton === "individual" ? 1 : 2
    if(additionalAttributes){
      const sortedArray = additionalAttributes
        .sort((a, b) => a.display_sort_order_id - b.display_sort_order_id)
        .filter((ele) => ele.entity_type_id.includes(activeRadioButtonId));
      setSortedAdditionalAttributes(sortedArray);
    }

  }, [activeRadioButton, additionalAttributes]);

  useEffect(() => {
    const updatedValidateFunction = {};
    if (additionalAttributes?.length > 0) {
      for(let i = 0; i < additionalAttributes.length; i++){
        updatedValidateFunction[[camelToUnderscore(additionalAttributes[i].enum_type_name)]] =
            ADDITIONAL_INFORMATION_VALIDATE_FUNCTION_MAPPER[additionalAttributes[i].ui_type]
      }
      setValidateFunctionDetails({ ...updatedValidateFunction });
    }
  }, [additionalAttributes]);


  useEffect(() => {
    if (state?.data?.record_type)
      setActiveRadioButton(IBL_RECORD_TYPE[state?.data?.record_type]);
      if(entityDetails.dateTypeList.length > 0){
        const enumDetailsObj = entityDetails.dateTypeList.filter((dobType) => {
          if (dobType.enum_value_id === state?.data?.record_type) return dobType;
        })[0];
        dispatch(setEnumDetails({...enumDetailsObj}));
      }
  }, []);


  useEffect(() => {
    if (timer.current) clearTimeout(timer.current);

    timer.current = setTimeout(() => {
      validateField();
    }, [500]);
  }, [entityDetails?.standard_data?.names, entityDetails?.standard_data?.additionalInformation]);

  useEffect(() =>{
    if(entityDetails.error?.status){
      if (entityDetails.error?.response?.data) {
        toast.error(t(entityDetails.error.response?.data?.code));
      } else {
        toast.error(t(entityDetails.error.code));
      }
    }
  },[entityDetails?.standard_data?.error])


  const onChangeHandler = async (event, newActiveTab) => {
    if(disableSaveAsdraftButton && newActiveTab === "informationFields") {
      toast.error("Please fill the mandatory fields");
      return;
    }
    if(newActiveTab === "informationFields" && entityData?.entityData?.action === "add" && !entityData?.entityData?.data?.blocklist_entry_version_no){
      submitHandler("save-as-draft", disableSaveAsdraftButton, false);
    }
    if (newActiveTab !== null) setActiveTab(newActiveTab);
  };

  const navigateToIBLEntries = () => {
    dispatch(resetEntityDetails());
    dispatch(resetAddtionalInformation());
    dispatch(resetAttachmentsData());
    dispatch(setEntityData({ query: blocklistDetails.blocklist_id, action: "add" }));
    navigate("/iblDetails", {
      state: { query: blocklistDetails.blocklist_id, action: "add" },
    });
  };

  const handleChange = (event) => {
    setActiveRadioButton(event.target.value);
    const recordType = event.target.value === "individual" ? 1 : 2;

    if(entityDetails.dateTypeList.length > 0){
      const enumDetailsObj = entityDetails.dateTypeList.filter((dobType) => {
        if (dobType.enum_value_id === recordType) return dobType;
      })[0];

      dispatch(setEnumDetails({...enumDetailsObj}));
    }
  };

  const fetchEntityBaiscDetailsObj = async () => {
    await resetFieldHandler();
    await dispatch(
      updateEntityDetails({
        names: state.data.standard_data.names,
        dobs: _.isEmpty(state.data.standard_data.dobs)
          ? selectedRole === "maker" ? (entityDetails.standard_data.dobs): []
          : (state.data.standard_data.dobs
        ).map((dob) => ({
          enum_type_id: 3,
          enum_value_id: enumDetails.enum_value_id,
          enum_value_name: enumDetails.enum_value_name,
          month: dob.month ? { id: dob.month, value: monthsMapperObj[dob.month] } : null,
          day: dob?.day ? { id: dob.day, value: dob.day } : null,
          year: dob?.year ? {id: dob.year, value: dob.year} : null,
        })),
        addresses: _.isEmpty(state.data.standard_data.addresses)
          ? selectedRole === "maker" ? entityDetails.standard_data.addresses : []
          : state.data.standard_data.addresses,
        national_ids: _.isEmpty(state.data.standard_data.national_ids)
          ? selectedRole === "maker" ? entityDetails.standard_data.national_ids : []
          : state.data.standard_data.national_ids,
        additionalInformation: {
          proposed_start_dtm: state.data.proposed_start_dtm,
          proposed_end_dtm: state.data.proposed_end_dtm,
          comment: state.data.comment,
        },
        proposed_start_dtm: state.data.proposed_start_dtm,
        proposed_end_dtm: state.data.proposed_end_dtm,
        comment: state.data.comment,
      })
    );
  };

  const fetchAdditionalInformationDetails = async () => {
    const data = state.data.additional_data;
    if (!_.isEmpty(additionalAttributes)) {
      additionalAttributes.map((ele) => {
        const elementData = data[camelToUnderscore(ele.enum_type_name)]
          ? data[camelToUnderscore(ele.enum_type_name)]
          : null;
        switch (ele.ui_type) {
          case "complex_radio_date":
            dispatch(
              setAdditionalInformation({
                key: camelToUnderscore(ele.enum_type_name),
                data:
                  Object.keys(elementData).length > 0
                    ? {
                        enum_type_id: elementData.enum_type_id,
                        is_true: elementData.is_true,
                        day: elementData?.day
                          ? {
                              id: elementData?.day,
                              value: elementData?.day,
                            }
                          : "",
                        month: elementData?.month
                          ? {
                              id: elementData?.month,
                              value:
                                monthsMapperObj[
                                  elementData?.month.toString()
                                ],
                            }
                          : "",
                        year: elementData?.year ?  {
                          id: elementData?.year,
                          value: elementData?.year,
                        } : "",
                      }
                    : {
                      },
              })
            );
            break;
          case "radio_group":
            dispatch(
              setAdditionalInformation({
                key: camelToUnderscore(ele.enum_type_name),
                data: elementData.enum_value_id
                  ? { ...elementData }
                  : {
                    },
              })
            );
            break;
          case "textarea":
            dispatch(
              setAdditionalInformation({
                key: camelToUnderscore(ele.enum_type_name),
                data:
                  elementData.value !== ""
                    ? { ...elementData }
                    : { 
                    },
              })
            );
            break;
          case "textbox":
            dispatch(
              setAdditionalInformation({
                key: camelToUnderscore(ele.enum_type_name),
                data:
                  elementData?.values?.length > 0
                    ? {
                        enum_type_id: elementData.enum_type_id,
                        values: [...elementData.values],
                      }
                    : {
                      },
              })
            );

            break;
          case "complex_dropdown":
            let updatedAdditionalTabData = [];
            elementData?.length >= 0 &&
              elementData.map((complexDropdowndata) => {
                ele.enum_values.map((enumvalue) => {
                  if (
                    enumvalue.enum_value_id ===
                    complexDropdowndata.enum_value_id
                  ) {
                    updatedAdditionalTabData.push({
                      enum_type_id: complexDropdowndata.enum_type_id,
                      enum_value_id: {
                        id: enumvalue.enum_value_id,
                        value: enumvalue.enum_value_name,
                      },
                      enum_value_name: {
                        id: enumvalue.enum_value_id,
                        value: enumvalue.enum_value_name,
                      },
                      code: countries?.filter(
                        (country) => country.id === complexDropdowndata.code
                      )[0],
                    });
                  }
                });
              });
            dispatch(
              setAdditionalInformation({
                key: camelToUnderscore(ele.enum_type_name),
                data:
                  updatedAdditionalTabData.length > 0
                    ? [...updatedAdditionalTabData]
                    : [],
              })
            );
            break;
          case "complex_dropdown_date":
            let updatedComplexDropdownDate = [];
            elementData?.length >= 0 &&
              elementData.map((complexDropdowndateData) => {
                ele.enum_values.map((enumvalue) => {
                  if (
                    enumvalue.enum_value_id ===
                    complexDropdowndateData.enum_value_id
                  ) {
                    updatedComplexDropdownDate.push({
                      enum_type_id: complexDropdowndateData.enum_type_id,
                      enum_value_id: {
                        id: enumvalue.enum_value_id,
                        value: enumvalue.enum_value_name,
                      },
                      enum_value_name: {
                        id: enumvalue.enum_value_id,
                        value: enumvalue.enum_value_name,
                      },
                      sinceDay: complexDropdowndateData.sinceDay
                        ? {
                            id: complexDropdowndateData.sinceDay,
                            value: complexDropdowndateData.sinceDay,
                          }
                        : "",
                      sinceMonth: complexDropdowndateData.sinceMonth
                        ? {
                            id: complexDropdowndateData.sinceMonth,
                            value:
                              monthsMapperObj[
                                complexDropdowndateData.sinceMonth.toString()
                              ],
                          }
                        : "",
                      sinceYear: {
                        id: complexDropdowndateData.sinceYear,
                        value: complexDropdowndateData.sinceYear,
                      },
                      toDay: complexDropdowndateData.toDay
                        ? {
                            id: complexDropdowndateData.toDay,
                            value: complexDropdowndateData.toDay,
                          }
                        : "",
                      toMonth: complexDropdowndateData.toMonth
                        ? {
                            id: complexDropdowndateData.toMonth,
                            value:
                              monthsMapperObj[
                                complexDropdowndateData.sinceMonth.toString()
                              ],
                          }
                        : "",
                      toYear: {
                        id: complexDropdowndateData.toYear,
                        value: complexDropdowndateData.toYear,
                      },
                    });
                  }
                });
              });

            dispatch(
              setAdditionalInformation({
                key: camelToUnderscore(ele.enum_type_name),
                data:
                  updatedComplexDropdownDate.length > 0
                    ? [...updatedComplexDropdownDate]
                    : [],
              })
            );
            break;
          case "complex_attachment":
            if (elementData?.length > 0) {
              dispatch(setAttachmentsData([...elementData]));
            }
            break;
          default:
        }
      });
    }
  };


  const resetFieldHandler = () => {
    dispatch(resetEntityField({ key: "additionalInformation" }));
    if (_.isEmpty(state.data.standard_data.dobs))
      dispatch(resetEntityField({ key: "dobs" }));
    if (_.isEmpty(state.data.standard_data.addresses))
      dispatch(resetEntityField({ key: "addresses" }));
    if (_.isEmpty(state.data.standard_data.national_ids))
      dispatch(resetEntityField({ key: "national_ids" }));
  };


  useEffect(() =>{
    const recordType = activeRadioButton === "individual" ? 1 : 2;

    const enumDetailsObj = entityDetails.dateTypeList?.filter((dobType) => {
      if (dobType.enum_value_id === recordType) return dobType;
    })[0];
    dispatch(setEnumDetails({...enumDetailsObj}));
  },[entityDetails.dateTypeList]);


 //======submit handler

  const submitHandler = async (type, buttonDisabled, redirect) => {
    if(buttonDisabled) { return }
    setLoading(true)
    const standardData = {
      names: entityDetails.standard_data.names.map((name) => ({
        enum_type_id: name.enum_type_id,
        enum_value_id: name.enum_value_id,
        enum_value_name: name.enum_value_name,
        title_honorific: name.title_honorific,
        first_name: name.first_name,
        middle_name: name.middle_name,
        last_name: name.last_name,
        maiden_name: name.maiden_name,
        suffix: name.suffix,
        original_script_name: name.original_script_name,
      })),
      dobs: isValueEmpty(entityDetails.standard_data.dobs)
      ? []
      : entityDetails.standard_data.dobs.map((dob) => ({
          enum_type_id: 3,
          enum_value_id: enumDetails.enumData.enum_value_id,
          enum_value_name: enumDetails.enumData.enum_value_name,
          month: dob.month ? dob.month.id : null,
          day: dob?.day ? dob.day.id : null,
          year: dob?.year ? dob.year.id : null,
        })),
      addresses: isValueEmpty(entityDetails.standard_data.addresses)
        ? []
        : entityDetails.standard_data.addresses,
      national_ids: isValueEmpty(entityDetails.standard_data.national_ids)
        ? []
        : entityDetails.standard_data.national_ids,
    };

    let body = {
      api_action: IBL_API_ACTION_ID[type],
      client_id: 1,
      user_id: userId,
      blocklist_id: entityData.entityData.action === "add" ? entityData.entityData.id : entityData.entityData.data.blocklist_id,
      record_type: IBL_RECORD_TYPE_ID[activeRadioButton],
      proposed_start_dtm:
        entityDetails?.standard_data?.additionalInformation?.proposed_start_dtm,
      proposed_end_dtm: entityDetails?.standard_data?.additionalInformation?.proposed_end_dtm,
      standard_data: standardData,
      comment: entityDetails?.standard_data?.additionalInformation.comment,
    };

    if (entityData.entityData.action === "edit") {
      body["blocklist_entry_id"] = entityData.entityData.data.blocklist_entry_id;
      body["blocklist_entry_version_no"] =
        entityData.entityData.data.blocklist_entry_version_no;
    }

    const dobvalidate = validateDate()
    if(dobvalidate){
      toast.error(t("Please Enter the valid date"));
      setLoading(false);
      return
    }
    let updatedAdditionalTabData = {...additionalInformationData}
    await removeEmptyFieldHandler(updatedAdditionalTabData);
    let validateAdditionalTabData = { error: false, error_message: "" };

    validateAdditionalField(validateAdditionalTabData, updatedAdditionalTabData);
    if(validateAdditionalTabData.error){
      toast.error(validateAdditionalTabData["error_message"]);
      setLoading(false);
      return;
    }

    if(tabs.length > 0 ){
      let additionalData = {};
      sortedAdditionalAttributes.map((ele) => {
        if(updatedAdditionalTabData[camelToUnderscore(ele.enum_type_name)]){
          switch (ele.ui_type) {
            case "complex_radio_date":
              additionalData[camelToUnderscore(ele.enum_type_name)] = {
                enum_type_id: updatedAdditionalTabData[camelToUnderscore(ele.enum_type_name)].enum_type_id,
                is_true: updatedAdditionalTabData[camelToUnderscore(ele.enum_type_name)].is_true,
                day: updatedAdditionalTabData[camelToUnderscore(ele.enum_type_name)]?.day?.id ? updatedAdditionalTabData[camelToUnderscore(ele.enum_type_name)]?.day?.id : null,
                month: updatedAdditionalTabData[camelToUnderscore(ele.enum_type_name)]?.month?.id ? updatedAdditionalTabData[camelToUnderscore(ele.enum_type_name)]?.month?.id : null,
                year: updatedAdditionalTabData[camelToUnderscore(ele.enum_type_name)]?.year?.id ? updatedAdditionalTabData[camelToUnderscore(ele.enum_type_name)]?.year.id : null,
              }
            break;
            case "radio_group":
            case "textarea":
            case "radio_group":
            case "textbox":
              additionalData[camelToUnderscore(ele.enum_type_name)] = updatedAdditionalTabData[camelToUnderscore(ele.enum_type_name)]
              break;
            case "complex_dropdown":
              additionalData[camelToUnderscore(ele.enum_type_name)] = updatedAdditionalTabData[camelToUnderscore(ele.enum_type_name)]?.length > 0 && updatedAdditionalTabData[camelToUnderscore(ele.enum_type_name)].map((values) => ({
                enum_type_id: ele.enum_type_id,
                enum_value_id: values.enum_value_id.id,
                enum_value_name: values.enum_value_name.value,
                code: values.code.id
              }))
              break;
            case "complex_dropdown_date":
              additionalData[camelToUnderscore(ele.enum_type_name)] = updatedAdditionalTabData[camelToUnderscore(ele.enum_type_name)]?.length > 0 && updatedAdditionalTabData[camelToUnderscore(ele.enum_type_name)].map((values) => ({
                enum_type_id: ele.enum_type_id,
                enum_value_id: values.enum_value_id.id,
                enum_value_name: values.enum_value_name.value,
                sinceDay: values.sinceDay.id,
                sinceMonth: values.sinceMonth.id,
                sinceYear: values.sinceYear.id,
                toDay: values.toDay.id,
                toMonth: values.toMonth.id,
                toYear: values.toYear.id,
              })) 
              break;
            default:
          }
        }
      });

      body["additional_data"] = {...additionalData}
      if(attachmentData.length > 0){
        body["additional_data"]["attachments"] = [...attachmentData]
      }
    }

    const response = await addUpdateEntity(body);

    if (response.status === 200 && Object.keys(response.data.data).length > 0) {
      if(redirect)
        toast.success("Blocklist Entry Successfully created !");
      if (type === "submit-and-add-another") {
        dispatch(resetEntityDetails());
        dispatch(resetAddtionalInformation());
        dispatch(resetAttachmentsData());
        setActiveTab("individual")
        dispatch(setEntityData({ id: blocklistDetails.blocklist_id, action: "add" }));
        setLoading(false);
        return;
      }
      if(redirect){
        dispatch(setEntityData({ query: blocklistDetails.blocklist_id, activeTab: type, action: "add"}));
        navigate("/iblDetails", { state: { query: blocklistDetails.blocklist_id, presentActiveTab: type, action: "add"} });
        // dispatch(setEntityData({ id: entityData.entityData.data.blocklist_id, action: "add" }));
        dispatch(resetEntityDetails());
        dispatch(resetAddtionalInformation());
      } else {
        dispatch(setEntityData({ data: { ...response.data.data }, action: "edit" , id: blocklistDetails.blocklist_id}));
      }
      dispatch(resetAttachmentsData());
    } else {
      if (response?.response?.data) {
        toast.error(t(response?.response?.data?.code));
      } else {
        toast.error(t(response.code));
      }
    }
    setLoading(false);
  };

  //========================= validation ===================

  const validateDate = () => {
    for (let i = 0; i < entityDetails?.standard_data?.dobs.length; i++) {
      if(entityDetails?.standard_data?.dobs[i].day === null && entityDetails?.standard_data?.dobs[i].year === null && entityDetails?.standard_data?.dobs[i].month == null && entityDetails?.standard_data?.dobs.length > 1 ) return true
      if (entityDetails?.standard_data?.dobs[i].day) {
        if (entityDetails?.standard_data?.dobs[i].year === null || entityDetails?.standard_data?.dobs[i].month == null) return true;
      }
      if (entityDetails?.standard_data?.dobs[i].month) {
        if (entityDetails?.standard_data?.dobs[i].year === null) return true;
      }
    }
    return false
  };

  const validatenamePrimaryName = (isError) => {
    let isPrimaryNamePresent = false;
    for (let i = 0; i < entityDetails?.standard_data?.names.length; i++) {
      isPrimaryNamePresent =
        entityDetails?.standard_data?.names[i].enum_value_id === 1
          ? true
          : false;
      if (isPrimaryNamePresent)
        isError.isPrimaryNamePresent = isPrimaryNamePresent;
    }
  };

  const validatenameField = (key, name, isError) => {
    let error = false;

    if (
      !_.isEmpty(name.first_name) ||
      !_.isEmpty(name.last_name) ||
      !_.isEmpty(name.middle_name) ||
      !_.isEmpty(name.maiden_name)
    ) {
      error = false;
    } else {
      error = true;
    }
    if (isError.error === false) isError.error = error;
  };


  const removeEmptyFieldHandler = (updatedAdditionalTabData) => {
    additionalAttributes.map((ele) => {
      switch (ele.ui_type) {
        case "complex_radio_date":
          if (
            (updatedAdditionalTabData[camelToUnderscore(ele.enum_type_name)] &&
            (Object.keys(
              updatedAdditionalTabData[camelToUnderscore(ele.enum_type_name)]
            )?.length === 0) ||
            isEmptyCheck(
              updatedAdditionalTabData[camelToUnderscore(ele.enum_type_name)]
                ?.enum_type_id
            ))
          ) {
            delete updatedAdditionalTabData[
              camelToUnderscore(ele.enum_type_name)
            ];
          }
          break;
        case "radio_group":
        case "textarea":
        case "radio_group":
          if (
            updatedAdditionalTabData[camelToUnderscore(ele.enum_type_name)] &&
            (Object.keys(
              updatedAdditionalTabData[camelToUnderscore(ele.enum_type_name)]
            )?.length === 0 ||
            isEmptyCheck(
              updatedAdditionalTabData[camelToUnderscore(ele.enum_type_name)]
                ?.enum_type_id
            ))
          ) {
            delete updatedAdditionalTabData[
              camelToUnderscore(ele.enum_type_name)
            ];
          }
          break;
        case "textbox":
          if ( updatedAdditionalTabData[camelToUnderscore(ele.enum_type_name)] &&
            (Object.keys(
              updatedAdditionalTabData[camelToUnderscore(ele.enum_type_name)]
            )?.length === 0 ||
            Object.keys(
              updatedAdditionalTabData[camelToUnderscore(ele.enum_type_name)]
            )?.values[0] === "" ||
            isEmptyCheck(
              updatedAdditionalTabData[camelToUnderscore(ele.enum_type_name)]
                ?.enum_type_id
            ))
          ) {
            delete updatedAdditionalTabData[
              camelToUnderscore(ele.enum_type_name)
            ];
          }
          break;
        case "complex_dropdown":
        case "complex_dropdown_date":
          if ( updatedAdditionalTabData[camelToUnderscore(ele.enum_type_name)] &&
            (updatedAdditionalTabData[camelToUnderscore(ele.enum_type_name)]
              ?.length === 0) 
          ) {
            delete updatedAdditionalTabData[
              camelToUnderscore(ele.enum_type_name)
            ];
          }
          break;
        default:
      }
    });

    return updatedAdditionalTabData;
  };


  const validateAdditionalField = (
    validateAdditionalTabData,
    updatedAdditionalTabData
  ) => {
    const additionalInformationKeys = Object.keys(updatedAdditionalTabData);
    if (additionalInformationKeys.length > 0) {
      let result = {};
      for (let i = 0; i < additionalInformationKeys.length; i++) {
       result = validateFunctionDetails[
          additionalInformationKeys[i]
        ](updatedAdditionalTabData[additionalInformationKeys[i]]);
        if (result.error) {
          validateAdditionalTabData["error"] = true;
          validateAdditionalTabData["error_message"] = result["error_message"];
          return;
        }
      }
      validateAdditionalTabData["error"] = false;
    }
  };

  const validateField = async () => {
    const isError = { error: false, isPrimaryNamePresent: false };
    validatenamePrimaryName(isError);
    for (let i = 0; i < entityDetails?.standard_data?.names.length; i++) {
      validatenameField(
        entityDetails?.standard_data?.names[i].enum_value_name,
        entityDetails?.standard_data?.names[i],
        isError
      );
    }
    if (isError.isPrimaryNamePresent && isError.error) {
      setDisableSaveAsdraftButton(true);
      setDisableSubmitButton(true);
    } else if (!isError.isPrimaryNamePresent) {
      setDisableSaveAsdraftButton(true);
      setDisableSubmitButton(true);
    } else {
      setDisableSaveAsdraftButton(false);
      if (
        isEmptyCheck(entityDetails?.standard_data?.additionalInformation?.proposed_end_dtm) ||
        isEmptyCheck(entityDetails?.standard_data?.additionalInformation?.proposed_end_dtm) ||
        _.isEmpty(entityDetails?.standard_data?.additionalInformation.comment)
      ) {
        setDisableSubmitButton(true);
      } else {
        setDisableSubmitButton(false);
      }
    }
  };


  return (
    <>
      <div
        className="d-flex flex-row mt-3 px-1 py-5 w-100"
        id="blocklist-details"
      >
        <Button
          variant="text"
          startIcon={<ArrowBackIcon />}
          className="fcc-color-grey me-1 w-5"
          onClick={navigateToIBLEntries}
        >
          BACK
        </Button>
        <IBLDetailsComponent blocklistDetails={blocklistDetails} />
      </div>
      <div className="add-blocklist-entry-content">

        <Tabs tabs={tabs} onChangeHandler={onChangeHandler} value={activeTab} />
        <div>
          <FormControl sx={{ marginY: "1%" }}>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={activeRadioButton}
              onChange={handleChange}
              sx={{
                "&.MuiFormGroup-root": {
                  marginTop: "1%",
                  marginBottom: "1%",

                  color: "#1C2472",
                },
              }}
            >
              <FormControlLabel
                value="individual"
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "#FB665C",
                      },
                      "&.MuiFormGroup-root": {
                        color: "blue",
                      },
                    }}
                  />
                }
                label="Individual"
                disabled={selectedRole=== "checker"}
              />
              <FormControlLabel
                value="organisation"
                control={
                  <Radio
                    sx={{
                      " &.Mui-checked": {
                        color: "#FB665C",
                      },
                    }}
                  />
                }
                label="Organisation"
                disabled={selectedRole=== "checker"}
              />
            </RadioGroup>
          </FormControl>
        </div>
        {activeTabComponent != null && activeTabComponent}
        {
          selectedRole === "maker" && 
            <div className="action-button-container d-flex align-items-end justify-content-end p-3 mt-2 mb-5">
              <Tooltip title={disableSaveAsdraftButton ? "Please enter the primary name" : ""} followCursor={disableSaveAsdraftButton}>
                <Button
                  size="medium"
                  variant="outlined"
                  color="error"
                  onClick={() => submitHandler("save-as-draft", disableSaveAsdraftButton, true)}
                  className={`save-as-draft-btn ${
                    disableSaveAsdraftButton && "disabled-style-draft-button"
                  }`}
                >
                  SAVE AS DRAFT
                </Button>
              </Tooltip>
              <Tooltip  title={disableSubmitButton ? "Please enter additional information" : ""} followCursor={disableSubmitButton}>
                <Button
                  size="medium"
                  variant="contained"
                  color="error"
                  onClick={() => submitHandler("submit", disableSubmitButton, true)}
                  className={`submit-btn ${
                    disableSubmitButton && "disabled-style"
                  }`}
                >
                  SUBMIT
                </Button>
              </Tooltip>
              <Tooltip title={disableSubmitButton ? "Please enter additional information" : ""}  followCursor={disableSubmitButton}>
                <Button
                  size="medium"
                  variant="contained"
                  color="error"
                  className={`submit-btn ${
                    disableSubmitButton && "disabled-style"
                  }`}
                  onClick={() => submitHandler("submit-and-add-another", disableSubmitButton, true)}
                >
                  SUBMIT AND ADD ANOTHER{" "}
                </Button>
              </Tooltip>
              </div>
        }
          <div style={{height: "1ch"}}></div>
      </div>
    </>
  );
};

export default AddEntity;
