import { useEffect, useState, useRef } from "react";
import { TextField, Divider, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import ClearIcon from "@mui/icons-material/Clear";
import ModeIcon from "@mui/icons-material/Mode";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";

//components, constants

import DetailsSection from "../../features/detailsSection/detialsSection";
import { setAdditionalInformation } from "../../redux/aditionalInformationRedux";
import { camelToUnderscore } from "../../constants/additionalInformationTabUtility";
import DateCompoment from "../dateComponent/DateComponent";
import { CUSTOM_DROPDOWN_DATE_KEYS } from "../../constants/iblConstants";
import { isEmptyCheck } from "../../constants/utility";
import { convertBase64 } from "../../constants/utility";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import OverflowTip from "../../components/overflowTooltip/overflowTooltip";
import { attachments } from "../../api/blockListManagementAPI";
import { setAttachmentsData } from "../../redux/attachmentsredux";
import { setLoading } from "../../redux/enityDetailsRedux";
//styles

import "./customAttachment.scss";

const CustomAttachment = (props) => {
  const { attributeDetails, isDisabled } = props;
  const uploadFileRef = useRef(null);

  const { t } = useTranslation();

  const userId = useSelector((state) => state.login?.user?.attributes?.email);
  const selectedRole = sessionStorage.getItem("selectedRole");

  const [fileDataState, setFileDataState] = useState(selectedRole === "maker" ? {
    enum_type_id: "",
    file_name: "",
    description: "",
    file: null,
    file_display_name: "",
  } : null);

  const [attachmentState, setAttachmentState] = useState([]);
  const [uploadedAttachments, setUploadedAttachments] = useState([]);

  const dispatch = useDispatch();
  const attachmentData = useSelector(
    (state) => state.attachmentData.attachments
  );
  const entityData = useSelector((state) => state.entityData);


  useEffect(() => {
    if (entityData.entityData?.action === "edit" && attachmentData?.length > 0) {
      setUploadedAttachments([...attachmentData]);
    }
  }, [attachmentData]);

  const onChangeHandler = (value, key, index) => {
    setFileDataState((prevSate) => ({ ...prevSate, [key]: value }));
  };

  const addFieldHandler = () => {
    setAttachmentState((prevSate) => [...prevSate, fileDataState]);
    if (uploadFileRef?.current?.value) {
      uploadFileRef.current.value = null;
    }
    setFileDataState({
      enum_type_id: "",
      file_name: "",
      description: "",
      file: null,
      file_display_name: "",
    });
  };

  const fileChangeHandler = (event) => {
    if (event.target.files[0]) {
      const file = event.target.files[0];
      const size = file.size / (1024 * 1024);
      if (size > 2) {
        return toast.error("File Should be Less than 2mb", {
          autoClose: 2000,
        });
      }
      setFileDataState((prevSate) => ({
        ...prevSate,
        file: event.target.files[0],
        file_display_name: event.target.files[0].name,
      }));
      toast.success(`File Selected: ${event.target.files[0].name}`, {
        autoClose: 2000,
      });
    } else {
      toast.error("Please Select a file", {
        autoClose: 2000,
      });
    }
  };

  const deleteHandler = (id) => {
    const updatedValues = [...attachmentState];
    updatedValues.splice(id, 1);
    setAttachmentState([...updatedValues]);
  };

  const deleteUploadedFileHandler = async (id) => {
    dispatch(setLoading(true));
    let body = {
      api_action: 3,
      client_id: entityData.entityData.data.client_id,
      blocklist_id: entityData.entityData.data.blocklist_id,
      blocklist_entry_id: entityData.entityData.data.blocklist_entry_id,
      blocklist_entry_version_no: entityData.entityData.data.blocklist_entry_version_no,
      user_id: userId,
      attachment: { file_url: uploadedAttachments[id].file_url },
    };

    const response = await attachments(body);

    if (response.status === 200 && Object.keys(response.data.data).length > 0) {
      toast.success("Deleted successfully!");
      dispatch(setLoading(false));
      setUploadedAttachments([...response.data.data.attachments]);
      dispatch(setAttachmentsData([...response.data.data.attachments]));
      return;
    } else {
      if (response?.response?.data) {
        toast.error(t(response?.response?.data?.code));
      } else {
        toast.error(t(response.code));
      }
    }
  };

  const submitHandler = async () => {
    dispatch(setLoading(true));
    let body = {
      api_action: 1,
      client_id: entityData.entityData.data.client_id,
      blocklist_id: entityData.entityData.data.blocklist_id,
      blocklist_entry_id: entityData.entityData.data.blocklist_entry_id,
      blocklist_entry_version_no:
        entityData.entityData.data.blocklist_entry_version_no,
      user_id: userId,
      attachments: [],
    };

    let data = await Promise.all(
      attachmentState.map(async (attachment) => {
        const base64ConvertedFile = await convertBase64(attachment.file);
        return await {
          enum_type_id: attributeDetails.enum_type_id,
          file_name: attachment.file_name,
          description: attachment.description,
          base64_url: base64ConvertedFile,
        };
      })
    );

    body["attachments"] = [...data];

    const response = await attachments(body);

    if (response.status === 200 && Object.keys(response.data.data).length > 0) {
      toast.success("Attachments uploaded successfully!");
      dispatch(setLoading(false));
      dispatch(setAttachmentsData([...response.data.data.attachments]));
      setUploadedAttachments([...response.data.data.attachments]);
      setAttachmentState([]);
      return;
    } else {
      if (response?.response?.data) {
        toast.error(t(response?.response?.data?.code));
      } else {
        toast.error(t(response.code));
      }
    }
  };

  return (
    <DetailsSection
      title={attributeDetails?.default_label_name}
      showAddIcon={false}
    >{
      selectedRole=== "maker" && 
      <div className="d-flex ms-2">
        <div className="d-flex" style={{ width: "20%" }}>
          <Button
            fullWidth
            className={`upload-file-btn`}
            variant="outlined"
            sx={{
              height: "99%",
            }}
            color="error"
            onClick={() => {
              uploadFileRef.current.click();
            }}
            // disabled={ele?.file_name !== null}
          >
            <UploadFileIcon
              sx={{
                color: "#FB665C",
                cursor: "pointer",
              }}
              height={18}
              width={15}
            />
            <span className={`upload-file-btn-text`}>UPLOAD FILE</span>
          </Button>
          <input
            data-testid="upload-file"
            onChange={(event) => fileChangeHandler(event)}
            multiple={false}
            ref={uploadFileRef}
            type="file"
            accept=".xls,.xlsx, .json, .pdf, .jpeg, .jpg, .png, .doc, .zip"
            hidden
          />
        </div>
        <div className="d-flex ms-2 me-2" style={{ width: "80%" }}>
          <TextField
            sx={{
              width: "50%",
              borderRadius: 8,
              marginRight: "2%",
            }}
            id="outlined-required"
            label="File Name"
            value={fileDataState.file_name}
            onChange={(event) =>
              onChangeHandler(event.target.value, "file_name")
            }
          />
          <TextField
            sx={{
              width: "80%",
              borderRadius: 8,
              marginRight: "1%",
            }}
            id="outlined-required"
            label="Description"
            value={fileDataState.description}
            onChange={(event) =>
              onChangeHandler(event.target.value, "description")
            }
          />
        </div>
        <Button
          sx={{
            height: "50%",
          }}
          variant="outlined"
          startIcon={<AddIcon />}
          className="color-black"
          disabled={
            isEmptyCheck(fileDataState.file) ||
            isEmptyCheck(fileDataState.description) ||
            isEmptyCheck(fileDataState.file_name)
          }
          onClick={addFieldHandler}
        >
          Add
        </Button>
      </div>
    }
      <div className="file-name px-2 mt-2 uploaded-file-style">
        <OverflowTip value={fileDataState?.file_display_name} />
      </div>
      <div className="my-2">
        {uploadedAttachments.length > 0 && selectedRole=== "maker" && (
          <div className="title">Uploaded Files</div>
        )}
        {uploadedAttachments?.map((ele, index) => {
          return (
            <>
              {ele?.file_name && (
                <div
                  style={{
                    width: "50%",
                    backgroundColor: "#E8E9EB",
                    borderRadius: "8px",
                    padding: "0.5%",
                    marginBottom: "1%",
                  }}
                >
                  <div className="d-flex m-2 justify-content-between">
                    <div
                      className="d-flex justify-content-center align-items-center file-name"
                      style={{ width: "94%", color: "black" }}
                    >
                      {
                        <>
                          <InsertDriveFileOutlinedIcon
                            sx={{
                              color: "#000",
                              opacity: "58%",
                              marginRight: "1%",
                              cursor: "pointer",
                            }}
                            height={20}
                            width={16}
                          />
                          <OverflowTip value={ele?.file_name} />
                        </>
                      }
                    </div>
                    <div>
                      {ele.file_name && selectedRole=== "maker" && (
                        <div className="d-flex">
                          <ClearIcon
                            sx={{
                              color: "#000",
                              opacity: "58%",
                              cursor: "pointer",
                              marginTop: "2%",
                            }}
                            onClick={() => deleteUploadedFileHandler(index)}
                          />
                        </div>
                      )}
                    </div>{" "}
                  </div>
                  {ele?.description && (
                    <div className="ms-2" style={{ color: "black" }}>
                      <span>Description: </span>
                      <span>{ele?.description}</span>
                    </div>
                  )}
                </div>
              )}
            </>
          );
        })}
      </div>
      <div className="my-2">
        {attachmentState.length > 0 && (
          <div className="title">Attachment Files</div>
        )}
        {attachmentState?.map((ele, index) => {
          return (
            <>
              {ele?.file_name && (
                <div
                  style={{
                    width: "50%",
                    backgroundColor: "#F5F5F5",
                    borderRadius: "8px",
                    padding: "0.5%",
                    marginBottom: "1%",
                  }}
                >
                  <div className="d-flex m-2 justify-content-between">
                    <div
                      className="d-flex justify-content-center align-items-center file-name"
                      style={{ width: "100%", color: "black" }}
                    >
                      {
                        <>
                          <InsertDriveFileOutlinedIcon
                            sx={{
                              color: "#000",
                              opacity: "58%",
                              marginRight: "1%",
                              cursor: "pointer",
                            }}
                            height={20}
                            width={16}
                          />
                          <OverflowTip value={ele?.file_name} />
                        </>
                      }
                    </div>
                    <div>
                      {ele.file_name && (
                        <div className="d-flex">
                          <ClearIcon
                            sx={{
                              color: "#000",
                              opacity: "58%",
                              cursor: "pointer",
                              marginTop: "2%",
                            }}
                            onClick={() => deleteHandler(index)}
                          />
                        </div>
                      )}
                    </div>{" "}
                  </div>
                  {ele?.description && (
                    <div className="ms-2" style={{ color: "black" }}>
                      <span>Description: </span>
                      <span>{ele?.description}</span>
                    </div>
                  )}
                </div>
              )}
            </>
          );
        })}
      </div>
    {
      selectedRole=== "maker" &&
        <div className="mt-2">
          <Button
            variant="contained"
            size="large"
            color="error"
            disabled={attachmentState.length === 0}
            onClick={submitHandler}
          >
            Add attachments
          </Button>
        </div>
    }
    { uploadedAttachments.length === 0 && selectedRole === "checker" && (
        <div
          className="d-flex flex-column justify-content-center align-items-center w-100"
          style={{
            color: "#1C2472",
          }}
        >
          No data found
        </div>
    )}
    </DetailsSection>
  );
};

export default CustomAttachment;
