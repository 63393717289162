import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Divider,
  InputAdornment,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Skeleton,
  Box,
  IconButton,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import { useTranslation } from "react-i18next";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import CommonSelect from "../dropDown/dropDown";

//components,
import * as ListManagementApi from "../../api/listManagementAPI";
import {
  setLoading,
  setSelectedCodesRedux,
  setLists,
} from "../../redux/searchCodesRedux";

//styles
import "./searchComponent.scss";
import {
  API_KEYS_FOR_SEARCH_COMPONENT,
  INPUTFIELD_FOR_SEARCH_COMPONENT,
  TITLE_FOR_SEARCH_COMPONENT,
} from "../../constants/ruleConstants";
import { toast } from "react-toastify";
const SearchComponent = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const searchListRef = useRef(null);

  const {
    onToggleButtonChange,
    width = "35%",
    activeButton,
    id,
    name,
    ruleType,
    setActiveButton,
  } = props;

  const buttons = [
    { title: "All", value: "all" },
    { title: "Selected", value: "selected" },
  ];

  const apiKeys = API_KEYS_FOR_SEARCH_COMPONENT[ruleType];

  const api = {
    3: "fetchListProviderCode",
    4: "fetchListReferenceCode",
    8: "searchEntity",
  };

  const isLoading = useSelector((state) => state.searchcodesdata.loading);
  const lists = useSelector((state) => state.searchcodesdata.lists);
  const selectedCodesRedux = useSelector(
    (state) => state.searchcodesdata.selectedCodesRedux
  );
  const countries = useSelector((state) => state.metadata.countries);
  const [jurisdictions, setJurisdictions] = useState([]);
  const roles = useSelector((state) => state.metadata.roles);
  const [clearData, setClearData] = useState(false);
  const [searchData, setSearchData] = useState("");
  const [apiData, setApiData] = useState(null);
  const [onLoad, setOnLoad] = useState(false);
  const [selectedJurisdiction, setSelectedJurisdiction] = useState("");

  let displayList = lists;

  switch (activeButton) {
    case "selected":
      displayList = [...selectedCodesRedux];
      break;
    default:
      displayList = [...lists];
      break;
  }

  useEffect(() => {
    setOnLoad(false);
    getApiBody();
    if (["2", "11", "12"].includes(ruleType)) {
      if (searchData === "") {
        getList();
      } else {
        setSearchData("");
      }
    }
  }, [ruleType]);

  useEffect(() => {
    if (onLoad === false && ["3", "4", "8"].includes(ruleType)) {
      getList();
      setOnLoad(true);
    }
    if (clearData === true && ["3", "4", "8"].includes(ruleType)) {
      getList();
      setClearData(false);
    }
  }, [apiData]);

  useEffect(() => {
    if (ruleType === "4") {
      let juri = countries.map((country) => {
        return { id: country.country_code, value: country.country_name };
      });
      const jurisdictionSortedList = juri.sort((a, b) =>
        a.value.localeCompare(b.value)
      );
      setJurisdictions([...jurisdictionSortedList]);
    }
  }, []);

  useEffect(() => {
    if (onLoad === false && ["2", "11", "12"].includes(ruleType)) {
      getList();
      setOnLoad(true);
    }
    if (clearData === true && ["2", "11", "12"].includes(ruleType)) {
      getList();
      setClearData(false);
    }
  }, [searchData]);

  const getApiBody = () => {
    switch (ruleType) {
      case "3":
        setApiData({ id: "", name: "" });
        break;
      case "4":
        setApiData({ id: "", name: "", jurisdiction: "" });
        break;
      case "8":
        setApiData({ id: "", name: "" });
        break;
      default:
    }
  };

  const handleSelect = (value) => {
    const listMap = new Map();

    const updatedList = lists.map((ele) => {
      const newEle = { ...ele };
      if (newEle[id] === value[id]) {
        newEle["selected"] = !ele.selected;
      }

      if (newEle.selected) {
        listMap.set(newEle[id], newEle);
      }
      return newEle;
    });

    selectedCodesRedux.forEach((ele) => {
      if (ele[id] !== value[id]) {
        listMap.set(ele[id], ele);
      } else {
        listMap.delete(value[id]);
      }
    });

    dispatch(setLists([...updatedList]));
    dispatch(setSelectedCodesRedux([...listMap.values()]));
  };

  const onChangeHandler = (event, index) => {
    if (event.target.value) {
      if (["3", "4", "8"].includes(ruleType)) {
        setApiData((prevState) => ({
          ...prevState,
          [apiKeys[index]]: event.target.value,
        }));
      } else {
        setSearchData(event.target.value.toLowerCase());
      }
    } else {
      clearDataHandler(index);
    }
  };

  const clearDataHandler = (index) => {
    if (["3", "4", "8"].includes(ruleType)) {
      setApiData((prevState) => ({
        ...prevState,
        [apiKeys[index]]: "",
      }));
    } else {
      setSearchData("");
    }
    setClearData(true);
  };

  const getList = async () => {
    let newLists = [];
    setActiveButton("all");
    dispatch(setLoading(true));
    switch (ruleType) {
      case "2": {
        newLists = [...countries];
        const updatedList = [];
        if (searchData !== "") {
          newLists.map((ele) => {
            if (ele[id].toLowerCase().match(searchData)) {
              updatedList.push(ele);
            }
          });
          newLists = [...updatedList];
        }
        break;
      }

      case "3":
      case "4":
      case "8": {
        const listResponse = async () => {
          const responseFromAPI = await ListManagementApi[api[ruleType]](
            apiData
          );
          if (!responseFromAPI.hasOwnProperty("data") || responseFromAPI?.data?.length === 0 ) {
            return;
          }

          if (responseFromAPI?.data?.length > 0) {
            const updatedList = responseFromAPI.data.map((ele) => {
              return { ...ele, selected: false };
            });

            newLists = [...updatedList];
          } else {
            toast.error(t(responseFromAPI?.response?.data?.code));
          }
        };
        await listResponse();
        break;
      }

      case "11": {
        newLists = countries.filter((ele) => ele.is_territory === false);
        const updatedList = [];
        if (searchData !== "") {
          newLists.map((ele) => {
            if (ele[id].toLowerCase().match(searchData)) {
              updatedList.push(ele);
            }
          });
          newLists = [...updatedList];
        }
        break;
      }

      case "12": {
        newLists = [...roles];
        const updatedList = [];
        if (searchData !== "") {
          newLists.map((ele) => {
            if (ele[id].toLowerCase().match(searchData)) {
              updatedList.push(ele);
            }
          });
          newLists = [...updatedList];
        }
        break;
      }
      default:
    }

    newLists = newLists.map((ele) => {
      if (
        selectedCodesRedux.find((selectedItem) => selectedItem[id] === ele[id])
      ) {
        return { ...ele, selected: true };
      }
      return ele;
    });

    dispatch(setLists(newLists));
    dispatch(setLoading(false));

    searchListRef.current.scrollTo({ top: "0", behavior: "smooth" });
  };

  const juriDropdownChange = (event, index) => {
    setSelectedJurisdiction(event.target.value);
    onChangeHandler(event, index);
  };

  const handleJurisdictionClear = () => {
    setSelectedJurisdiction(null);
    setApiData((prevState) => ({
      ...prevState,
      [apiKeys[1]]: "",
    }));
    setClearData(true);
  };

  return (
    <div className="search-container">
      <div className="d-flex justify-content-start m-b-4">
        {INPUTFIELD_FOR_SEARCH_COMPONENT[ruleType] &&
          INPUTFIELD_FOR_SEARCH_COMPONENT[ruleType].map((title, index) => {
            return ruleType === "4" && index === 1 ? (
              <CommonSelect
                customStyle={{
                  width: "25%",
                  marginRight: "1%",
                }}
                showLabel={false}
                value={selectedJurisdiction}
                title="Jurisdiction"
                inputId={"inputId"}
                labelId={"Jurisdiction-label-" + index}
                selectId={"select-id-" + index}
                label="Jurisdiction"
                options={jurisdictions}
                loading={isLoading}
                onChangeHandler={(event) => juriDropdownChange(event, index)}
                selectHeigth="40px"
                customSx={selectedJurisdiction}
                jurisdictionLabel={true}
                endAdornment={
                  selectedJurisdiction && (
                    <IconButton onClick={handleJurisdictionClear}>
                      <ClearIcon />
                    </IconButton>
                  )
                }
              />
            ) : (
              <TextField
                key={index + title}
                style={{
                  width: width,
                  borderRadius: 8,
                  padding: 0,
                  marginRight: "1.5% ",
                }}
                variant="outlined"
                size="small"
                id="input-with-icon-textfield"
                label={title}
                value={
                  ["3", "4", "8"].includes(ruleType)
                    ? apiData
                      ? apiData[apiKeys[index]]
                      : ""
                    : searchData
                    ? searchData
                    : ""
                }
                InputProps={{
                  endAdornment: (["3", "4", "8"].includes(ruleType)
                    ? apiData && apiData[apiKeys[index]]
                    : searchData) && (
                    <InputAdornment
                      position="end"
                      sx={{ marginLeft: "1%", cursor: "pointer" }}
                    >
                      <ClearIcon
                        onClick={() => {
                          clearDataHandler(index);
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                onChange={(event) => onChangeHandler(event, index)}
              ></TextField>
            );
          })}

        <Button variant="text" sx={{ color: "#FB665C" }} onClick={getList}>
          Search
        </Button>
      </div>
      <div className="add-rule-select-button d-flex justify-content-between mb-1 px-2">
        {TITLE_FOR_SEARCH_COMPONENT[ruleType] &&
          TITLE_FOR_SEARCH_COMPONENT[ruleType].map((title, index) => (
            <div
              key={index}
              className={` title ${index === 0 ? "m-l-2" : "margin-left-3"}`}
            >
              {title}
            </div>
          ))}
        <ToggleButtonGroup
          variant="text"
          color="primary"
          size="small"
          value={activeButton}
          exclusive
          onChange={onToggleButtonChange}
          aria-label="Platform"
          id="btn-group"
        >
          {buttons.map((button) => (
            <ToggleButton
              sx={{
                padding: "0px 20px",
              }}
              value={button.value}
              key={button.title}
            >
              {button.title}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </div>
      <Divider />
      <List
        ref={searchListRef}
        dense
        component="div"
        role="list"
        style={{ minHeight: "16vh", maxHeight: "16vh", overflow: "auto" }}
      >
        {isLoading ? (
          <Box id="blloader-addrule">
            <Skeleton className="skeleton-style" animation="wave" />
            <Skeleton className="skeleton-style" animation="wave" />
            <Skeleton className="skeleton-style" animation="wave" />
            <Skeleton className="skeleton-style" animation="wave" />
            <Skeleton className="skeleton-style" animation="wave" />
            <Skeleton className="skeleton-style" animation="wave" />
            <Skeleton className="skeleton-style" animation="wave" />
            <Skeleton className="skeleton-style" animation="wave" />
          </Box>
        ) : (
          <>
            {displayList.length > 0 ? (
              displayList.map((value) => {
                const labelId = `transfer-list-item-${value[id]}-label`;
                return (
                  <ListItem
                    key={value.id}
                    role="listitem"
                    button
                    sx={{
                      marginTop: "1%",
                      background: value?.selected && "#E7EEF9",
                      borderRadius: value?.selected && "4px",
                    }}
                    onClick={() => handleSelect(value)}
                  >
                    <ListItemText
                      sx={{
                        fontFamily: "Poppins",
                        fontWeight: "400",
                        maxWidth: ruleType === "12" ? "100%" : "40%",
                        fontSize: "15px",
                        lineHeight: "22.5px",
                        textAlign: "left",
                        color: "rgba(0, 0, 0, 0.87)",
                      }}
                      id={labelId}
                      primary={value[id]}
                    />
                    <ListItemText
                      sx={{
                        fontFamily: "Poppins",
                        fontWeight: "400",
                        fontSize: "15px",
                        maxWidth: "60%",
                        lineHeight: "22.5px",
                        textAlign: "left",
                        color: "rgba(0, 0, 0, 0.87)",
                      }}
                      id={labelId}
                      primary={value[name]}
                    />
                  </ListItem>
                );
              })
            ) : (
              <div
                className="d-flex flex-column justify-content-center align-items-center w-100 mt-3"
                style={{
                  minHeight: "10vh",
                  maxHeight: "10vh",
                  overflow: "auto",
                }}
              >
                No data found
              </div>
            )}
          </>
        )}
        <ListItem />
      </List>
    </div>
  );
};

export default SearchComponent;
