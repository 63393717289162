import { useEffect, useState } from "react";
import { Divider, TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ClearIcon from "@mui/icons-material/Clear";
import { useSelector, useDispatch } from "react-redux";

import Stack from "@mui/material/Stack";
//components
import DetailsSection from "../../detialsSection";
import {
  setEntityDetails,
  addEntityField,
} from "../../../../redux/enityDetailsRedux"; //style
import "./dobField.scss";
import DateCompoment from "../../../../components/dateComponent/DateComponent";
import NoDataFound from "../../../../components/noDataFound/noDataFound";

const DobField = (props) => {
  const dispatch = useDispatch();
  const dobs = useSelector((state) => state?.entityDetails?.standard_data?.dobs);
  const selectedRole = sessionStorage.getItem("selectedRole");

  const onChangeHandler = (value, key, index) => {
    const updatedDobs = [...dobs];
    updatedDobs[index] = {
      ...updatedDobs[index],
      [key]: value,
    };
    dispatch(setEntityDetails({ key: "dobs", data: [...updatedDobs] }));
  };

  const deleteHandler = (id) => {
    const updatedDobs = [...dobs];
    updatedDobs.splice(id, 1);
    dispatch(setEntityDetails({ key: "dobs", data: [...updatedDobs] }));
  };

  return (
    <div>
      <DetailsSection
        title={
          props.activeRadioButton === "individual"
            ? "Date of Birth"
            : "Date of Registration"
        }
        addFieldHandler={() => dispatch(addEntityField({ key: "dobs" }))}
        showAddIcon={selectedRole === "maker"}
        isDisabled={selectedRole === "checker"}
      >
        {dobs.length > 0 ?
          dobs.map((dob, index) => {
            return (
              <>
                <div
                  className={`dob-container w-60 ${index > 0 && "m-t-1"}`}
                  key={index}
                >
                  <DateCompoment
                    onChangeHandler={onChangeHandler}
                    dob={dob}
                    isDisabled={selectedRole === "checker"}
                    index={index}
                    labelId={
                      props.activeRadioButton === "individual" ? "dob" : "dor"
                    }
                  />
                  {dobs.length > 1 && selectedRole === "maker" && (
                    <ClearIcon
                      sx={{
                        color: "#000",
                        opacity: "58%",
                        cursor: "pointer",
                        marginTop: "4%",
                      }}
                      onClick={() => deleteHandler(index)}
                    />
                  )}
                </div>
                {index !== dobs.length - 1 && (
                  <Divider className="w-100 divider-style" />
                )}
              </>
            );
          })
          : <NoDataFound />
        }
      </DetailsSection>
    </div>
  );
};

export default DobField;
