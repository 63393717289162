import { createSlice } from "@reduxjs/toolkit";

export const searchCodesSlice = createSlice({
  name: "searchcodesdata",
  initialState: {
    codes: [],
    lists: [],
    selectedCodesRedux: [],
    loading: true,
  },
  reducers: {
    setSearchedCodes: (state, action) => {
      state.codes = action.payload;
    },
    setLists: (state, action) => {
      state.lists = action.payload;
    },
    setSelectedCodesRedux: (state, action) => {
      state.selectedCodesRedux = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    resetLists: (state) => {
      state.lists = [];
    },
    resetSelectedCodesRedux: (state) => {
      state.selectedCodesRedux = [];
    },
  },
});

export const {
  setSearchedCodes,
  setLists,
  setSelectedCodesRedux,
  setLoading,
  resetLists,
  resetSelectedCodesRedux,
} = searchCodesSlice.actions;

export default searchCodesSlice.reducer;
