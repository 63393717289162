import React from "react";

const TablePlaceholder = () => {
  const generatePlaceholders = () => {
    let output = [],
      repeate = 5;

    while (repeate--) {
      output.push(
        <div
          className="ph-item m-0 mb-3"
          key={`sp-${repeate}`}
          style={{ opacity: repeate * 0.35 }}
        >
          <div className="ph-col-12">
            <div className="ph-row">
              <div className="ph-col-2 big"></div>
              <div className="ph-col-2 empty"></div>
              <div className="ph-col-2"></div>
              <div className="ph-col-2 empty"></div>
              <div className="ph-col-2"></div>
              <div className="ph-col-2 big"></div>
              <div className="ph-col-1"></div>

              <div className="ph-col-2 big empty"></div>
              <div className="ph-col-2 empty"></div>
              <div className="ph-col-2"></div>
              <div className="ph-col-2 empty"></div>
              <div className="ph-col-2"></div>
              <div className="ph-col-2 big"></div>
              <div className="ph-col-1"></div>

              <div className="ph-col-2 big empty"></div>
              <div className="ph-col-2 empty"></div>
              <div className="ph-col-2"></div>
              <div className="ph-col-2 empty"></div>
              <div className="ph-col-2"></div>
              <div className="ph-col-2 big"></div>
              <div className="ph-col-1"></div>
            </div>
          </div>
        </div>
      );
    }

    return output;
  };

  return (
    <div className="w-100" data-testid="search-placeholder">
      {generatePlaceholders()}
    </div>
  );
};

export default TablePlaceholder;
