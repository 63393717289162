import { React, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, TextField } from "@mui/material";
import { toast } from "react-toastify";
import _ from "lodash";
import { blockListManagementAPI } from "../../../api";
import SpinnerBlocker from '../../../components/loader/spinnerBlocker/spinnerBlocker';
import { useTranslation } from "react-i18next";

// styles
import "./deleteIBLEntry.scss";
import { setUpdateList } from "../../../redux/enityDetailsRedux";
import EventBus from "../../../components/eventBus/eventBus";

const DeleteIBLEntry = (props) => {
  const dispatch = useDispatch();
  const { handleClose } = props;
  const { deleteEntry } = props;
  const userId = useSelector((state) => state.login?.user?.attributes?.email);
  const blocklistDetails = useSelector(
    (state) => state.metadata.selectedBlocklist
  );
  const [submitLoader, setSubmitLoader] = useState(false);
  const [comment, setComment] = useState("")
  const { t } = useTranslation();

  const submitDeleteHandler = async () => {
    setSubmitLoader(true)
    const api_action = deleteEntry[0].api_action;
    const updatedDeleteEntry = deleteEntry.map((ele) => {
      return {
        blocklist_entry_id: ele.blocklist_entry_id,
        blocklist_entry_version_no: ele.blocklist_entry_version_no,
      };
    });
    const body = {
      api_action: api_action,
      blocklist_id: blocklistDetails.blocklist_id,
      user_id: userId,
      client_id: 1,
      comment:comment,
      delete_entries: [...updatedDeleteEntry]};

    const response = await blockListManagementAPI.addUpdateEntity(body);

    if (response.status === 200 && response.data.data) {
      setSubmitLoader(false);
      dispatch(setUpdateList(true));
      EventBus.publish('reload_page', props.activeTab);

      if (api_action === 3) {
        toast.success("Blocklist draft entry deleted successfully!");
      } else {
        toast.success("Blocklist entry delete request raised successfully!");
      }
    } else {
      setSubmitLoader(false);
      if (response?.response?.data) {
        toast.error(t(response?.response?.data?.code));
      } else {
        toast.error(t(response.code));
      }
    }
    handleClose();
  };

  return (
    <>
      <div className="d-flex justify-content-center update-blocklist-container w-100 flex-column ">
        <p className="update-blocklist-title">
          Request for Deletion
        </p>
        <div className="content">
          <p className="label">
            Add Comments<span className="important-style">*</span>
          </p>
          <TextField
            fullWidth
            id="outlined-multiline-static"
            multiline
            rows={4}
            value={comment}
            onChange={(event) => {
              setComment(event.target.value);
            }}
            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 },}} 
            placeholder="Enter Description"
          />
          <div className="action-buttons-container">
            <div className="d-flex justify-content-end">
              <Button
                sx={{ marginRight: "1.6%" }}
                size="large"
                color="error"
                onClick={handleClose}
              >
                CANCEL
              </Button>
              <Button
                variant="contained"
                size="large"
                color="error"
                disabled={_.isEmpty(comment)}
                onClick={submitDeleteHandler}
              >
                SUBMIT
              </Button>
            </div>
          </div>
        </div>
      </div>
      {submitLoader && <SpinnerBlocker></SpinnerBlocker>}
    </>
  );
};

export default DeleteIBLEntry;
