const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const apiURL = {
  ruleGroupType: `${baseUrl}/dj/ruleRefs`,
  activeRules: `${baseUrl}/dj/suppressionRules`,
  pendingAddingDeletingRules: `${baseUrl}/dj/suppressionRulesMods`,
  descriptions: `${baseUrl}/dj/descriptions`,
  occupations: `${baseUrl}/dj/occupations`,
  regions: `${baseUrl}/dj/regions`,
  referenceCode: `${baseUrl}/dj/listOfLists`,
  providerCode: `${baseUrl}/dj/listProviders`,
  searchEntity: `${baseUrl}/dj/listOfEntities`,
  getScreenList: `${baseUrl}/internal/tenantScreenTypeList`,
  getWatchList: `${baseUrl}/internal/watchListForScreenType`,
  createBlocklist: `${baseUrl}/internal/blocklists`,
  createBlocklistFile: `${baseUrl}/internal/blocklistCreateFile`,
  getBlockList: `${baseUrl}/internal/blocklists`,
  getNameAndNationalTypeUrl: `${baseUrl}/internal/enumtypes`,
  getBlockListEntriesMod: `${baseUrl}/internal/blocklistEntriesMod`,
  getBlockListEntries: `${baseUrl}/internal/blocklistEntries`,
  entriesModHandler: `${baseUrl}/internal/blocklistEntriesModHandler`,
  getMasterBlocklistEntries: `${baseUrl}/internal/blocklistEntries`,
  getStageBlocklistEntries :`${baseUrl}/internal/blocklistEntriesMod`,
  getEntriesComment: `${baseUrl}/internal/blocklistEntriesComment`,
  approveRejectRules: `${baseUrl}/dj/suppressionRulesModsHandler`,
  getReconResult:`${baseUrl}/getDashboardData`,
  generatetCompareReport: `${baseUrl}/abinitioTriggerForReport`,
  viewComparisonReport:  `${baseUrl}/compareReport`,
  getAdditionalAttributes: `${baseUrl}/internal/blocklistAdditionalAttribute`,
  attachments: `${baseUrl}/internal/blocklistEntriesAttachment`
};
