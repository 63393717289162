import * as React from "react";
import format from "date-fns/format";
import _ from "lodash"

import "./iblRecordDetails.scss";

const IblR = (props) => {
  const { recordDetails, recordComment, isExisting, isModified } = props;

  return (
    <div className="record-details-container d-flex w-100 flex-column">
      {isExisting && (
        <h className="w-100 heading-record p-2">Existing Record</h>
      )}
      {isModified && (
        <h className="w-100 heading-record p-2">Modified Record</h>
      )}
      <div className="p-2">
        <div className="d-flex w-100">
          <span className="field-heading mt-2">Name</span>
        </div>
        {recordDetails?.standard_data?.names?.map((name, index) => (
          <>
            <div className="d-flex w-100 ms-2 mt-3 align-items-center">
              <span className="serial-number">{`${++index}.`}</span>
              <span className="avatar-primary d-flex justify-content-center align-items-center ms-2">
                <span className="name-initial">{`${
                  name?.first_name?.slice(0, 1) +
                  name?.middle_name?.slice(0, 1) +
                  name?.last_name?.slice(0, 1)
                }`}</span>
              </span>
              <span className="ms-2 text">{name?.full_name ? name?.full_name : name.first_name}</span>
            </div>
          </>
        ))}
        <div className="d-flex w-100 mt-3">
          <div className="d-flex w-50">
            <span className="field-heading">National ID</span>
          </div>
          <div className="d-flex w-50">
            <span className="field-heading">Date Of Birth</span>
          </div>
        </div>
        <div className="d-flex w-100 mt-3">
          <div className="d-flex flex-column ms-2 w-50">
            {recordDetails?.standard_data?.national_ids?.map(
              (nationalId, index) => (
                <>
                  <div className="d-flex w-100">
                    <span className="serial-number">{`${++index}.`}</span>
                    <span className="ms-3 text-heading">
                      {nationalId.enum_value_name}
                    </span>
                  </div>
                  <span className="d-flex w-100 ms-3 mt-2 text">
                    {nationalId.id_value}
                  </span>
                </>
              )
            )}
          </div>
          <div className="d-flex w-50">
            <div className="d-flex flex-column ms-2">
              <div className="d-flex w-100 ms-1">
                {recordDetails?.standard_data?.dobs?.map((dob, index) => (
                  <>
                    <span className="serial-number">{`${++index}.`}</span>

                    <span className="ms-3 dob-value">
                      {format(new Date(dob.date), "dd LLL yyyy")}
                    </span>
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex w-100 mt-3">
          <span className="field-heading">Address</span>
        </div>
        {recordDetails?.standard_data?.addresses?.map((address, index) => (
          <>
            <div className="d-flex flex-column ms-2 mt-2 w-100">
              <div className="d-flex w-100">
                <span className="serial-number">{`${++index}.`}</span>
                <span className="w-25 text-heading ms-1">Country</span>
                <span className="d-flex w-75 text-heading ms-1">
                  Full Address
                </span>
              </div>
              <div className="d-flex w-100">
                <span className="d-flex w-25 ms-3 mt-1 text">
                  {address.address_country}
                </span>
                <span className="d-flex w-75 text">
                  {`${
                    address.address_line +
                    ", " +
                    address.address_city +
                    ", " +
                    address.address_zipcode
                  }`}
                </span>
              </div>
            </div>
          </>
        ))}
        <div className="d-flex w-100 field-heading mt-2">
          Existing Validity Period
        </div>
        <div className="d-flex w-100 ms-2">
          <div className="d-flex w-50 text-heading mt-2">Active From</div>
          <div className="d-flex w-50 text-heading mt-2">Active To</div>
        </div>
        <div className="d-flex w-100 ms-2 mt-1">
          <div className="d-flex w-50 text">
            {format(
              new Date(
                recordDetails?.proposed_start_dtm?.slice(0, 10) || new Date()
              ),
              "dd LLL yyyy"
            )}
          </div>
          <div className="d-flex w-50 text">
            {format(
              new Date(
                recordDetails?.proposed_end_dtm?.slice(0, 10) || new Date()
              ),
              "dd LLL yyyy"
            )}
          </div>
        </div>
        {!_.isEmpty(recordComment) && (
          <>
            <div className="d-flex w-100 field-heading mt-2">Comments</div>
            <div className="d-flex w-100 ms-2 text mt-1">{recordComment}</div>
          </>
        )}
      </div>
    </div>
  );
};

export default IblR;
