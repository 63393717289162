import { useEffect, useState, useRef } from "react";
import { TextField, Divider } from "@mui/material";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import ClearIcon from "@mui/icons-material/Clear";

//components, constants

import DetailsSection from "../../features/detailsSection/detialsSection";
import { setAdditionalInformation } from "../../redux/aditionalInformationRedux";
import { camelToUnderscore } from "../../constants/additionalInformationTabUtility";
import SpinnerBlocker from "../loader/spinnerBlocker/spinnerBlocker";
import { isEmptyCheck } from "../../constants/utility";
import NoDataFound from "../noDataFound/noDataFound";

const CustomDropdowm = (props) => {
  const { attributeDetails, isDisabled } = props;
  const selectedRole = sessionStorage.getItem("selectedRole");

  const [dropDownState, setDropdownState] = useState(selectedRole === "maker" ? [
    {
      enum_type_id: "",
      enum_value_id: "",
      enum_value_name: "",
      code: "",
    },
  ]:  null );

  const additionalInformationData = useSelector(
    (state) =>
      state.aditionalInformationDetails[
        camelToUnderscore(attributeDetails.enum_type_name)
      ]
  );
  
  const entityData = useSelector((state) => state.entityData )
  
  const countries = useSelector((state) => state.entityDetails.countries);

  const [countryTypeOption, setCountryTypeOption] = useState([]);
  const [countryCodeOption, setCountryCodeOption] = useState([]);

  let timer = useRef(null);
  const dispatch = useDispatch();



  useEffect(() => {
    if (attributeDetails?.enum_values) {
      const countryTypeList = attributeDetails.enum_values.map((ele) => ({
        id: ele.enum_value_id,
        value: ele.enum_value_name,
      }));
      setCountryTypeOption([...countryTypeList]);
      setCountryCodeOption([...countries]);
    }
  }, [attributeDetails]);

  useEffect(() => {
    if (entityData.entityData?.action === "edit" && additionalInformationData?.length > 0) {
      setDropdownState([...additionalInformationData]);
    }

  }, []);

  const addFieldHandler = () => {
    setDropdownState((prevState) => [
      ...prevState,
      {
        enum_type_id: "",
        enum_value_id: "",
        enum_value_name: "",
        code: "",
      },
    ]);
  };

  const onChangeHandler = (value, key, index) => {
    const updatedDDropdownState = [...dropDownState];
    updatedDDropdownState[index] = {
      ...updatedDDropdownState[index],
      enum_type_id: attributeDetails.enum_type_id,
      [key]: value,
    };
    if (key === "enum_value_name") {
      updatedDDropdownState[index] = {
        ...updatedDDropdownState[index],
        enum_value_id: value,
      };
    }
    setDropdownState([...updatedDDropdownState]);
  };

  const deleteHandler = (id) => {
    const updatedValues = [...dropDownState];
    updatedValues.splice(id, 1);
    setDropdownState([...updatedValues]);
  };

  useEffect(() => {
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      const updatedDropDownState = [];
      dropDownState?.map((ele) => {
        if (!isEmptyCheck(ele) && !isEmptyCheck(ele.enum_type_id) ) {
          updatedDropDownState.push(ele);
        }
      });
      dispatch(
        setAdditionalInformation({
          key: camelToUnderscore(attributeDetails.enum_type_name),
          data: [...updatedDropDownState],
        })
      );
    }, [500]);
  }, [dropDownState]);

  return (
    <DetailsSection
      title={attributeDetails?.default_label_name}
      showAddIcon={attributeDetails.accept_multi_value && selectedRole !== "checker"}
      addFieldHandler={addFieldHandler}
    >
    {dropDownState?.map((ele, index) => {
      return (
        <>
          <div className="d-flex mt-3 w-100" key={index}>
            <Autocomplete
              disablePortal
              value={ele.enum_value_name}
              id={"additionalfield-country-type" + index}
              options={countryTypeOption}
              getOptionLabel={(option) =>
                option?.value ? option?.value : ""
              }
              sx={{ width: "38%", marginRight: "1%" }}
              renderInput={(params) => (
                <TextField {...params} label="Country Type" />
              )}
              onChange={(event, newValue) => {
                onChangeHandler(newValue, "enum_value_name", index);
              }}
              readOnly={selectedRole === "checker"}

            />
            <Autocomplete
              disablePortal
              value={ele.code}
              id={"additionalfield-country-code" + index}
              getOptionLabel={(option) =>
                option?.value ? option?.value : ""
              }
              options={countryCodeOption}
              sx={{ width: "38%", marginRight: "1%" }}
              renderInput={(params) => (
                <TextField {...params} label="Country Code" />
              )}
              onChange={(event, newValue) => {
                onChangeHandler(newValue, "code", index);
              }}
              readOnly={selectedRole === "checker"}
            />
            {dropDownState.length > 1 && selectedRole !== "checker" && (
              <ClearIcon
                sx={{
                  color: "#000",
                  opacity: "58%",
                  cursor: "pointer",
                  marginTop: "2%",
                }}
                onClick={() => deleteHandler(index)}
              />
            )}
          </div>
          {index !== dropDownState.length - 1 && (
            <Divider className="w-100 divider-style m-t-1" />
          )}
        </>
      );
    })}
    { isEmptyCheck(dropDownState) && (
      <NoDataFound />
      )}
    </DetailsSection>
  );
};

export default CustomDropdowm;
