import * as React from "react";
import { blockListManagementAPI } from "../../api";
import { Button, Pagination } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./IBLDetails.scss";
import Tabs from "../../components/tabs/tabs";
import ListOfEntries from "../../components/table/listOfEntries";
import { Add } from "@mui/icons-material";
import UpdateBlockList from "./updateBlockList";
import RightSidePopup from "../../components/modal/modal";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import BlocklistLoader from "../../components/loader/blocklistLoader/blocklistLoader";
import { setSelectedBlocklist } from "../../redux/metadataRedux";
import { ACTIVE_TAB, IBL_ENTRY_STATUS } from "../../constants/iblConstants";
import NoDataPresent from "../../components/noDataPresent/noDataPresent";
import IBLDetailsComponent from "./iblDetailsComponent/ibldetailsComponent";
import { resetEntityDetails } from "../../redux/enityDetailsRedux";
import SearchBox from "../../components/searchBox/searchBox";
import { resetEntityData, setEntityData } from "../../redux/entityDataRedux";
import EventBus from "../../components/eventBus/eventBus";

const IBLDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();

  const [isEntryAvailable, setEntryAvailable] = React.useState(false);
  const [isDataLoading, setIsDataLoading] = React.useState(true);
  const [blocklistDetails, setBlocklistDetails] = React.useState();
  const [blocklistEntries, setBlocklistEntries] = React.useState({
    approved: null,
    pending: null,
    drafts: null,
    approvedPageCount: null,
    pendingPageCount: null,
    draftPageCount: null,
  });
  const [activeTab, setActiveTab] = React.useState(state?.presentActiveTab ? ACTIVE_TAB[state?.presentActiveTab] : "approved");
  const [openModal, setOpenModal] = React.useState(false);
  const tabs = [
    { title: "APPROVED", value: "approved" },
    { title: "PENDING APPROVAL", value: "pending" },
    { title: "DRAFT", value: "draft" },
  ];
  const selectedRole = sessionStorage.getItem('selectedRole');
  const selectedBlockList = sessionStorage.getItem('selectedBlockList');
  const [activePage, setActivePage] = React.useState(1);
  const [searchAttributes, setSearchAttributes] = React.useState({
    name: null,
    nationalId: null
  });
  const isUpdateRequired = useSelector((state) => state.entityDetails.updateList);

  React.useEffect(() =>{
    const blocklistDetails = JSON.parse(
      sessionStorage.getItem("selectedBlocklistDetails")
    );
    if(blocklistDetails){
      setBlocklistDetails({...blocklistDetails})
    }
  },[])

  const onChangeHandler = (event, newActiveTab) => {
    if (newActiveTab !== null) setActiveTab(newActiveTab);
    if (newActiveTab === "approved") getApprovedEntries();
    if (newActiveTab === "pending") getPendingEntries();
    if (newActiveTab === "draft") getDraftEntries();
  };

  const onSearch = (nameVal, nationalIdVal) => {
    setSearchAttributes((prevState) => ({
      ...prevState,
      name: nameVal,
      nationalId: nationalIdVal
    }));
  }

  const getApprovedEntries = (event,value) => {
    setIsDataLoading(true);
    setActivePage(value);
    const asyncResponse = async () => {
      try {
        const responseFromEntriesAPI =
          await blockListManagementAPI.fetchIBListEntries({
            blocklistId: selectedBlockList,
            offset: value ? (value - 1) * 10 : null,
            limit: 10,
            name: searchAttributes.name,
            national_id: searchAttributes.nationalId
          });
        setBlocklistEntries((prevState) => ({
          ...prevState,
          approved: responseFromEntriesAPI.data,
          approvedPageCount: Math.ceil(
            responseFromEntriesAPI.totalRecords / 10
          )
        }));
        setIsDataLoading(false);
        setEntryAvailable(responseFromEntriesAPI.data.length);
      } catch (e) {
        setBlocklistEntries((prevState) => ({
          ...prevState,
          approved: null,
          approvedPageCount: null
        }));
        setEntryAvailable(false);
        toast.error("Error fetching data");
      } finally {
        setIsDataLoading(false);
      }
    };
    asyncResponse();
  };

  const getPendingEntries = (event, value) => {
    setActivePage(value);
    setIsDataLoading(true);
    const asyncResponse = async () => {
      try {
        const responseFromEntriesAPI =
          await blockListManagementAPI.fetchIBListEntriesMods({
            blocklistId: selectedBlockList,
            status: IBL_ENTRY_STATUS.PENDING,
            offset: value ?(value - 1) * 10 : null,
            limit: 10,
            name: searchAttributes.name,
            national_id: searchAttributes.nationalId
          });
        setBlocklistEntries((prevState) => ({
          ...prevState,
          pending: responseFromEntriesAPI.data,
          pendingPageCount: Math.ceil(
            responseFromEntriesAPI.totalRecords / 10
          )
        }));
        setIsDataLoading(false);
        setEntryAvailable(responseFromEntriesAPI.data.length);
      } catch (e) {
        setBlocklistEntries((prevState) => ({
          ...prevState,
          pending: null,
          pendingPageCount: null
        }));
        setEntryAvailable(false);
        toast.error("Error fetching data");
      } finally {
        setIsDataLoading(false);
      }
    };
    asyncResponse();
  };

  const getDraftEntries = (event, value) => {
    setActivePage(value);
    setIsDataLoading(true);
    const asyncResponse = async () => {
      try {
        const responseFromEntriesAPI =
          await blockListManagementAPI.fetchIBListEntriesMods({
            blocklistId: selectedBlockList,
            status: IBL_ENTRY_STATUS.DRAFT,
            offset: value ? (value - 1) * 10: null,
            limit: 10,
            name: searchAttributes.name,
            national_id: searchAttributes.nationalId
          });
        setBlocklistEntries((prevState) => ({
          ...prevState,
          drafts: responseFromEntriesAPI.data,
          draftPageCount: Math.ceil(responseFromEntriesAPI.totalRecords / 10)
        }));
        setIsDataLoading(false);
        setEntryAvailable(responseFromEntriesAPI.data.length);
      } catch (e) {
        setBlocklistEntries((prevState) => ({
          ...prevState,
          drafts: null,
          draftPageCount: null
        }));
        setEntryAvailable(null);
        toast.error("Error fetching data");
      } finally {
        setIsDataLoading(false);
      }
    };
    asyncResponse();
  };

  const handleClose = () => setOpenModal(false);

  const handleOpen = () => setOpenModal(true);

  const navigateToIBLDashboard = (event) => {
    navigate("/ibldashboard");
  };

  const navigateToAddEntry = () => {
    dispatch(resetEntityDetails())
    dispatch(resetEntityData())
    dispatch(setEntityData({ id: blocklistDetails ? blocklistDetails.blocklist_id : state.query,  action: "add" }));
    navigate("/addentity", {
      state: { id: blocklistDetails ? blocklistDetails.blocklist_id : state.query,  action: "add" },
    });
  };

  React.useEffect(() => {
    setIsDataLoading(true);
    const asyncResponse = async () => {
      try {
        const responseFromAPI =
          await blockListManagementAPI.fetchIBListDetails({
            blocklistId: selectedBlockList,
          });
        setBlocklistDetails(responseFromAPI.data[0]);
        dispatch(setSelectedBlocklist(responseFromAPI.data[0]));
        sessionStorage.setItem('selectedBlocklistDetails',JSON.stringify(responseFromAPI.data[0]));
        if (selectedRole === "maker") {
          getApprovedEntries(1);
        } else {
          setActiveTab("pending");
          getPendingEntries(1);
        }
      } catch (e) {
        toast.error("Error fetching data");
        setBlocklistDetails(null);
      } finally {
        // setIsDataLoading(false);
      }
    };
    if(state?.presentActiveTab) return;
    asyncResponse();
  }, []);

  React.useEffect(() => {
    if(searchAttributes?.name || searchAttributes?.nationalId){
      if (activeTab === "approved") getApprovedEntries();
      if (activeTab === "pending") getPendingEntries();
      if (activeTab === "draft") getDraftEntries();
    }
  }, [searchAttributes]);

  React.useEffect(() => {
    if (state?.presentActiveTab === "save-as-draft") {
      onChangeHandler(null, "draft");
    }
    if (state?.presentActiveTab === "submit") {
      onChangeHandler(null, "pending");

    }
  }, [state?.presentActiveTab]);

  React.useEffect(() => {
    EventBus.subscribe("reload_page", (changeTab) => {
      if (changeTab) {
        onChangeHandler(null, changeTab);
        return;
      }
    });
  }, []);

  return (
    <>
      {(blocklistDetails) && (
        <div
          className="d-flex flex-row mt-3 px-1 py-5 w-100"
          id="blocklist-details"
        >
          <Button
            variant="text"
            startIcon={<ArrowBackIcon />}
            className="fcc-color-grey me-1 w-5"
            onClick={navigateToIBLDashboard}
          >
            BACK
          </Button>
          <IBLDetailsComponent selectedRole={selectedRole} blocklistDetails={blocklistDetails} handleOpen={handleOpen} ></IBLDetailsComponent>
          {selectedRole === "maker" && (
            <RightSidePopup open={openModal} width="26%">
              <UpdateBlockList
                handleClose={handleClose}
                blocklistId={blocklistDetails?.blocklist_id}
                blocklistName={blocklistDetails?.blocklist_name}
                state={blocklistDetails?.state_id}
              />
            </RightSidePopup>
          )}
        </div>
      )}
      {selectedRole === "maker" && (
        <div className="d-flex flex-row w-100 justify-content-between">
          <Tabs
            tabs={tabs}
            onChangeHandler={onChangeHandler}
            value={activeTab}
          />
          <div className="d-flex flex-column align-items-end">
            <Button
              className="add-rule-btn"
              variant="outlined"
              size="medium"
              onClick={navigateToAddEntry}
              startIcon={<Add />}
            >
              Add Record
            </Button>
          </div>
        </div>
      )}
      <SearchBox onSearch={onSearch}/>
      {isDataLoading ? (
        <BlocklistLoader></BlocklistLoader>
      ) : isEntryAvailable ? (
        <div className="d-flex flex-column w-100 mt-3" id="list-of-entries">
          {activeTab === "approved" && (
            <>
              <ListOfEntries
                listFor={activeTab}
                rows={blocklistEntries.approved}
                handler={getApprovedEntries}
                pageCount={blocklistEntries.approvedPageCount}
              />
              {blocklistEntries.approvedPageCount > 1 && (
                <div className="d-flex justify-content-center mt-2 mb-5">
                  <Pagination
                    count={blocklistEntries?.approvedPageCount}
                    shape="rounded"
                    onChange={getApprovedEntries}
                    page={activePage}
                  />
                </div>
              )}
            </>
          )}
          {activeTab === "pending" && (
            <>
              <ListOfEntries
                listFor={activeTab}
                rows={blocklistEntries?.pending}
                handler={getPendingEntries}
                pageCount={blocklistEntries?.pendingPageCount}
              />
              {blocklistEntries?.pendingPageCount > 1 && (
                <div className="d-flex justify-content-center mt-2 mb-5">
                  <Pagination
                    count={blocklistEntries?.pendingPageCount}
                    shape="rounded"
                    onChange={getPendingEntries}
                    page={activePage}
                  />
                </div>
              )}
            </>
          )}
          {activeTab === "draft" && (
            <>
              <ListOfEntries
                listFor={activeTab}
                rows={blocklistEntries?.drafts}
                handler={getDraftEntries}
                pageCount={blocklistEntries?.draftPageCount}
              />
              {blocklistEntries?.draftPageCount > 1 && (
                <div className="d-flex justify-content-center mt-2 mb-5">
                  <Pagination
                    count={blocklistEntries?.draftPageCount}
                    shape="rounded"
                    onChange={getDraftEntries}
                    page={activePage}
                  />
                </div>
              )}
            </>
          )}
        </div>
      ) : (<NoDataPresent message="No entry available in blocklist"></NoDataPresent>)}
    </>
  );
};

export default IBLDetails;
