import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

//components, constants
import DateCompoment from "../../components/dateComponent/DateComponent";
import DetailsSection from "../../features/detailsSection/detialsSection";
import { setAdditionalInformation } from "../../redux/aditionalInformationRedux";
import { camelToUnderscore } from "../../constants/additionalInformationTabUtility";
import { isEmptyCheck } from "../../constants/utility";
import NoDataFound from "../../components/noDataFound/noDataFound";

const ComplexRadioDateSection = (props) => {
  const { attributeDetails } = props;
  const dispatch = useDispatch();
  const { state } = useLocation();
  const selectedRole = sessionStorage.getItem("selectedRole");

  const [activeRadioButton, setActiveRadioButton] = useState(null);
  const [complexRadioDateState, setComplexRadioDateState] = useState(
    selectedRole === "maker"
      ? {
          enum_type_id: "",
          is_true: null,
          day: null,
          month: null,
          year: null,
        }
      : null
  );

  const additionalInformationData = useSelector(
    (state) =>
      state.aditionalInformationDetails[
        camelToUnderscore(attributeDetails.enum_type_name)
      ]
  );
  const entityData = useSelector((state) => state.entityData);

  let timer = useRef(null);

  useEffect(() => {
    if (
      entityData.entityData?.action === "edit" &&
      additionalInformationData &&
      Object.keys(additionalInformationData).length > 0
    ) {
      setActiveRadioButton(
        isEmptyCheck(additionalInformationData?.is_true)
          ? null
          : additionalInformationData?.is_true
          ? "yes"
          : "no"
      );
      setComplexRadioDateState({
        ...additionalInformationData,
      });
    }
  }, []);

  const onChangeHandler = (value, keyName) => {
    const updatedRadioDateDetails = { ...complexRadioDateState };
    if (keyName === "is_true") {
      setActiveRadioButton(value);
      updatedRadioDateDetails[keyName] = isEmptyCheck(value)
        ? null
        : value === "yes";
    } else {
      updatedRadioDateDetails[keyName] = value;
    }
    setComplexRadioDateState({ ...updatedRadioDateDetails });
  };

  useEffect(() => {
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      dispatch(
        setAdditionalInformation({
          key: camelToUnderscore(attributeDetails.enum_type_name),
          data: !isEmptyCheck(complexRadioDateState?.is_true)
            ? {
                enum_type_id: attributeDetails?.enum_type_id,
                is_true: complexRadioDateState?.is_true,
                day: complexRadioDateState?.day
                  ? complexRadioDateState?.day
                  : null,
                month: complexRadioDateState?.month
                  ? complexRadioDateState?.month
                  : null,
                year: complexRadioDateState?.year
                  ? complexRadioDateState?.year
                  : null,
              }
            : null,
        })
      );
    }, [500]);
  }, [complexRadioDateState]);

  return (
    <>
      {attributeDetails && (
        <DetailsSection
          title={attributeDetails?.default_label_name}
          showAddIcon={attributeDetails.accept_multi_value}
        >
          {" "}
          {complexRadioDateState && (
            <FormControl>
              <RadioGroup
                onChange={(event) =>
                  onChangeHandler(event.target.value, "is_true")
                }
                row
                aria-labelledby="row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={activeRadioButton}
              >
                <FormControlLabel
                  value={"no"}
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          color: "#1976d2!important",
                        },
                      }}
                    />
                  }
                  style={{ color: "black" }}
                  label="NO"
                  disabled={selectedRole === "checker"}
                />
                <FormControlLabel
                  value={"yes"}
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          color: "#1976d2",
                        },
                      }}
                    />
                  }
                  label="YES"
                  style={{ color: "black" }}
                  disabled={selectedRole === "checker"}
                />
              </RadioGroup>
            </FormControl>
          )}
          {complexRadioDateState?.is_true && (
            <DateCompoment
              onChangeHandler={onChangeHandler}
              dob={{
                day: complexRadioDateState?.day
                  ? complexRadioDateState?.day
                  : null,
                month: complexRadioDateState?.month
                  ? complexRadioDateState?.month
                  : null,
                year: complexRadioDateState?.year
                  ? complexRadioDateState?.year
                  : null,
              }}
              isDisabled={selectedRole === "checker"}
            />
          )}
          {isEmptyCheck(complexRadioDateState) && <NoDataFound />}
        </DetailsSection>
      )}
    </>
  );
};

export default ComplexRadioDateSection;
