import { useEffect, useState } from "react";
import { Divider, TextField } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useSelector, useDispatch } from "react-redux";

//components
import DetailsSection from "../../detialsSection";
import CommonSelect from "../../../../components/dropDown/dropDown";
import {
  setEntityDetails,
  addEntityField,
} from "../../../../redux/enityDetailsRedux";

//style
import "./nationalIdField.scss";
import NoDataFound from "../../../../components/noDataFound/noDataFound";

const NationalIdField = (props) => {
  const { loading = true, isDisabled } = props;
  const dispatch = useDispatch();

  const nationalIds = useSelector((state) => state?.entityDetails?.standard_data?.national_ids);
  const nationalIDTypesList = useSelector((state) => state?.entityDetails?.nationalIDTypesList);
  const selectedRole = sessionStorage.getItem("selectedRole");

  const [nationalIdTypeOptions, setNationalIdTypeOptions] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(true);

  useEffect(() => {
    setIsDataLoading(false);
  }, [nationalIdTypeOptions]);

  useEffect(() => {
    const numberIdList = nationalIDTypesList?.enum_values;
    const updatedNumberIdList = numberIdList
      ? numberIdList.map((name) => {
          return { id: name.enum_value_id, value: name.enum_value_name };
        })
      : [];
    setNationalIdTypeOptions(updatedNumberIdList);
    setIsDataLoading(false);
  }, [nationalIDTypesList]);

  const onChangeHandler = (event, key, index) => {
    let updatedNationalIds = [...nationalIds];
    if (key === "enum_value_id") {
      const enum_value_name = nationalIdTypeOptions.filter(
        (nationalIdType) => nationalIdType.id === event.target.value
      )[0].value;
      updatedNationalIds[index] = {
        ...updatedNationalIds[index],
        enum_value_name,
        enum_type_id: nationalIDTypesList.enum_type_id,
      };
    }
    updatedNationalIds[index] = {
      ...updatedNationalIds[index],
      [key]: event.target.value,
    };
    dispatch(
      setEntityDetails({ key: "national_ids", data: [...updatedNationalIds] })
    );
  };

  const deleteHandler = (id) => {
    const updatedNationalIds = [...nationalIds];
    updatedNationalIds.splice(id, 1);
    dispatch(
      setEntityDetails({ key: "national_ids", data: [...updatedNationalIds] })
    );
  };

  return (
    <div>
      <DetailsSection
        title="National ID"
        addFieldHandler={() =>
          dispatch(addEntityField({ key: "national_ids" }))
        }
        showAddIcon={selectedRole === "maker"}
        isDisabled={selectedRole === "checker"}
      >
        {nationalIds.length > 0 ? nationalIds.map((name, index) => {
          return (
            <>
              <div
                className={`nationalId-container ${index > 0 && "m-t-1"}`}
                key={index}
              >
                <CommonSelect
                  customStyle={{
                    width: "16%",
                    height: "25%",
                    marginRight: "1%",
                  }}
                  menuWidth="8%"
                  showLabel={false}
                  value={name.enum_value_id}
                  title="ID Type"
                  inputId={"inputId"}
                  labelId={"Id Type-label-" + index}
                  selectId={"select-id-" + index}
                  label="Name"
                  options={nationalIdTypeOptions.length > 0 ? nationalIdTypeOptions : []}
                  onChangeHandler={(event) =>
                    onChangeHandler(event, "enum_value_id", index)
                  }
                  loading={isDataLoading}
                  readOnly={selectedRole === "checker"}
                />
                <TextField
                  style={{
                    width: "16%",
                    borderRadius: 8,
                    marginRight: "1%",
                  }}
                  value={name?.id_value}
                  variant={"outlined"}
                  id="input-with-icon-textfield"
                  label="ID Number"
                  onChange={(event) =>
                    onChangeHandler(event, "id_value", index)
                  }
                  inputProps={{ readOnly: selectedRole === "checker" }}
                />
                {nationalIds.length > 1 && selectedRole === "maker" && (
                  <ClearIcon
                    sx={{
                      color: "#000",
                      opacity: "58%",
                      cursor: "pointer",
                      marginTop: "1%",
                    }}
                    onClick={() => deleteHandler(index)}
                  />
                )}
              </div>
              {index !== nationalIds.length - 1 && (
                <Divider className="w-100 divider-style" />
              )}
            </>
          );
        })
      :
      <NoDataFound />
      }
      </DetailsSection>
    </div>
  );
};

export default NationalIdField;
