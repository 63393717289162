import { useEffect, useState } from "react";
import { Divider, TextField } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useSelector, useDispatch } from "react-redux";
//components
import DetailsSection from "../../detialsSection";
import CommonSelect from "../../../../components/dropDown/dropDown";
import { IBL_NAME_TITLE } from "../../../../constants/iblConstants";
import {
  setEntityDetails,
  addEntityField,
} from "../../../../redux/enityDetailsRedux";

//style
import "./nameField.scss";

const NameField = (props) => {
  const { loading = true } = props;
  const dispatch = useDispatch();

  const [isDataLoading, setIsDataLoading] = useState(true);
  const names = useSelector((state) => state?.entityDetails?.standard_data?.names);
  const nameDetailsList = useSelector((state) => state?.entityDetails?.nameDetailsList);
  const selectedRole = sessionStorage.getItem("selectedRole");

  const [namesTypeOptions, setNamesTypeOptions] = useState([]);

  useEffect(() => {
    const nameList = nameDetailsList?.enum_values;
    const updatedNameList = nameList
      ? nameList.map((name) => {
          return { id: name.enum_value_id, value: name.enum_value_name };
        })
      : [];
    setNamesTypeOptions(updatedNameList);
    setIsDataLoading(false);
  }, [nameDetailsList]);

  useEffect(() => {
    setIsDataLoading(false);
  }, [namesTypeOptions]);

  const onChangeHandler = (event, key, index) => {
    let updatedNames = [...names];

    if (key === "enum_value_id") {
      const enum_value_name = namesTypeOptions.filter(
        (nameType) => nameType.id === event.target.value
      )[0].value;
      updatedNames[index] = {
        ...updatedNames[index],
        enum_value_name,
        enum_type_id: nameDetailsList.enum_type_id,
      };
    }
    updatedNames[index] = {
      ...updatedNames[index],
      [key]: event.target.value,
    };
    dispatch(setEntityDetails({ key: "names", data: [...updatedNames] }));
  };

  const deleteHandler = (id) => {
    const updatedNames = [...names];
    updatedNames.splice(id, 1);
    dispatch(setEntityDetails({ key: "names", data: [...updatedNames] }));
  };

  return (
    <div>
      <DetailsSection
        title="Name"
        helperText="Primary name is mandatory"
        addFieldHandler={() => dispatch(addEntityField({ key: "names" }))}
        showAddIcon={selectedRole === "maker"}
        isDisabled={selectedRole === "checker"}
      >
        {names &&
          names.map((name, index) => {
            return (
              <>
                <div
                  className={`name-container ${index > 0 && "m-t-1"}`}
                  key={index}
                >
                  <div className="d-flex flex-column w-100">
                    <div className="d-flex w-100 mb-3">
                      <CommonSelect
                        customStyle={{
                          width: "14%",
                          height: "25%",
                          marginRight: "1%",
                        }}
                        showLabel={false}
                        value={name.enum_value_id}
                        title="Name Type"
                        inputId={"inputId"}
                        labelId={"name-label-" + index}
                        selectId={"select-id-" + index}
                        label="Name Type"
                        options={namesTypeOptions?.length > 0 ? namesTypeOptions : []}  
                        onChangeHandler={(event) =>
                          onChangeHandler(event, "enum_value_id", index)
                        }
                        loading={isDataLoading}
                        readOnly={selectedRole === "checker"}
                      />
                      {selectedRole === "checker" && !name.title_honorific ? (
                        <TextField
                          sx={{
                            width: "6%",
                            borderRadius: 8,
                            // height: 40,
                            marginRight: "1%",
                          }}
                          value=" "
                          variant={"outlined"}
                          id="input-with-icon-textfield"
                          label="Title"
                          inputProps={{ readOnly: selectedRole === "checker" }}
                        />
                      ) : (
                        <CommonSelect
                          customStyle={{
                            width: "6%",
                            height: "25%",
                            marginRight: "1%",
                          }}
                          showLabel={false}
                          value={name.title_honorific}
                          title="Title"
                          inputId={"inputId"}
                          labelId={"name-label-" + index}
                          selectId={"select-id-" + index}
                          label="Title"
                          options={IBL_NAME_TITLE}
                          onChangeHandler={(event) =>
                            onChangeHandler(event, "title_honorific", index)
                          }
                          loading={loading || isDataLoading}
                          readOnly={selectedRole === "checker"}
                        />
                      )}
                      <TextField
                        sx={{
                          width: "16%",
                          borderRadius: 8,
                          // height: 40,
                          marginRight: "1%",
                        }}
                        value={name.first_name}
                        variant={"outlined"}
                        id="input-with-icon-textfield"
                        label="First Name"
                        onChange={(event) =>
                          onChangeHandler(event, "first_name", index)
                        }
                        inputProps={{ readOnly: selectedRole === "checker" }}
                      />
                      <TextField
                        sx={{
                          width: "16%",
                          borderRadius: 8,
                          // height: 40,
                          marginRight: "1%",
                        }}
                        value={name?.middle_name}
                        variant={"outlined"}
                        id="input-with-icon-textfield"
                        label="Middle Name"
                        onChange={(event) =>
                          onChangeHandler(event, "middle_name", index)
                        }
                        inputProps={{ readOnly: selectedRole === "checker" }}
                      />
                      <TextField
                        sx={{
                          width: "16%",
                          borderRadius: 8,
                          // height: 40,
                          marginRight: "1%",
                        }}
                        variant={"outlined"}
                        id="input-with-icon-textfield"
                        label="Surname"
                        value={name?.last_name}
                        onChange={(event) =>
                          onChangeHandler(event, "last_name", index)
                        }
                        inputProps={{ readOnly: selectedRole === "checker" }}
                      />
                      <TextField
                        sx={{
                          width: "16%",
                          borderRadius: 8,
                          // height: 40,
                          marginRight: "1%",
                        }}
                        value={name?.maiden_name}
                        variant={"outlined"}
                        id="input-with-icon-textfield"
                        label="Maiden Name"
                        onChange={(event) =>
                          onChangeHandler(event, "maiden_name", index)
                        }
                        inputProps={{ readOnly: selectedRole === "checker" }}
                      />
                      {names.length > 1 && selectedRole === "maker" && (
                        <ClearIcon
                          sx={{
                            color: "#000",
                            opacity: "58%",
                            cursor: "pointer",
                            marginTop: "1%",
                          }}
                          onClick={() => deleteHandler(index)}
                        />
                      )}
                    </div>
                    <div className="d-flex w-100 ">
                      <div
                        style={{
                          width: "15%",
                        }}
                      ></div>
                      <TextField
                        sx={{
                          width: "16%",
                          borderRadius: 8,
                          // height: 40,
                          marginRight: "1%",
                        }}
                        value={name?.suffix}
                        variant={"outlined"}
                        id="input-with-icon-textfield"
                        label="Suffix"
                        onChange={(event) =>
                          onChangeHandler(event, "suffix", index)
                        }
                        inputProps={{ readOnly: selectedRole === "checker" }}
                      />
                      <TextField
                        sx={{
                          width: "57%",
                          borderRadius: 8,
                          // height: 40,
                          marginRight: "1%",
                        }}
                        value={name?.original_script_name}
                        variant={"outlined"}
                        id="input-with-icon-textfield"
                        label="Original Script Name"
                        onChange={(event) =>
                          onChangeHandler(event, "original_script_name", index)
                        }
                        inputProps={{ readOnly: selectedRole === "checker" }}
                      />
                    </div>
                  </div>
                </div>
                {index !== names.length - 1 && (
                  <Divider className="w-100 divider-style m-t-1" />
                )}
              </>
            );
          })}
      </DetailsSection>
    </div>
  );
};

export default NameField;
