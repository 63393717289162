import { useEffect, useState, useRef } from "react";
import TextField from "@mui/material/TextField";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

//components
import DetailsSection from "../../features/detailsSection/detialsSection";
import { setAdditionalInformation } from "../../redux/aditionalInformationRedux";
import { camelToUnderscore } from "../../constants/additionalInformationTabUtility";
import { isEmptyCheck } from "../../constants/utility";
import NoDataFound from "../noDataFound/noDataFound";

export default function CustomTextarea(props) {
  const { attributeDetails } = props;
  const selectedRole = sessionStorage.getItem("selectedRole");

  const [textAreaValue, setTextAreaState] = useState(selectedRole === "maker" ? {
    enum_type_id: "",
    value: "",
  }: null );
  let timer = useRef(null);
  const dispatch = useDispatch();

  const additionalInformationData = useSelector(
    (state) => state.aditionalInformationDetails
  );

  const entityData = useSelector((state) => state.entityData);

  const editData = additionalInformationData[
    camelToUnderscore(attributeDetails.enum_type_name)
  ]
    ? additionalInformationData[
        camelToUnderscore(attributeDetails.enum_type_name)
      ]
    : [];

  useEffect(() => {
    if (
      entityData.entityData?.action === "edit" &&
      Object.keys(editData)?.length > 0
    ) {
      setTextAreaState({
        ...editData,
      });
    }
  }, []);

  const onChangeHandler = (value) => {
    const updatedTextAreaValue = { ...textAreaValue };
    updatedTextAreaValue["enum_type_id"] = attributeDetails?.enum_type_id;
    updatedTextAreaValue["value"] = value;
    setTextAreaState({ ...updatedTextAreaValue });
  };

  useEffect(() => {
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      dispatch(
        setAdditionalInformation({
          key: camelToUnderscore(attributeDetails.enum_type_name),
          data: textAreaValue?.value !== "" ? { ...textAreaValue } : {},
        })
      );
    }, [500]);
  }, [textAreaValue]);

  return (
<DetailsSection
      title={attributeDetails?.default_label_name}
      showAddIcon={
        attributeDetails.accept_multi_value && selectedRole === "maker"
      }
    >
      {isEmptyCheck(textAreaValue) ? (
        <NoDataFound />
      ) : (
        <TextField
          id="outlined-multiline-static"
          label={attributeDetails?.default_label_name}
          sx={{
            width: "100%",
            borderRadius: 8,
          }}
          value={textAreaValue?.value}
          multiline
          rows={4}
          onChange={(event) => onChangeHandler(event.target.value)}
          inputProps={{ readOnly: selectedRole === "checker" }}
        />
      )}
    </DetailsSection>
  );
}
