import { useEffect, useState, useRef } from "react";
import { TextField, Divider } from "@mui/material";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import ClearIcon from "@mui/icons-material/Clear";

//components, constants

import DetailsSection from "../../features/detailsSection/detialsSection";
import { setAdditionalInformation } from "../../redux/aditionalInformationRedux";
import { camelToUnderscore } from "../../constants/additionalInformationTabUtility";
import DateCompoment from "../dateComponent/DateComponent";
import { CUSTOM_DROPDOWN_DATE_KEYS } from "../../constants/iblConstants";
import { isEmptyCheck } from "../../constants/utility";
import NoDataFound from "../noDataFound/noDataFound";

const CustomDropdownDate = (props) => {
  const { attributeDetails, isDisabled } = props;
  const selectedRole = sessionStorage.getItem("selectedRole");

  const [dropDownDateState, setDropdownDateState] = useState(
    selectedRole === "maker"
      ? [
          {
            enum_type_id: "",
            enum_value_id: "",
            enum_value_name: "",
            sinceDay: "",
            sinceMonth: "",
            sinceYear: "",
            toDay: "",
            toMonth: "",
            toYear: "",
          },
        ]
      : null
  );

  const additionalInformationData = useSelector(
    (state) => state.aditionalInformationDetails
  );

  const editData = additionalInformationData[
    camelToUnderscore(attributeDetails.enum_type_name)
  ]
    ? additionalInformationData[
        camelToUnderscore(attributeDetails.enum_type_name)
      ]
    : [];

  const entityData = useSelector((state) => state.entityData);

  const [roleTypeOption, setRoleTypeOption] = useState([]);

  let timer = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (attributeDetails?.enum_values) {
      const roleTypeList = attributeDetails.enum_values.map((ele) => ({
        id: ele.enum_value_id,
        value: ele.enum_value_name,
      }));
      setRoleTypeOption([...roleTypeList]);
    }
  }, [attributeDetails]);

  useEffect(() => {
    if (entityData.entityData?.action === "edit" && editData?.length > 0) {
      setDropdownDateState([...editData]);
    }
  }, []);

  const addFieldHandler = () => {
    setDropdownDateState((prevState) => [
      ...prevState,
      {
        enum_type_id: "",
        enum_value_id: "",
        enum_value_name: "",
        sinceDay: "",
        sinceMonth: "",
        sinceYear: "",
        toDay: "",
        toMonth: "",
        toYear: "",
      },
    ]);
  };

  const onChangeHandler = (value, key, index) => {
    const updatedDDropdownDateState = [...dropDownDateState];
    updatedDDropdownDateState[index] = {
      ...updatedDDropdownDateState[index],
      [CUSTOM_DROPDOWN_DATE_KEYS["enum_type_id"]]:
        attributeDetails.enum_type_id,
      [CUSTOM_DROPDOWN_DATE_KEYS[key]]: value,
    };
    if (CUSTOM_DROPDOWN_DATE_KEYS[key] === "enum_value_name") {
      updatedDDropdownDateState[index] = {
        ...updatedDDropdownDateState[index],
        [CUSTOM_DROPDOWN_DATE_KEYS["enum_value_id"]]: value,
        [CUSTOM_DROPDOWN_DATE_KEYS["fromDay"]]: "",
        [CUSTOM_DROPDOWN_DATE_KEYS["fromMonth"]]: "",
        [CUSTOM_DROPDOWN_DATE_KEYS["fromYear"]]: "",
        [CUSTOM_DROPDOWN_DATE_KEYS["toDay"]]: "",
        [CUSTOM_DROPDOWN_DATE_KEYS["toMonth"]]: "",
        [CUSTOM_DROPDOWN_DATE_KEYS["toYear"]]: "",
      };
    }
    setDropdownDateState([...updatedDDropdownDateState]);
  };

  const deleteHandler = (id) => {
    const updatedValues = [...dropDownDateState];
    updatedValues.splice(id, 1);
    setDropdownDateState([...updatedValues]);
  };

  useEffect(() => {
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      const updatedDropdownDateData = [];
      dropDownDateState?.map((ele) => {
        if (!isEmptyCheck(ele.enum_value_id.id)) {
          updatedDropdownDateData.push(ele);
        }
      });
      dispatch(
        setAdditionalInformation({
          key: camelToUnderscore(attributeDetails.enum_type_name),
          data: [...updatedDropdownDateData],
        })
      );
    }, [500]);
  }, [dropDownDateState]);

  return (
    <DetailsSection
      title={attributeDetails?.default_label_name}
      showAddIcon={
        attributeDetails.accept_multi_value && selectedRole === "maker"
      }
      addFieldHandler={addFieldHandler}
    >
      {dropDownDateState?.map((ele, index) => {
        return (
          <>
            <div
              className="d-flex mt-3 w-100 justify-content-between"
              key={index}
            >
              <Autocomplete
                disablePortal
                value={ele.enum_value_name}
                id={"additionalfield-role-type" + index}
                options={roleTypeOption}
                getOptionLabel={(option) =>
                  option?.value ? option?.value : ""
                }
                sx={{ width: "38%", marginRight: "1%" }}
                renderInput={(params) => <TextField {...params} label="Role" />}
                onChange={(event, newValue) => {
                  onChangeHandler(newValue, "enum_value_name", index);
                }}
                readOnly={selectedRole === "checker"}
              />
              {dropDownDateState.length > 1 && selectedRole === "maker" && (
                <ClearIcon
                  sx={{
                    color: "#000",
                    opacity: "58%",
                    cursor: "pointer",
                    marginTop: "1%",
                  }}
                  onClick={() => deleteHandler(index)}
                />
              )}
            </div>
            {ele.enum_value_id && (
              <div>
                <DateCompoment
                  index={index}
                  customLabel={{
                    yearLabel: "From Year",
                    monthLabel: "From Month",
                    dayLabel: "From Day",
                  }}
                  onChangeHandler={onChangeHandler}
                  dob={{
                    day: ele?.sinceDay ? ele?.sinceDay : null,
                    month: ele?.sinceMonth ? ele?.sinceMonth : null,
                    year: ele?.sinceYear ? ele?.sinceYear : null,
                  }}
                  isDisabled={selectedRole === "checker"}
                />
                <DateCompoment
                  index={index}
                  customLabel={{
                    yearLabel: "To Year",
                    monthLabel: "To Month",
                    dayLabel: "To Day",
                  }}
                  onChangeHandler={onChangeHandler}
                  dob={{
                    day: ele?.toDay ? ele?.toDay : null,
                    month: ele?.toMonth ? ele?.toMonth : null,
                    year: ele?.toYear ? ele?.toYear : null,
                  }}
                  isDisabled={selectedRole === "checker"}
                />
              </div>
            )}
            {index !== dropDownDateState.length - 1 && (
              <Divider className="w-100 divider-style m-t-1" />
            )}
          </>
        );
      })}
      {isEmptyCheck(dropDownDateState) && (
        <NoDataFound/>
      )}
    </DetailsSection>
  );
};

export default CustomDropdownDate;
