import { React, useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CommonSelect from "../../components/dropDown/dropDown";
import { Button, TextField } from "@mui/material";
import DatePicker from "../../components/datePicker/datepicker";
import { useSelector } from "react-redux";
import { listManagementAPI } from "../../api";
import SpinnerBlocker from "../../components/loader/spinnerBlocker/spinnerBlocker";
import NoDataPresent from "../../components/noDataPresent/noDataPresent";

const ShowResult = () => {
  const screen = useSelector((state) => state.metadata.screenList);
  const [date, setDate] = useState(new Date());
  const [options, setOptions] = useState([]);
  const [screenList, setScreenList] = useState(null);
  const [data, setDataForTable] = useState(null);
  const [total, setTotal] = useState(0);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isError, setError] = useState(null);

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#1C2472",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  function subtotal(items) {
    return items.map(({ Final }) => Final).reduce((sum, i) => sum + i, 0);
  }

  const numberFormatter = (item) => {
    if (item > 1000) {
      return Math.floor(item / 1000) + "K";
    } else {
      return item;
    }
  };

  const getReconResult = () => {
    setIsDataLoading((prevState) => !prevState);
    const asyncResponse = async () => {
      try {
        const responseFromAPI = await listManagementAPI.getReconResult({
          client_id: 1,
          screen_type_id: screenList,
          generated_date: date,
        });
        setError(false);
        const formattedResponseForTable =
          responseFromAPI?.data?.data?.statistics.map((ele) => {
            return {
              name: ele.watchList,
              Source: ele.sourceRecCount,
              Excluded: ele.excludedRecCount,
              Included: ele.includedRecCount,
              Final: ele.finalRecCount,
            };
          });
        setDataForTable(
          formattedResponseForTable.sort((a, b) => b.Source - a.Source)
        );
      } catch (err) {
        setError(true);
        setDataForTable(null);
      } finally {
        setIsDataLoading(false);
      }
    };
    asyncResponse();
  };

  const handleChangeScreenList = (event) => {
    setScreenList(event.target.value);
  };

  useEffect(() => {
    const opt = screen.map((screenObj) => {
      return { id: screenObj.screen_type_id, value: screenObj.name };
    });
    setOptions([...opt]);
  }, []);

  useEffect(() => {
    if (data) {
      const total = subtotal(data);
      setTotal(total);
    }
  }, [data]);

  return (
    <div className="d-flex flex-column">
      <Paper className="d-flex mt-5 p-4 flex-column" elevation={3}>
        <h5>Summary Report</h5>
        <div className="d-flex justify-content-between mt-2">
          <CommonSelect
            customStyle={{
              width: "25%",
              marginRight: "1%",
            }}
            showLabel={false}
            value={screenList}
            title="Screen Type"
            label="Screen Type"
            options={options}
            onChangeHandler={handleChangeScreenList}
          />
          <DatePicker
            className="w-25"
            disableFuture={true}
            label="Date"
            inputFormat="dd MMM yyyy"
            value={date}
            onChange={(newValue) => {
              setDate(newValue.toISOString());
            }}
            renderInput={(params) => <TextField {...params} />}
          />
          <div className="w-50 d-flex flex-column align-items-end align-self-center">
            <Button
              className="add-rule-btn"
              variant="outlined"
              size="medium"
              onClick={getReconResult}
            >
              View Report
            </Button>
          </div>
        </div>
      </Paper>
      {isDataLoading && <SpinnerBlocker></SpinnerBlocker>}
      {data && data.length > 0 ? (
        <div
          className="d-flex justify-content-around"
          style={{ minHeight: "50vh" }}
        >
          <TableContainer component={Paper} className="w-50 mt-5">
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>List Name</StyledTableCell>
                  <StyledTableCell align="right">Source Count</StyledTableCell>
                  <StyledTableCell align="right">
                    Included Count
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    Excluded Count
                  </StyledTableCell>
                  <StyledTableCell align="right">Final Count</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row) => (
                  <StyledTableRow key={row.name}>
                    <StyledTableCell component="th" scope="row">
                      {row.name}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.Source}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.Included}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.Excluded}
                    </StyledTableCell>
                    <StyledTableCell align="right">{row.Final}</StyledTableCell>
                  </StyledTableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={4} align="right">
                    Total
                  </TableCell>
                  <TableCell align="right">{total}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Paper className="d-flex flex-column w-50 mt-5 ms-3">
            <ResponsiveContainer height="50%">
              <BarChart data={data}>
                <CartesianGrid strokeDasharray="2 2" />
                <XAxis dataKey="name" />
                <YAxis tickFormatter={numberFormatter} />
                <Tooltip />
                <Legend verticalAlign="top" height={36} />
                <Bar dataKey="Source" fill="#00C49F" />
                <Bar dataKey="Included" fill="#7300e6" />
                <Bar dataKey="Excluded" fill="#e6ac00" />
                <Bar dataKey="Final" fill="#1C2472" />
              </BarChart>
            </ResponsiveContainer>
            <ResponsiveContainer height="50%" className="mt-2">
              <PieChart>
                <Legend
                  layout="vertical"
                  verticalAlign="middle"
                  align="right"
                />
                <Pie
                  dataKey="Final"
                  isAnimationActive={false}
                  data={data}
                  cx="50%"
                  cy="50%"
                  outerRadius={80}
                  fill="#8884d8"
                  label
                >
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </Paper>
        </div>
      ) : (
        isError && <NoDataPresent message="No data available !"></NoDataPresent>
      )}
    </div>
  );
};

export default ShowResult;
