import { Paper, Button, TextField } from "@mui/material";
import * as React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getMasterBlocklistEntries,
  getStageBlocklistEntries,
  getEntriesComments,
  entriesModHandler,
} from "../../api/blockListManagementAPI";
import { useTranslation } from "react-i18next";
import IblRecordDetails from "./iblRecordDetails/iblRecordDetails";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IBLDetailsComponent from "./iblDetailsComponent/ibldetailsComponent";
import SpinnerBlocker from "../../components/loader/spinnerBlocker/spinnerBlocker";

const ViewModifiedApproval = (props) => {
  const navigate = useNavigate();
  const [masterData, setMasterData] = React.useState({});
  const [masterComment, setMasterComment] = React.useState();
  const [stageData, setStageData] = React.useState({});
  const [stageComment, setStageComment] = React.useState();
  const [disableButton, setDisableButton] = React.useState(true);
  const [comments, setComments] = React.useState("");
  const userId = useSelector((state) => state.login?.user?.attributes?.email);
  const { state } = useLocation();
  const blocklistDetails = useSelector(
    (state) => state.metadata.selectedBlocklist
  );
  const [isloading, setLoading] = React.useState(false);
  const { t } = useTranslation();

  const navigateToIBLEntries = () => {
    navigate("/iblDetails", {
      state: { query: blocklistDetails.blocklist_id },
    });
  };

  const onChangeHandler = (event) => {
    setComments(event?.target?.value);
    if (event?.target?.value) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  };

  const action = async (value) => {
    setLoading(true);
    const body = {
      client_id: 1,
      blocklist_id: blocklistDetails.blocklist_id,
      blocklist_entry_id: state.entryId,
      version_no: state.versionNo,
      api_action: value,
      comment: comments,
      user_id: userId,
    };

    const response = await entriesModHandler(body);
    if (response.status === 200 && response.data.data.length) {
      body.api_action == 1
        ? toast.success("Entry Approved!")
        : toast.success("Entry Rejected!");
      setLoading(false);
      navigateToIBLEntries();
    } else {
      if (response?.response?.data) {
        toast.error(t(response?.response?.data?.code));
        setLoading(false);
      } else {
        toast.error(t(response.code));
        setLoading(false);
      }
    }
  };

  React.useEffect(() => {
    (async () => {
      try {
        const query = {
          client_id: 1,
          blocklist_id: blocklistDetails.blocklist_id,
          blocklist_entry_id: state.entryId,
          version_no: state.versionNo - 1,
        };
        const response = await getMasterBlocklistEntries(query);
        const newMasterData = response?.data?.data[0];
        setMasterData(newMasterData);
      } catch (e) {
        toast.error(e);
      }
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      try {
        const query = {
          client_id: 1,
          blocklist_id: blocklistDetails.blocklist_id,
          blocklist_entry_id: state.entryId,
          version_no: state.versionNo,
        };
        const response = await getStageBlocklistEntries(query);
        const newStageData = response?.data?.data[0];
        setStageData(newStageData);
      } catch (e) {
        toast.error(e);
      }
    })();
  }, []);

  // React.useEffect(() => {
  //   (async () => {
  //     try {
  //       const query = {
  //         client_id: 1,
  //         blocklist_id: blocklistDetails.blocklist_id,
  //         blocklist_entry_id: state.entryId,
  //         version_no: state.versionNo - 1,
  //       };
  //       const response = await getEntriesComments(query);
  //       setMasterComment(response?.data?.data?.comments[0]?.comment_text);
  //     } catch (e) {
  //       console.error(e);
  //     }
  //   })();
  // }, []);

  React.useEffect(() => {
    (async () => {
      try {
        const query = {
          client_id: 1,
          blocklist_id: blocklistDetails.blocklist_id,
          blocklist_entry_id: state.entryId,
          version_no: state.versionNo - 1,
        };
        const response = await getEntriesComments(query);
        setMasterComment(response?.data?.data?.comments[0]?.comment_text);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  return (
    <>
      <div
        className="d-flex flex-row mt-3 px-1 py-5 w-100"
        id="blocklist-details"
      >
        <Button
          variant="text"
          startIcon={<ArrowBackIcon />}
          className="fcc-color-grey me-1 w-5"
          onClick={navigateToIBLEntries}
        >
          BACK
        </Button>
        <IBLDetailsComponent
          selectedRole="checker"
          blocklistDetails={blocklistDetails}
        ></IBLDetailsComponent>
      </div>
      <div className="d-flex flex-column view-new-approval-container w-100">
        <div className="d-flex w-100">
          <span className="section-title w-25">Basic Details</span>
          <span className="section-right-label d-flex justify-content-end w-75">
            Individual
          </span>
        </div>
        <hr className="w-100" />
        <div className="d-flex ">
          <div className="w-50 mr-16">
            <IblRecordDetails
              recordDetails={masterData}
              // recordComment={masterComment}
              isExisting={true}
            />
          </div>
          <div className="w-50">
            <IblRecordDetails
              recordDetails={stageData}
              recordComment={stageComment}
              isModified={true}
            />
          </div>
        </div>
        <Paper
          className="mt-5 p-4"
          elevation={5}
          sx={{ width: "100%", minHeight: "15rem", color: "#1C2472" }}
        >
          <div className="section-title w-100 mb-2">Approver Action</div>
          <div className="d-flex w-100 justify-content-between">
            <TextField
              className="w-50"
              id="outlined-multiline-static"
              label="Comments"
              value={comments}
              multiline
              rows={6}
              placeholder="Comments"
              onChange={(event) => onChangeHandler(event)}
            />
            <div className="align-self-end">
              <Button
                size="large"
                variant="outlined"
                color="error"
                disabled={disableButton}
                onClick={() => {
                  return action(2);
                }}
              >
                Reject
              </Button>
              <Button
                className="ms-2"
                variant="contained"
                size="large"
                color="error"
                disabled={disableButton}
                onClick={() => {
                  return action(1);
                }}
              >
                Approve
              </Button>
            </div>
          </div>
        </Paper>
      </div>
      {isloading && <SpinnerBlocker></SpinnerBlocker>}
    </>
  );
};

export default ViewModifiedApproval;
