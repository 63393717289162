import { Button, InputLabel, Paper } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import IconButton from "@mui/material/IconButton";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import format from "date-fns/format";
import { useTranslation } from "react-i18next";
import { ruleConstants } from "../../constants";
import "./table.scss";
import RightSidePopup from "../modal/modal";
import StopApproveRejectRule from "../../features/stopApproveRejectRule/stopApproveRejectRule";
import NoDataPresent from "../noDataPresent/noDataPresent";
import OverflowTip from "../overflowTooltip/overflowTooltip";

export default function TableForActiveAndPendingRules({
  tableFor,
  rows,
  ...props
}) {
  const [openDescription, setOpenDescription] = useState([]);
  const descriptions = useSelector((state) => state.metadata.descriptions);
  const occupation = useSelector((state) => state.metadata.roles);
  const region = useSelector((state) => state.metadata.countries);
  const { t } = useTranslation();
  const ruleTypeWithMultipleDescriptions = [7];
  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const rule_category_id = sessionStorage.getItem("category") || 1;
  useEffect(() => {
    if (rows != null) {
      setOpenDescription([...Array(rows.length)].map((ele) => false));
    }
  }, []);

  const handleClick = (index) => {
    const updateOpenDescription = openDescription.map((ele, i) => {
      if(index === i) return !ele
      return false
    } )
    setOpenDescription(updateOpenDescription);
  };

  const handleClose = () => setOpenModal(false);
  const handleOpen = (data) => {
    setOpenModal(true);
    setSelectedRow(data);
  }

  return rows.length > 0 ? (
    <>
      <div
        style={{ width: "100%", minHeight: "2rem", maxHeight: "2rem" }}
        className={`d-flex flex-row mb-1`}
      >
        <div className="py-2" style={{ minWidth: "8%", maxWidth: "8%" }}>
          <InputLabel className="fcc-name">RULE ID</InputLabel>
        </div>
        <div className="py-2" style={{ minWidth: "9.5%", maxWidth: "9.5%" }}>
          <InputLabel className="fcc-name">RULE GROUP</InputLabel>
        </div>
        <div className="py-2" style={{ minWidth: "17%", maxWidth: "17%" }}>
          <InputLabel className="fcc-name">RULE TYPE</InputLabel>
        </div>
        <div className="py-2" style={{ minWidth: "24.5%", maxWidth: "24.5%" }}>
          <InputLabel className="fcc-name">RULE VALUE</InputLabel>
        </div>
        <div className="py-2" style={{ minWidth: "12%", maxWidth: "12%" }}>
          <InputLabel className="fcc-name">PROPOSED FROM</InputLabel>
        </div>
        <div className="py-2" style={{ minWidth: "12%", maxWidth: "12%" }}>
          <InputLabel className="fcc-name">PROPOSED TO</InputLabel>
        </div>
        <div className="py-2" style={{ minWidth: "7%", maxWidth: "7%" }}>
          <InputLabel className="fcc-name">WATCHLIST</InputLabel>
        </div>
      </div>

      {rows &&
        rows.map((data, index) => {
          return (
            <div key={index}>
              <Paper
                sx={{ width: "100%", minHeight: "3.5rem", maxHeight: "3.5rem" }}
                elevation={1}
                className={`d-flex flex-row mt-1 justify-content-start ${
                  index % 2 ? `alt-row-color` : ""
                }`}
                key={index}
                id={"paper-" + index}
              >
                <div
                  className="d-flex align-items-center"
                  style={{
                    minWidth: "8%",
                    maxWidth: "8%",
                  }}
                  id="rule-id"
                >
                  <div className="ps-2 value-container">{data.ruleId}</div>
                </div>
                <div
                  className="d-flex align-items-center"
                  style={{
                    minWidth: "10%",
                    maxWidth: "10%",
                  }}
                  id="rule-group"
                >
                  <div className="px-2 value-container">
                    {" "}
                    {t(ruleConstants.RULE_GROUP[data.ruleGroup])}
                  </div>
                </div>
                <div
                  className="d-flex align-items-center"
                  style={{
                    minWidth: "17%",
                    maxWidth: "17%",
                  }}
                  id="rule-type"
                >
                  <div className="value-container">
                    {rule_category_id === "2" ? t(ruleConstants.RULE_TYPE_INCLUSION[data.ruleType]) : t(ruleConstants.RULE_TYPE_EXCLUSION[data.ruleType])}
                  </div>
                </div>
                <div
                  className="d-flex align-items-center"
                  style={{
                    minWidth: "24%",
                    maxWidth: "24%",
                  }}
                  id="rule-value"
                >
                  {ruleTypeWithMultipleDescriptions.includes(data.ruleType) ? (
                    <div className="value-container">
                      Description
                      <IconButton
                        variant="text"
                        aria-label="expand row"
                        size="small"
                        onClick={() => handleClick(index)}
                      >
                        {openDescription[index] ? (
                          <ArrowDropUpIcon />
                        ) : (
                          <ArrowDropDownIcon />
                        )}
                      </IconButton>
                    </div>
                  ) : data.ruleRefId === 3 ||
                    data.ruleType === 11 ||
                    data.ruleRefId === 16 ||
                    data.ruleType === 2 ? (
                    <div className="value-container">
                      {
                        region.find(
                          (ele) => ele.country_code === data.ruleValue1
                        )["country_name"]
                      }
                    </div>
                  ) : data.ruleRefId === 9 || data.ruleType === 8 ? (
                    <div className="value-container">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={`https://dev-facctview.facctum.ai/profile?entityId=${data.ruleValue1}`}
                      >
                        {data.ruleValue1}
                      </a>
                    </div>
                  ) : data.ruleRefId === 17 || data.ruleType === 12 ? (
                    <div className="value-container">
                      {
                        occupation.find(
                          (ele) =>
                            parseInt(ele.occupation_code) ===
                            parseInt(data.ruleValue1)
                        )["occupation_name"]
                      }
                    </div>
                  ) : (
                    <div className="value-container">{data.ruleValue1}</div>
                  )}
                </div>
                <div
                  className="d-flex align-items-center"
                  style={{
                    minWidth: "12%",
                    maxWidth: "12%",
                  }}
                  id="proposed-from-id"
                >
                  <div className="px-2 value-container">
                    {data.from &&
                      format(new Date(data?.from?.slice(0, 10)), "dd LLL yyyy")}
                  </div>
                </div>
                <div
                  className="d-flex align-items-center"
                  style={{
                    minWidth: "12%",
                    maxWidth: "12%",
                  }}
                  id="proposed-to-id"
                >
                  <div className="px-2 value-container">
                    {data.to &&
                      format(new Date(data?.to?.slice(0, 10)), "dd LLL yyyy")}
                  </div>
                </div>
                <div
                  className="d-flex align-items-center"
                  style={{
                    minWidth: "8%",
                    maxWidth: "8%",
                  }}
                  id="list-type"
                >
                  <div className="px-2 value-container">
                    {ruleConstants.WATCHLIST[data.listType]}
                  </div>
                </div>
                <div
                  className="d-flex align-items-center value-container"
                  style={{ minWidth: "5rem", maxWidth: "5rem" }}
                >
                  {tableFor === "activeRules" && (
                    <Button
                      className="stop-btn"
                      variant="outlined"
                      size="medium"
                      onClick={()=>handleOpen(data)}
                    >
                      STOP
                    </Button>
                  )}
                </div>
                <div className="d-flex align-items-center align-self-end value-container">
                  <IconButton
                    aria-label="expand row"
                    size="large"
                    onClick={() => handleClick(index)}
                  >
                    {openDescription[index] ? (
                      <ArrowDropUpIcon />
                    ) : (
                      <ArrowDropDownIcon />
                    )}
                  </IconButton>
                </div>
              </Paper>
              {openDescription[index] && (
                <Paper
                  sx={{
                    width: "100%",
                    minHeight: "8.5rem",
                    maxHeight: "10.5rem",
                  }}
                  elevation={1}
                  className={`d-flex flex-row ${
                    index % 2 ? `alt-row-color` : ""
                  }`}
                  key={index}
                  id={"paper-" + index}
                >
                  <div
                    className="py-3"
                    style={{
                      minWidth: "60%",
                      maxWidth: "65%",
                    }}
                  >
                    {ruleTypeWithMultipleDescriptions.includes(
                      data.ruleType
                    ) && (
                      <div>
                        {data.ruleValue1 && (
                          <p>
                            <span className="title">Description 1</span>
                            <span className="ps-2 description">
                              {
                                descriptions.descr1.find(
                                  (ele) =>
                                    ele.description1_id.toString() ===
                                    data.ruleValue1.toString()
                                )["description1_name"]
                              }
                            </span>
                          </p>
                        )}
                        {data.ruleValue2 && (
                          <p>
                            <span className="title">Description 2</span>
                            <span className="ps-2 description">
                              {
                                descriptions.descr2.find(
                                  (ele) =>
                                    ele.description2_id.toString() ===
                                    data.ruleValue2.toString()
                                )["description2_name"]
                              }
                            </span>
                          </p>
                        )}
                        {data.ruleValue3 && (
                          <p>
                            <span className="title">Description 3</span>
                            <span className="ps-2 description">
                              {
                                descriptions.descr3.find(
                                  (ele) =>
                                    ele.description3_id.toString() ===
                                    data.ruleValue3.toString()
                                )["description3_name"]
                              }
                            </span>
                          </p>
                        )}
                        {data.ruleValue4 && (
                          <p>
                            <span className="title">Description 4</span>
                            <span className="ps-2 description">
                              {data.ruleValue4}
                            </span>
                          </p>
                        )}
                        {data.ruleValue5 && (
                          <p>
                            <span className="title">Description 5</span>
                            <span className="ps-2 description">
                              {data.ruleValue5}
                            </span>
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                  <div
                    className="d-flex"
                    style={{
                      minWidth: "12%",
                      maxWidth: "12%",
                    }}
                    id="state-id"
                  >
                    <div className="d-flex flex-column">
                      <div className="pt-3 title">Created on</div>
                      <div className="description">
                        {format(
                          new Date(data?.addedDate?.slice(0, 10)),
                          "dd LLL yyyy"
                        )}
                      </div>

                      {data.updatedDate && (
                        <>
                          <div className="pt-2 title">Approved on</div>
                          <div className="pb-2 description">
                            {format(
                              new Date(data?.updatedDate?.slice(0, 10)),
                              "dd LLL yyyy"
                            )}
                          </div>
                        </>
                      )}

                      {data.inUseStartDate && (
                        <>
                          <div className="title">In Use From</div>
                          <div className="description">
                            {format(
                              new Date(data?.inUseStartDate?.slice(0, 10)),
                              "dd LLL yyyy"
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div
                    className="d-flex"
                    style={{
                      minWidth: "25%",
                      maxWidth: "25%",
                    }}
                    id="state-id"
                  >
                    <div className="d-flex flex-column justify-content-start col-6 col-md-12 col-sm-12 col-xs-12">
                      <div className="pt-3 title">Created by</div>
                      <div className="description"><OverflowTip value={data?.addedUserId}/></div>
                      {data.updatedUserId && (
                        <div className="pt-2 title">Approved by</div>
                      )}
                      <div className="pb-2 description">
                      <OverflowTip value={data?.updatedUserId} />
                      </div>
                      {data?.inUseToDate && (
                        <div className="title">In Use To</div>
                      )}
                      {data?.inUseFromDate && (
                        <div className="description">
                          {format(
                            new Date(data?.inUseEndDate?.slice(0, 10)),
                            "dd LLL yyyy"
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </Paper>
              )}
            </div>
          );
        })}
      {tableFor === "activeRules" && (
        <RightSidePopup open={openModal} width="26%">
          <StopApproveRejectRule handleClose={handleClose} selectedRow={selectedRow} activeTab={tableFor}/>
        </RightSidePopup>
      )}
    </>
  ) : (
    <NoDataPresent message={`No ${tableFor === "activeRules" ? "active rules" : " pending rules"} available`} />
  );
}
