/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Paper, Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ruleConstants } from "../../constants";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Tooltip from "@mui/material/Tooltip";

//compoments, constants, API handler
import SearchComponent from "../../components/searchComponent/searchComponent";
import * as ListManagementApi from "../../api/listManagementAPI";
import SpinnerLoader from "../../components/spinnerLoader/spinnerLoader";
import {
  ADD_RULE_STEPS_EXCLUSION,
  ADD_RULE_STEPS_INCLUSION,
  ADD_RULE_TITLE,
  ID_NAME_MAPPER,
  RULE_GROUP_TYPE_FULL_FORM,
  SELECT_COMPONENT_INCLUSION_TITLES,
  SELECT_COMPONENT_EXCLUSION_TITLES,
  MIN_WIDTH_FOR_DESCRIPTION_EXCLUSION,
  MIN_WIDTH_FOR_DESCRIPTION_INCLUSION,
} from "../../constants/ruleConstants";
import {
  setSearchedCodes,
  setLists,
  setLoading,
  setSelectedCodesRedux,
  resetLists,
  resetSelectedCodesRedux,
} from "../../redux/searchCodesRedux";
import DraftsTable from "./draftsTable";
//styles
import "./addNewRule.scss";

const AddRule = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [ruleGroupType, setRuleGroupType] = useState();
  const [ruleList, setRuleList] = useState();
  const [ruleType, setRuleType] = useState();
  const [selectedRule, setSelectedRule] = useState();
  const [selectedRuleType, setSelectedRuleType] = useState(null);
  const [draftedRules, setDraftedRules] = useState();
  const [descriptions, setDescriptions] = React.useState(null);
  const [descriptionOne, setDescriptionOne] = React.useState("");
  const [descriptionTwo, setDescriptionTwo] = React.useState("");
  const [descriptionThree, setDescriptionThree] = React.useState("");
  const [descriptionOneOptions, setDescriptionOneOptions] =
    React.useState(null);
  const [descriptionTwoOptions, setDescriptionTwoOptions] =
    React.useState(null);
  const [descriptionThreeOptions, setDescriptionThreeOptions] =
    React.useState(null);
  const [showExcludeBtn, setShowExcludeBtn] = useState(false);
  const [list, setList] = useState(null);
  const [deletedRow, setDeletedRow] = useState(null);
  const descriptionsData = useSelector((state) => state.metadata.descriptions);
  const [isLoading, setIsLoading] = useState(false);
  const [isDescriptionOneDataLoading, setIsDescriptionOneDataLoading] = useState(false);
  const [isDescriptionTwoDataLoading, setIsDescriptionTwoDataLoading] = useState(false);
  const [isDescriptionThreeDataLoading, setIsDescriptionThreeDataLoading] = useState(false);
  const lists = useSelector((state) => state.searchcodesdata.lists);
  const [activeButton, setActiveButton] = useState("all");
  const selectedCodesRedux = useSelector((state) => state.searchcodesdata.selectedCodesRedux);
  const [stepsScreen, setStepsScreen] = useState(1);
  const rule_category_id = sessionStorage.getItem("category") || 1;

  useEffect(() => {
    if (selectedCodesRedux.length > 0) {
      setShowExcludeBtn(true);
    } else {
      setShowExcludeBtn(false);
    }
    if ([1, 5, 6, 9, 10, 13, 14].includes(parseInt(selectedRuleType)) || selectedRule === "1002") {
      setShowExcludeBtn(true);
    }
  }, [selectedCodesRedux]);

  const handleDescriptionOneChange = (event) => {
    setIsDescriptionTwoDataLoading(true)
    setDescriptionTwoOptions(null);
    const description2Options = descriptions.descr2.filter(
      (i) => parseInt(i.description1_id) === parseInt(event.target.value)
    );
    if (description2Options.length) {
      setDescriptionTwoOptions(description2Options);
    }
    setDescriptionOne(event.target.value);
    setDescriptionTwo("");
    setDescriptionThree("");
    setDescriptionThreeOptions(null);
    setShowExcludeBtn(true);
    setIsDescriptionTwoDataLoading(false)
  };

  const handleDescriptionTwoChange = (event) => {
    setIsDescriptionThreeDataLoading(true)
    setDescriptionThreeOptions(null);
    const description3Options = descriptions.descr3.filter(
      (i) => parseInt(i.description2_id) === parseInt(event.target.value)
    );
    if (description3Options.length) {
      setDescriptionThreeOptions(description3Options);
    }
    setDescriptionTwo(event.target.value);
    setDescriptionThree("");
    setIsDescriptionThreeDataLoading(false)
  };

  const handleDescriptionThreeChange = (event) => {
    setDescriptionThree(event.target.value);
  };

  const getButtonStyle = (rule, key) => {
    if (rule?.toString() === key?.toString()) return "filled";
    if (draftedRules?.length) return "disbaled";
    return "outlined";
  };

  const navigateToDashboard = (event) => {
    navigate("/dashboard");
  };

  const selectRuleGroup = (event) => {
    dispatch(resetLists());
    dispatch(resetSelectedCodesRedux());
    dispatch(setLoading())
    const group = event.target.id;
    const uniqueRuleTypes = ruleGroupType.filter(
        (i) => parseInt(i.rule_group_id) === parseInt(group)
    );
    const typeArray = uniqueRuleTypes.map((ele) => {
      return {
        ruleRefId: ele.rule_ref_id,
        ruleGroupId: ele.rule_group_id,
        ruleTypeId: ele.rule_type_id,
        ruleTypeName: ruleConstants.RULE_TYPE_SHORT_TEXT[ele.rule_type_id],
      };
    });
    setRuleType(typeArray);
    setSelectedRule(group);
    setStepsScreen(group);
    if(group === "1002")
    {
      setSelectedRuleType("9");
    }
    else{
      setSelectedRuleType(null);
    }
  };

  const selectRuleType = (event) => {
    setSelectedRuleType(event.target.id);
    dispatch(resetSelectedCodesRedux());
    dispatch(resetLists());
    dispatch(setLoading())

    setList(null);
    switch (event.target.id) {
      case "2": 
      case "3":
      case "4":
      case "8":
      case "11":
      case "12": {
          setActiveButton("all")
          break;
      }
      case "7": {
        setIsDescriptionOneDataLoading(true)
        setDescriptions(descriptionsData);
        setDescriptionOneOptions(descriptionsData.descr1);
        setIsDescriptionOneDataLoading(false)
        return descriptionsData;
      }
      default:
        setShowExcludeBtn(true);
    }
  };

  const createRule = (event) => {
    const ruleRefId = ruleGroupType.filter(
      (i) =>
        parseInt(i.rule_group_id) === parseInt(selectedRule) &&
        parseInt(i.rule_type_id) === parseInt(selectedRuleType)
    );
    switch (selectedRuleType?.toString()) {
      case "1":
      case "5":
      case "6":
      case "9":
      case "10":
      case "13": 
      case "14": {
        const obj = {
          ruleId: "1",
          ruleGroup: selectedRule,
          ruleType: selectedRuleType,
          value1: true,
          value2: null,
          value3: null,
          value4: null,
          value5: null,
          createdOn: "",
          effectFrom: "",
          effectTo: "",
          ruleRefId: ruleRefId[0]?.rule_ref_id,
        };
        return setDraftedRules([obj]);
      }
      case "2":
      case "11":
      case "12": {
        let draftRule = [];
        let sno = 1;
        selectedCodesRedux.forEach((option) => {
          let obj = {
            ruleId: sno++,
            ruleGroup: selectedRule,
            ruleType: selectedRuleType,
            value1: option.country_code || option.occupation_code,
            value2: null,
            value3: null,
            value4: null,
            value5: null,
            createdOn: "",
            effectFrom: "",
            effectTo: "",
            ruleRefId: ruleRefId[0]?.rule_ref_id,
          };
          draftRule.push(obj);
        });
        return setDraftedRules(draftRule);
      }
      case "3":
      case "4":
      case "8": {
        let draftRule = [];
        let sno = 1;
        selectedCodesRedux.forEach((option) => {
          let obj = {
            ruleId: sno++,
            ruleGroup: selectedRule,
            ruleType: selectedRuleType,
            value1:
              option.list_ref_id || option.list_provider_id || option.entity_id,
            value2: null,
            value3: null,
            value4: null,
            value5: null,
            createdOn: "",
            effectFrom: "",
            effectTo: "",
            ruleRefId: ruleRefId[0]?.rule_ref_id,
          };
          draftRule.push(obj);
        });
        return setDraftedRules(draftRule);
      }
      case "7": {
        const obj = {
          ruleId: "1",
          ruleGroup: selectedRule,
          ruleType: selectedRuleType,
          value1: descriptionOne,
          value2: descriptionTwo,
          value3: descriptionThree,
          value4: null,
          value5: null,
          createdOn: "",
          effectFrom: "",
          effectTo: "",
          ruleRefId: ruleRefId[0]?.rule_ref_id,
        };
        return setDraftedRules([obj]);
      }
      default:
        return;
    }
  };

  const deleteRule = (value) => {
    switch (selectedRuleType?.toString()) {
      case "2":
      case "11": {
        const updatedCodes = selectedCodesRedux.filter(
          (code) => {
            if(code.country_code !== value) return code
          }
        );
        const updatedList = lists.map((code) => {
          if (code.country_code === value) return { ...code, selected: !code.selected };
          return code;
        });
        dispatch(setSelectedCodesRedux([...updatedCodes]));
        dispatch(setLists([...updatedList]));
        dispatch(setSearchedCodes(updatedCodes));
        break;
      }
      case "12": {
        const updatedCodes = selectedCodesRedux.filter((code) => {
          if (code.occupation_code !== value) return code;
        });
        const updatedList = lists.map((code) => {
          if (code.occupation_code === value)
            return { ...code, selected: !code.selected };
          return code;
        });
        dispatch(setSelectedCodesRedux([...updatedCodes]));
        dispatch(setLists([...updatedList]));
        dispatch(setSearchedCodes(updatedCodes));
        break;
      }

      case "3":
      case "4":
        {
          const updatedCodes = selectedCodesRedux.filter((code) => {
            if (code._id !== value) return code;
          });
          const updatedList = lists.map((code) => {
            if (code._id === value) return { ...code, selected: !code.selected };
            return code;
          });
          // setCodes(updatedCodes);
          dispatch(setSelectedCodesRedux([...updatedCodes]));
          dispatch(setLists([...updatedList]));
          dispatch(setSearchedCodes(updatedCodes));
          break;
        }
      case "8": {
          const updatedCodes = selectedCodesRedux.filter((code) => {
            if (code.entity_id !== value) return code;
          });
          const updatedList = lists.map((code) => {
            if (code.entity_id === value) return { ...code, selected: !code.selected };
            return code;
          });
          // setCodes(updatedCodes);
          dispatch(setSelectedCodesRedux([...updatedCodes]));
          dispatch(setLists([...updatedList]));
          dispatch(setSearchedCodes(updatedCodes));
          break;
        }
      default:
        return;
    }
  };

  React.useEffect(() => {
    setIsLoading(true);
    const asyncResponse = async () => {
      const responseFromAPI = await ListManagementApi.fetchRuleGroupType(sessionStorage.getItem('category'));
      setRuleGroupType(responseFromAPI.data);
      //const uniqueGroups = [...new Set(responseFromAPI.data.map(x=>x.rule_group_id))];
      let uniGroups = new Map();
      responseFromAPI.data.forEach((p) => uniGroups.set(p.rule_group_id, p));
      let uniqueGroups = [...uniGroups.values()];
      const groupArray = uniqueGroups.map((ele) => {
        return {
          ruleGroupId: ele.rule_group_id,
          ruleGroupName: ruleConstants.RULE_GROUP[ele.rule_group_id],
        };
      });
      setRuleList(groupArray);
      setIsLoading(false);
    };
    asyncResponse();
  }, []);

  const getOptions = () => {
    switch (selectedRuleType?.toString()) {
      case "7":
        return (
          <div className="mt-3 d-flex justify-content-start">
            {descriptionOneOptions && (
              <FormControl className="me-3 w-25">
                <InputLabel id="desc-1-label">
                  {t(ADD_RULE_TITLE["AddRuleTitle10"])} 1
                </InputLabel>
                <Select
                  labelId="desc-1-label"
                  id="desc-1--select"
                  value={descriptionOne}
                  label="Description 1"
                  onChange={handleDescriptionOneChange}
                >
                  {descriptionOneOptions ?
                    descriptionOneOptions.map((option, index) => (
                      <MenuItem key={index} value={option.description1_id}>
                        {option.description1_name}
                      </MenuItem>
                    )) : (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ minHeight: "100px" }}
                      >
                        {isDescriptionOneDataLoading ? (
                          <SpinnerLoader />
                        ) : (
                          <div className="no-option-text">No options found</div>
                        )}
                      </div>
                    )
                  }
                </Select>
              </FormControl>
            )}
            {descriptionTwoOptions && (
              <FormControl className="me-3 w-25">
                <InputLabel id="desc-2-label">
                  {t(ADD_RULE_TITLE["AddRuleTitle10"])} 2
                </InputLabel>
                <Select
                  labelId="desc-2-label"
                  id="desc-2-select"
                  value={descriptionTwo}
                  label="Description 2"
                  onChange={handleDescriptionTwoChange}
                >
                  {descriptionTwoOptions ?
                    descriptionTwoOptions.map((option, index) => (
                      <MenuItem key={index} value={option.description2_id}>
                        {option.description2_name}
                      </MenuItem>
                    )) :  (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ minHeight: "100px" }}
                      >
                        {isDescriptionTwoDataLoading ? (
                          <SpinnerLoader />
                        ) : (
                          <div className="no-option-text">No options found</div>
                        )}
                      </div>
                    )}
                </Select>
              </FormControl>
            )}
            {descriptionThreeOptions && (
              <FormControl className="me-3 w-25">
                <InputLabel id="desc-3-label">
                  {t(ADD_RULE_TITLE["AddRuleTitle10"])} 3
                </InputLabel>
                <Select
                  labelId="desc-3-label"
                  id="desc-3-select"
                  value={descriptionThree}
                  label="Description 3"
                  onChange={handleDescriptionThreeChange}
                >
                  {descriptionThreeOptions ?
                    descriptionThreeOptions.map((option, index) => (
                      <MenuItem key={index} value={option.description3_id}>
                        {option.description3_name}
                      </MenuItem>
                    )) :  (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ minHeight: "100px" }}
                      >
                        {isDescriptionThreeDataLoading ? (
                          <SpinnerLoader />
                        ) : (
                          <div className="no-option-text">No options found</div>
                        )}
                      </div>
                    )
                  }
                </Select>
              </FormControl>
            )}
          </div>
        );
      default:
        return <></>;
    }
  };

  const handleChangeStepsScreen = (screenValue) =>{
    setStepsScreen(screenValue)
  }

  const handleDelete = (id) => {
    const rows = [...draftedRules];
    const deletedRow = rows.splice(id, 1);
    deleteRule(deletedRow[0].value1);
    setDraftedRules(rows);
  };

  const onToggleButtonChange = (event, newActiveButton) => {
    dispatch(setLoading(true))
    if (newActiveButton !== null) setActiveButton(newActiveButton);
    dispatch(setLoading(false))
  };

  return (
    <>
      <Button
        variant="text"
        startIcon={<ArrowBackIcon />}
        className="fcc-color-grey mt-3"
        onClick={navigateToDashboard}
      >
        {t(ADD_RULE_TITLE["AddRuleTitle14"])}
      </Button>
      { rule_category_id === "2" ? <div className="add-exclusion-text mt-3">{t("AddInclusionRules")}</div>
        : <div className="add-exclusion-text mt-3">{t("AddExclusionRules")}</div>}
      <div className="d-flex flex-row w-100 mt-3" id="add-new-rules">
        <div className="d-flex flex-column w-40 me-1" id="rule-paper">
          <Paper elevate="3" className="pb-3" id="rule-group-paper">
            <InputLabel className="select-rule-text mxy-3">
              {t(ADD_RULE_TITLE["AddRuleTitle2"])}
            </InputLabel>
            {isLoading ? (
              <div className="d-flex flex-row w-100 p-3">
                <Skeleton
                  animation="pulse"
                  className="w-75"
                  sx={{ height: "3rem" }}
                ></Skeleton>
              </div>
            ) : (
              <div className="d-flex flex-row p-3 rule-group">
                  {ruleList &&
                  ruleList.map((rule) => {
                    return [1002, 1003 ].includes(rule.ruleGroupId) ? (
                      <Tooltip title={t(RULE_GROUP_TYPE_FULL_FORM[rule.ruleGroupId])} placement="top">
                        <Button
                          key={rule.ruleGroupId}
                          id={rule.ruleGroupId}
                          value={rule.ruleGroupName}
                          className={`me-2 ${getButtonStyle(
                            selectedRule,
                            rule.ruleGroupId
                          )}`}
                          variant={
                            selectedRule === rule.ruleGroupId
                              ? "contained"
                              : "outlined"
                          }
                          onClick={selectRuleGroup}
                          disabled={draftedRules?.length > 0}
                        >
                          {t(rule.ruleGroupName)}
                        </Button>
                      </Tooltip>
                    ) : (
                      <Button
                        key={rule.ruleGroupId}
                        id={rule.ruleGroupId}
                        value={rule.ruleGroupName}
                        className={`me-2 ${getButtonStyle(
                          selectedRule,
                          rule.ruleGroupId
                        )}`}
                        variant={
                          selectedRule === rule.ruleGroupId
                            ? "contained"
                            : "outlined"
                        }
                        onClick={selectRuleGroup}
                        disabled={draftedRules?.length > 0}
                      >
                        {t(rule.ruleGroupName)}
                      </Button>
                    );
                  })}
              </div>
            )}
          </Paper>
          {(selectedRule && selectedRule !== "1002") && (
            <Paper elevate="3" id="rule-type-paper" className="mt-2">
              { rule_category_id === "2" ? <InputLabel className="exclude-rule-text mxy-3">
              {t("IncludeRuleType")}
              </InputLabel> :
              <InputLabel className="exclude-rule-text mxy-3">
              {t("ExcludeRuleType")}
              </InputLabel> }
              <div className="d-flex flex-row rule-type p-3 ">
              {ruleType &&
                  ruleType.map((rule) => {
                    return rule.ruleTypeId === 10 ? (
                      <Tooltip
                        title={t(RULE_GROUP_TYPE_FULL_FORM[rule.ruleTypeId])}
                        placement="top"
                      >
                        <Button
                          key={rule.ruleTypeId}
                          id={rule.ruleTypeId}
                          value={rule.ruleTypeName}
                          className={`me-2 ${getButtonStyle(
                            selectedRuleType,
                            rule.ruleTypeId
                          )}`}
                          variant={
                            selectedRuleType === rule.ruleTypeId
                              ? "contained"
                              : "outlined"
                          }
                          onClick={selectRuleType}
                          color={"error"}
                          disabled={draftedRules?.length > 0}
                        >
                          {t(rule.ruleTypeName)}
                        </Button>
                      </Tooltip>
                    ) : (
                      <Button
                        key={rule.ruleTypeId}
                        id={rule.ruleTypeId}
                        value={rule.ruleTypeName}
                        className={`me-2 ${getButtonStyle(
                          selectedRuleType,
                          rule.ruleTypeId
                        )}`}
                        variant={
                          selectedRuleType === rule.ruleTypeId
                            ? "contained"
                            : "outlined"
                        }
                        onClick={selectRuleType}
                        color={"error"}
                        disabled={draftedRules?.length > 0}
                      >
                        {t(rule.ruleTypeName)}
                      </Button>
                    );
                  })}
              </div>
            </Paper>
          )}
        </div>
        <Paper elevate="3" className="w-60 ps-3 ms-1" id="rule-descr-paper">
          <div className="select-steps mb-2 ps-2 justify-content-center align-items-center p-r-3">
            <span className="m-3">
              {selectedRuleType ? (
                <div
                  className={` rule-desc-text ${
                    [1, 5, 6, 9, 10, 13, 14].includes(parseInt(selectedRuleType)) &&
                    "d-flex  align-items-center justify-content-center"
                  }`}
                  style={{ minHeight: "8rem" }}
                >
                  <span>
                    {rule_category_id === "2"
                      ? t(
                          SELECT_COMPONENT_INCLUSION_TITLES[
                            selectedRuleType.toString()
                          ]
                        )
                      : t(
                          SELECT_COMPONENT_EXCLUSION_TITLES[
                            ["5", "6"].includes(selectedRuleType.toString())
                              ? selectedRule.toString() +
                                "_" +
                                selectedRuleType.toString()
                              : selectedRuleType.toString()
                          ]
                        )}
                    {t("willBe")}
                    <strong>
                      {" "}
                      {rule_category_id === "2"
                        ? t("INCLUDED")
                        : t("EXCLUDED")}{" "}
                    </strong>
                    {rule_category_id === "2" ? t("InYour") : t("FromYour")}{" "}
                    <strong>{t("ScreeningProcess")}</strong>
                  </span>
                  {["2", "3", "4", "8", "11", "12"].includes(selectedRuleType?.toString()) ? (
                    <SearchComponent
                      setActiveButton={setActiveButton}
                      activeButton={activeButton}
                      onToggleButtonChange={onToggleButtonChange}
                      id={ID_NAME_MAPPER[selectedRuleType?.toString()].id}
                      name={ID_NAME_MAPPER[selectedRuleType?.toString()].name}
                      ruleType={selectedRuleType?.toString()}
                      width={selectedRuleType?.toString() === "4"? "25%" : "43%"}
                      apiKeys={["id", "name"]}
                    />
                  ) : (
                    getOptions()
                  )}
                  </div>
              ) :(
                <>
                {
                  <div className="mb-2 d-flex mr-2">
                    <div className="d-flex flex-column align-items-center justify-content-center p-r-1">
                      {stepsScreen === 2 && (
                        <div className="dot d-flex align-items-center justify-content-center">
                          <KeyboardArrowLeftIcon
                            onClick={() => handleChangeStepsScreen(1)}
                          />
                        </div>
                      )}
                    </div>
                    <div
                      className={`w-100 ${
                        stepsScreen === "1002" &&
                        "d-flex align-items-center justify-content-center m-h-15"
                      }`}
                    >
                      <p
                        className={`steps-heading ${
                          stepsScreen === 2 && " m-l-3"
                        } ${stepsScreen === "1002" && "rule-desc-text"}`}
                      >
                        { rule_category_id === "2" ? t(ADD_RULE_STEPS_INCLUSION[stepsScreen]["title"]) : t(ADD_RULE_STEPS_EXCLUSION[stepsScreen]["title"])}
                      </p>
                      {(rule_category_id === "2" ?
                        ADD_RULE_STEPS_INCLUSION[stepsScreen]["content"] : ADD_RULE_STEPS_EXCLUSION[stepsScreen]["content"]).map((step) => {
                          return (
                            <div className="d-flex steps-text ps-1 mb-2 add-rule-steps">
                                 <span
                                className="title p-r-1"
                                style={{
                                  width: "10%",
                                  minWidth:
                                    rule_category_id === "2"
                                      ? MIN_WIDTH_FOR_DESCRIPTION_INCLUSION[
                                          stepsScreen
                                        ]
                                      : MIN_WIDTH_FOR_DESCRIPTION_EXCLUSION[
                                          stepsScreen
                                        ],
                                  wordWrap: "break-word",
                                }}
                              >
                                {t(step["title"])}
                              </span>
                              <span>
                                { step["content_section_1"] && <span className="content">{t(step["content_section_1"])}</span>}
                                <span className={`content ${step["content_section_1"] && "f-w-600"}`}>{t(step["content"])}</span>
                                { step["content_section_2"] && <span className="content">{t(step["content_section_2"])}</span>}
                              </span>
                            </div>
                          );
                        })}
                    </div>
                    {stepsScreen === 1 && (
                      <div className="d-flex flex-column align-item-center justify-content-center ">
                        <div className="dot d-flex align-item-center justify-content-center">
                          <KeyboardArrowRightIcon
                            onClick={() => handleChangeStepsScreen(2)}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                }
              </>
              )}
            </span>
          </div>
        </Paper>
      </div>
      {showExcludeBtn && (
        <div className="d-flex flex-column my-3 align-items-end">
          <Button id="exclude-btn" variant={"contained"} onClick={createRule}>
            {rule_category_id === "2" ? "INCLUDE" :t(ADD_RULE_TITLE["AddRuleTitle11"])}
          </Button>
        </div>
      )}
      {draftedRules?.length > 0 && (
        <div id="draft-rules" className="mt-3 w-100 d-flex flex-column">
          <span className="draft-heading">
            {t(ADD_RULE_TITLE["AddRuleTitle12"])}
          </span>
          <span className="draft-subheading my-2">
            {t(ADD_RULE_TITLE["AddRuleTitle13"])}
          </span>
          <div className="d-flex flex-column w-100">
            <div
              style={{
                width: "100%",
                minHeight: "2.3rem",
                maxHeight: "2.3rem",
              }}
              className={`d-flex flex-row drafts-header`}
            >
              <div className="p-2" style={{ minWidth: "5%", maxWidth: "5%" }}>
                <InputLabel className="drafts-header-text">Serial No.</InputLabel>
              </div>
              <div className="p-2" style={{ minWidth: "10%", maxWidth: "10%" }}>
                <InputLabel className="drafts-header-text">
                {t("RuleGroupUppercase")}
                </InputLabel>
              </div>
              <div className="p-2" style={{ minWidth: "20%", maxWidth: "20%" }}>
                <InputLabel className="drafts-header-text">
                {t("RuleType")}
                </InputLabel>
              </div>
              <div className="p-2" style={{ minWidth: "18%", maxWidth: "18%" }}>
                <InputLabel className="drafts-header-text">{t("ProposedStartDate")}</InputLabel>
              </div>
              <div className="p-2" style={{ minWidth: "18%", maxWidth: "18%" }}>
                <InputLabel className="drafts-header-text">{t("ProposedEndDate")}</InputLabel>
              </div>
              <div className="p-2" style={{ minWidth: "20%", maxWidth: "20%" }}>
                <InputLabel className="drafts-header-text">{t("Comment")}</InputLabel>
              </div>
            </div>
            <DraftsTable
              rows={draftedRules}
              deleterow={handleDelete}
            ></DraftsTable>
          </div>
        </div>
      )}
    </>
  );
};

export default AddRule;