import axios from "axios";
import { apiConstant } from '../constants';



export const getScreenList = () => {
  const url = apiConstant.apiURL.getScreenList+'?client_id=1';
  return axios.get(url)
      .then((response) => response.data);
}

export const getWatchList = (queryParam) => {
  const url = apiConstant.apiURL.getWatchList+'?client_id=1&screen_type_id='+queryParam.screenType;
  return axios.get(url)
      .then((response) => response.data);
}

export const fetchRuleGroupType = (queryParam) => {
  const url = `${apiConstant.apiURL.ruleGroupType}?rule_category_id=${queryParam}`;
  return axios.get(url)
      .then((response) => response.data);
}

export const fetchActiveRules = async (queryParam) => {
  let url = `${apiConstant.apiURL.activeRules}?client_id=1&watchlist_id=${queryParam.watchListId}&screen_type_id=${queryParam.screenTypeId}&valid_at_or_after=${queryParam.currentDateTime}&rule_category_id=${queryParam.rule_category_id}`;

  if (queryParam?.rule_group_id) {
    url = url + "&rule_group_id=" + queryParam.rule_group_id;
  }
  if (queryParam?.rule_type_id) {
    url = url + "&rule_type_id=" + queryParam.rule_type_id;
  }
  if (queryParam?.offset) {
    url = url + "&offset=" + queryParam.offset;
  }

  if (queryParam?.limit) {
    url = url + "&limit=" + queryParam.limit;
  }
  return axios
    .get(url)
    .then((response) => response.data);
};

export const addDeleteRules = (data) => {
  return axios
    .post(
      apiConstant.apiURL.pendingAddingDeletingRules,
      data
    )
    .then((response) => {
       return response
    })
    .catch((error)=>{
      return error;
    });
};

export const fetchPendingRules = (queryParam) => {
  let url = `${apiConstant.apiURL.pendingAddingDeletingRules}?client_id=1&watchlist_id=${queryParam.watchListId}&status=0&rule_category_id=${queryParam.rule_category_id}`

  if (queryParam?.rule_group_id) {
    url = url + "&rule_group_id=" + queryParam.rule_group_id;
  }
  if (queryParam?.rule_type_id) {
    url = url + "&rule_type_id=" + queryParam.rule_type_id;
  }
  if (queryParam?.offset) {
    url = url + "&offset=" + queryParam.offset;
  }

  if (queryParam?.limit) {
    url = url + "&limit=" + queryParam.limit;
  }
  return axios
    .get(url)
    .then((response) => {
       return response.data
    });
};

export const actionOnRule =(data) => {
  return axios
    .post(
      apiConstant.apiURL.approveRejectRules,
      data
    )
    .then((response)=> {
       return response
    });
};

export const fetchDescriptions = () => {
  return axios.get(apiConstant.apiURL.descriptions)
      .then((response) => response.data);
}

export const fetchRoles = () => {
  return axios.get(apiConstant.apiURL.occupations)
      .then((response) => response.data);
}

export const fetchCountries = (queryParam) => {
  let url = apiConstant.apiURL.regions;
  if (queryParam?.isOnlyCountries) {
    url = url + "?isOnlyCountries=" + queryParam?.isOnlyCountries;
  }
  return axios
    .get(url)
    .then((response) => response.data)
    .catch((error) => {
      return error;
    });
};

export const searchEntity = (queryParam) => {
  let url = apiConstant.apiURL.searchEntity + "?limit=10";
  if (queryParam?.id) {
    url = url + "&entity_id=" + queryParam.id;
  }
  if (queryParam?.name) {
    url = url + "&entity_name=" + queryParam.name;
  }
  return axios
    .get(url)
    .then((response) => response.data)
    .catch((error) => {
      return error;
    });
};

export const fetchListProviderCode = (queryParam) => {
  let url = apiConstant.apiURL.providerCode + "?limit=10";
  if (queryParam?.id) {
    url = url + "&list_provider_id=" + queryParam.id;
  }
  if (queryParam?.name) {
    url = url + "&list_provider_name=" + queryParam.name;
  }
  return axios
    .get(url)
    .then((response) => response.data)
    .catch((error) => {
      return error;
    });
};

export const fetchListReferenceCode = (queryParam) => {
  let url = apiConstant.apiURL.referenceCode + "?limit=10";
  if (queryParam?.id) {
    url = url + "&list_ref_id=" + queryParam.id;
  }
  if (queryParam?.name) {
    url = url + "&list_reference_name=" + queryParam.name;
  }
  if (queryParam?.jurisdiction) {
    url = url + "&jurisdiction=" + queryParam.jurisdiction;
  }
  return axios
    .get(url)
    .then((response) => response.data)
    .catch((error) => {
      return error;
    });
};

export const getReconResult = (data) => {
  return axios
    .post(
      apiConstant.apiURL.getReconResult,
      data
    )
    .then((response)=> {
       return response
    }).catch((error) => {
      return error;
    });
};

export const runComparison = (data) => {
  return axios
    .post(apiConstant.apiURL.generatetCompareReport, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};


export const viewComparisonReport = () => {
  return axios
    .get(apiConstant.apiURL.viewComparisonReport)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};