import * as React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import './blocklistLoader.scss';

export default function BlocklistLoader() {
  return (
    <Box id="blloader">
      <Skeleton className="skeleton-style" animation="wave" />
      <Skeleton className="skeleton-style" animation="wave" />
      <Skeleton className="skeleton-style" animation="wave" />
      <Skeleton className="skeleton-style" animation="wave" />
      <Skeleton className="skeleton-style" animation="wave" />
      <Skeleton className="skeleton-style" animation="wave" />
      <Skeleton className="skeleton-style" animation="wave" />
      <Skeleton className="skeleton-style" animation="wave" />
      <Skeleton className="skeleton-style" animation="wave" />
      <Skeleton className="skeleton-style" animation="wave" />
    </Box>
  );
}