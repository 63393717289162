import { useEffect, useState, useRef } from "react";
import { Divider, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import ClearIcon from "@mui/icons-material/Clear";

//components
import DetailsSection from "../../features/detailsSection/detialsSection";
import { setAdditionalInformation } from "../../redux/aditionalInformationRedux";
import { camelToUnderscore } from "../../constants/additionalInformationTabUtility";

//style
import "./customTextBox.scss";
import { isEmptyCheck } from "../../constants/utility";
import NoDataFound from "../noDataFound/noDataFound";

export default function CustomTextbox(props) {
  const { attributeDetails } = props;
  const selectedRole = sessionStorage.getItem("selectedRole");

  const [textValues, setTextboxState] = useState({
    enum_type_id: "",
    values: selectedRole === "maker" ? [""] : null,
  });
  let timer = useRef(null);
  const dispatch = useDispatch();

  const additionalInformationData = useSelector(
    (state) => state.aditionalInformationDetails
  );
  const entityData = useSelector((state) => state.entityData )

  const editData = additionalInformationData[
    camelToUnderscore(attributeDetails.enum_type_name)
  ]
    ? additionalInformationData[
        camelToUnderscore(attributeDetails.enum_type_name)
      ]
    : [];



  useEffect(() => {
    if (entityData.entityData?.action === "edit" && Object.keys(editData)?.length > 0) {
      setTextboxState({
      ...editData
      });
    }
  }, []);


  const addFieldHandler = () => {
    const updatedValues = [...textValues.values];
    updatedValues.push("");
    setTextboxState((prevState) => ({
      ...prevState,
      values: [...updatedValues],
    }));
  };

  const deleteHandler = (id) => {
    const updatedValues = [...textValues.values];
    updatedValues.splice(id, 1);
    setTextboxState((prevState) => ({
      ...prevState,
      values: [...updatedValues],
    }));
  };

  const onChangeHandler = (value, index) => {
    const updatedTextValues = { ...textValues };
    updatedTextValues["enum_type_id"] = attributeDetails?.enum_type_id;
    const updatedValues = [...updatedTextValues["values"]];
    updatedValues[index] = value;
    updatedTextValues["values"] = [...updatedValues];
    setTextboxState({ ...updatedTextValues });
  };

  useEffect(() => {
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      const updatedTextValues = [];
      textValues?.values?.map((ele) => {
        if (ele !== "") {
          updatedTextValues.push(ele);
        }
      });
      dispatch(
        setAdditionalInformation({
          key: camelToUnderscore(attributeDetails.enum_type_name),
          data:
            updatedTextValues.length > 0
              ? {
                  enum_type_id: textValues.enum_type_id,
                  values: [...updatedTextValues],
                }
              : {},
        })
      );
    }, [500]);
  }, [textValues]);

  return (
    <DetailsSection
      title={attributeDetails?.default_label_name}
      showAddIcon={attributeDetails.accept_multi_value && selectedRole === "maker" }
      addFieldHandler={addFieldHandler}
    >
      {textValues?.values?.map((ele, index) => {
        return (
          <>
            <div className="d-flex w-100">
              <TextField
                sx={{
                  width: "16%",
                  borderRadius: 8,
                  marginTop: "1%",
                  marginRight: "1%",
                }}
                id="outlined-required"
                label={attributeDetails?.default_label_name}
                value={ele}
                onChange={(event) => onChangeHandler(event.target.value, index)}
                inputProps={{ readOnly: selectedRole === "checker" }}
                />
              {textValues?.values?.length > 1 && selectedRole === "maker" && (
                <ClearIcon
                  sx={{
                    color: "#000",
                    opacity: "58%",
                    cursor: "pointer",
                    marginTop: "2%",
                  }}
                  onClick={() => deleteHandler(index)}
                />
              )}
            </div>
            {index !== textValues?.values?.length - 1 && (
              <Divider className="w-100 divider-style m-t-1" />
            )}
          </>
        );
      })}
      {isEmptyCheck(textValues?.values) && (
        <NoDataFound />
      )}
    </DetailsSection>
  );
}
